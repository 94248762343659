import React from "react";
import "./App.css";

import { SplashScreen } from "@capacitor/splash-screen";
import { Network } from "@capacitor/network";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import Presentazione from "./pages/Presentazione";
import { connect } from "react-redux";
import { setCurrentStep } from "./redux/actions/login";
import { setKeepView, setCurrentView } from "./redux/actions/preferences";
import MayPDialog from "./common/components/utils/MayPDialog";

import {
  getFirestore,
  collection,
  doc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { Route, Switch, withRouter } from "react-router-dom";
/*import {
  Route,
  //Switch,
  //Redirect
} from "react-router-dom";*/

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.showDialog = this.showDialog.bind(this);

    this.state = {
      openDialog: false,
      dialogTitle: "",
      dialogMessage: "",
      dialogActions: [],
      onDialogClose: () => {},
      position: {
        coords: {
          latitude: null,
          longitude: null,
        },
      },
      connected: true,
    };

    this.getPosition = this.getPosition.bind(this);

    console.log("CONSTRUCTOR", this.props);
  }

  showDialog(title, message, actions, autoclose = true, handler = null) {
    return new Promise((resolve, reject) => {
      this.setState({
        openDialog: true,
        dialogTitle: title,
        dialogMessage: message,
        dialogActions: actions && actions.length ? actions : ["OK"],
        onDialogClose: (action) => {
          if (handler && typeof handler === "function") {
            handler(action);
          }
          this.setState(
            {
              openDialog: !autoclose,
            },
            () => {
              resolve(action);
            }
          );
        },
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isAuth !== this.props.isAuth ||
      prevProps.keepView !== this.props.keepView ||
      prevProps.isRegistered !== this.props.isRegistered ||
      prevProps.isVerified !== this.props.isVerified ||
      prevProps.showPresentation !== this.props.showPresentation
    ) {
      if (this.props.showPresentation) {
        this.props.history.replace("/presentation");
      } else if (
        !this.props.keepView &&
        this.props.isAuth &&
        this.props.isRegistered &&
        this.props.isVerified
      ) {
        if (!this.props.location.pathname.startsWith("/main")) {
          this.props.history.replace("/main/home");
        }
      } else {
        if (!this.props.location.pathname.startsWith("/login")) {
          this.props.history.replace("/login/accedi");
        }
      }
    }
  }

  async getPosition() {
    //const position = await Plugins.Geolocation.getCurrentPosition();
    //console.log(position)
    //this.setState(state=>({
    //  position: position
    //}));
    //alert(position)
  }

  parseVersion(ver) {
    //let ver ="1.0.22";

    let reg = /^(\d+)\.(\d+)\.?(\d+)?\.?[b]?(\d+)?$/gm;
    let regRes = reg.exec(ver);
    let parsed = {
      x: 0,
      y: 0,
      z: 0,
      b: 0,
    };
    if (regRes && regRes.length) {
      parsed.x = regRes[1] ? Number(regRes[1]) : 0;
      parsed.y = regRes[2] ? Number(regRes[2]) : 0;
      parsed.z = regRes[3] ? Number(regRes[3]) : 0;
      parsed.b = regRes[4] ? Number(regRes[4]) : 0;
    }

    return parsed;
  }

  async checkProjectSettings(settings = undefined) {
    if (!this.props.isAuth) {
      return;
    }

    console.log("Settings: ", settings);
    const db = getFirestore();
    let docRef = doc(collection(db, "settings"), "project");
    let data = settings;
    try {
      if (!data) {
        const doc = await getDoc(docRef);

        if (doc.exists()) {
          data = doc.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    } catch (error) {
      console.log("Error getting document:", error);
    }
  }

  componentDidMount() {
    //this.getPosition()
    SplashScreen.hide();

    const db = getFirestore();
    this.checkProjectSettings();

    onSnapshot(doc(collection(db, "settings"), "project"), async (doc) => {
      const data = doc.data();
      await this.checkProjectSettings(data);
    });

    Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status);
      this.setState({
        connected: status.connected,
      });
      if (!status.connected) {
        //this.showDialog("Nessuna connessione a internet", "Per continuare ad usare Maypay assicurati di essere connesso ad internet.");
      } else {
      }
    });
    // To stop listening:
    // handler.remove();

    // Get the current network status
    Network.getStatus().then((status) => {
      this.setState({
        connected: status.connected,
      });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (
                !this.props.keepView &&
                this.props.isAuth &&
                this.props.isRegistered &&
                this.props.isVerified
              ) {
                this.props.history.replace("/main/home");
                return <MainPage />;
              }

              this.props.history.replace("/login/accedi");
              if (this.props.showPresentation) {
                return <Presentazione />;
              }
              return <LoginPage />;
            }}
          />
          <Route
            path="/login"
            render={() => {
              if (
                !this.props.keepView &&
                this.props.isAuth &&
                this.props.isRegistered &&
                this.props.isVerified
              ) {
                this.props.history.replace("/main/home");
                return <MainPage />;
              }

              if (this.props.showPresentation) {
                return <Presentazione />;
              }
              return <LoginPage />;
            }}
          />
          <Route
            path="/main"
            render={() => {
              if (
                !this.props.keepView &&
                this.props.isAuth &&
                this.props.isRegistered &&
                this.props.isVerified
              ) {
                return <MainPage />;
              }
              this.props.history.replace("/login/accedi");
              if (this.props.showPresentation) {
                return <Presentazione />;
              }
              return <LoginPage />;
            }}
          />
        </Switch>

        <MayPDialog
          forMerchant
          open={this.state.openDialog}
          title={this.state.dialogTitle}
          message={this.state.dialogMessage}
          actions={this.state.dialogActions}
          onClose={this.state.onDialogClose}
        />
        <MayPDialog
          forMerchant
          open={!this.state.connected}
          title={"Nessuna connessione a internet"}
          message={
            "Per continuare ad usare Maypay assicurati di essere connesso a internet."
          }
          actions={[]}
          onClose={() => {}}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appLoaded: state.app.loaded,
    showPresentation: state.preferences.showPresentation,
    isAuth: state.user.uid ? true : false,
    isRegistered: state.user.registered ? true : false,
    isVerified: state.user.verified ? true : false,
    keepView: state.preferences.keepView,
    currentView: state.preferences.currentView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoginStep: (n) => dispatch(setCurrentStep(n)),
    setKeepView: (keep) => dispatch(setKeepView(keep)),
    setCurrentView: (name) => dispatch(setCurrentView(name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
