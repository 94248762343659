const initStoreState = {
  data: {},
  profilePicURL: undefined,
  storePicURL: undefined,
};

function store(state = initStoreState, action) {
  let store = { ...state };
  switch (action.type) {
    case "CLEAR_STORE_DATA":
      store = initStoreState;
      return Object.assign({}, state, store);
    case "UPDATE_STORE":
      if (action.store) {
        store.data = action.store;
      }
      if (action.profilePicURL) {
        store.profilePicURL = action.profilePicURL;
      }
      if (action.storePicURL) {
        store.storePicURL = action.storePicURL;
      }
      return Object.assign({}, state, store);
    default:
      return state;
  }
}

export default store;
