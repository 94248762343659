const initLoginState = {
  currentStep: 0,
  companyCache: {},
  ownerCache: {},
  bankCache: {},
  stripeCache: {},
};

function login(state = initLoginState, action) {
  switch (action.type) {
    case "SET_STEP":
      console.log("setting step to", action.step);
      return Object.assign({}, state, { currentStep: action.step });
    case "CLEAR_CACHE":
      return Object.assign({}, state, {
        companyCache: {},
        ownerCache: {},
        bankCache: {},
        stripeCache: {},
      });
    case "SET_COMPANY_CACHE":
      console.log("LOGIN", action.data)
      return Object.assign({}, state, { companyCache: action.data });
    case "SET_OWNER_CACHE":
      return Object.assign({}, state, { ownerCache: action.data });
    case "SET_BANK_CACHE":
      return Object.assign({}, state, { bankCache: action.data });
    case "SET_STRIPE_CACHE":
      return Object.assign({}, state, { stripeCache: action.data });
    default:
      return state;
  }
}

export default login;
