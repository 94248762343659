import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import MayPMaterialTheme from "./MayPMaterialTheme";
import { createStore } from "redux";
import { clearStoreData } from "./redux/actions/store";
import { clearData } from "./redux/actions/user";
import appStore from "./redux/reducers";
import { setAppLoaded } from "./redux/actions/app";
import { setAuth } from "./redux/actions/user";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { getFirestore, getDoc, doc, collection } from "firebase/firestore";
import { firebaseConfig } from "./appenv";
import { clearCache, setCompanyCache, setOwnerCache, setStripeCache } from "./redux/actions/login";
import { setKeepView } from "./redux/actions/preferences";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["app"],
};
const persistedReducer = persistReducer(persistConfig, appStore);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

// Initialize Firebase
initializeApp(firebaseConfig);
getAuth().languageCode = "it";

const onLogin = async (user) => {
  console.log("ON LOGIN", user);

  if (user) {
    const result = await getDoc(
      doc(collection(getFirestore(), "merchants"), user.uid)
    );

    if (result.exists()) {
      const merchant = result.data();
      console.log("merchant exists", merchant, user.uid)

      user["registration_completed"] = merchant.registration_completed;

      if (merchant.registration_completed === false) {
        console.log("registration NOT completed yet");
        const cache = {company: {}, owner: {}, stripe: {}};
        // Bank data are not stored in firestore, then they cant be loaded 

        for (const k in merchant) {
          if (k.startsWith("owner") || k === "isOwner" || k === "isTheOnlyOwner" || k === "isTheOnlyDirector") {
            cache.owner[k] = merchant[k];
          } else if (k.startsWith("company")) {
            cache.company[k] = merchant[k];
          } else if (k.startsWith("stripe")) {
            if (k === "stripe_account_id") {
              cache.stripe["account_id"] = merchant[k];
            } else if (k === "stripe_owner_id") {
              cache.stripe["owner_id"] = merchant[k];
            }
          }
        }

        console.log("setting cache with firestore data", cache);

        store.dispatch(setOwnerCache(cache.owner));
        store.dispatch(setCompanyCache(cache.company));
        store.dispatch(setStripeCache(cache.stripe));
      } else {
        // go to home
        console.log("registration already completed, going to home");
        store.dispatch(setKeepView(false));

      }
      console.log("Set auth", user)
      store.dispatch(setAuth(user));
      store.dispatch(setAppLoaded(true));
    } else {
      // go to registration
      console.log("Merchant document does not exists yet");
      user["registration_completed"] = false;
      store.dispatch(setAuth(user));
    }
  } else {
    // User is signed out.
    // ...
    //clearStoreData();
    console.log("clear data after sign out");
    store.dispatch(clearStoreData());
    store.dispatch(clearData());
    store.dispatch(clearCache());
  }
};

onAuthStateChanged(getAuth(), onLogin);
onIdTokenChanged(getAuth(), onLogin);

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ThemeProvider theme={MayPMaterialTheme}>
            <div
              style={{
                height: "env(safe-area-inset-top)",
                backgroundColor: "#00A2E0",
                width: "100vw",
                display: "block",
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: "2000",
              }}
            ></div>
            <App />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
/* Only register a service worker if it's supported */
//if ('serviceWorker' in navigator) {
//  navigator.serviceWorker.register('./service-worker.js');
//}

/**
 * Warn the page must be served over HTTPS
 * The `beforeinstallprompt` event won't fire if the page is served over HTTP.
 * Installability requires a service worker with a fetch event handler, and
 * if the page isn't served over HTTPS, the service worker won't load.
 */
/*if (window.location.protocol === 'http:') {
  const requireHTTPS = document.getElementById('requireHTTPS');
  const link = requireHTTPS.querySelector('a');
  link.href = window.location.href.replace('http://', 'https://');
  requireHTTPS.classList.remove('hidden');
}*/

// Create the script tag, set the appropriate attributes
var script = document.createElement("script");
script.src =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyB0RDn40dwB8aDMI56OEZgMDpLeQkaaTcQ&callback=initMap";
script.defer = true;
script.async = true;

// Attach your callback function to the `window` object
window.initMap = function () {
  console.log("Map api are loaded");
};

// Append the 'script' element to 'head'
document.head.appendChild(script);

window.deferredPrompt = null;

window.addEventListener("beforeinstallprompt", (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  window.deferredPrompt = e;
});
