import { format } from "date-fns";
import { it } from "date-fns/locale";
import { getAuth } from "firebase/auth";
import React from "react";
import MayPDialog from "../../../common/components/utils/MayPDialog";
import { MayPPage } from "../../../common/components/utils/MayPPager";
import {
  TransactionStatus,
  currencyFormatter,
} from "../../../common/libs/utils";
import CFLib from "../../../libs/cloudfunction";
import StoreLib from "../../../libs/store";
import TransactionLib from "../../../libs/transaction";

import { IconButton } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import MayPTable from "../../../common/components/utils/MayPTable";
import Dettagli from "./Dettagli";
import styles from "./TransactionsHistory.module.css";

class TransactionsHistory extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    this.transactionLib = new TransactionLib();
    this.state = {
      savings: 0,
      transactions: [],
      tableTransactions: [],
      waitingTransactions: false,
      currentTimeLabel: "",
      waitingCloseCashRegister: false,
      openDialog: false,
      dialogTitle: "",
      dialogMessage: "",
      dialogActions: [],
      dialogClose: () => {},
    };
    this.loadTransactions = this.loadTransactions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.toFixed = this.toFixed.bind(this);

    this.preventLoading = false;
    this.cfLib = new CFLib();
    this.storeLib = new StoreLib();

    this.timer = null;
    this.updateTime = () => {
      let now = new Date();
      this.setState({
        currentTimeLabel: format(now, "dd LLL yy 'ore' p", { locale: it }),
      });

      this.timer = setTimeout(
        this.updateTime,
        60000 - now.getSeconds() * 1000 - now.getMilliseconds()
      );
    };

    this.pages = 0;
    this.forceLastPage = null;
    this.limit = 10;
    this.cursor = null;
  }

  toFixed(v) {
    return currencyFormatter.format(Number.parseFloat(v));
  }

  async loadTransactions() {
    if (this.state.waitingTransactions) {
      return;
    }
    this.setState({
      waitingTransactions: true,
    });

    let data = null;

    let resultTrans = await this.transactionLib.getTransactionsData(
      this.cursor,
      this.limit,
      this.props.storeId || this.props.searchInput?.storeId,
      this.props.searchInput?.startTime,
      this.props.searchInput?.endTime,
    );

    if (resultTrans.success && resultTrans.transactions.length === 0) {
      // end of pages
      console.log("END OF PAGES", resultTrans);
      this.setState({
        waitingTransactions: false,
      });
      return null;
    }

    data = resultTrans.transactions;
    this.cursor = resultTrans.cursor;

    data = data.map((t, index) => {
      const td = t.data;
      const mapped = {
        ...td,
        id: t.id,
        key: index,
        dateString: new Date(td.startTime).toLocaleString("en-GB"),
        displayId: t.id,
        displayCurrentProbability: `1/${td.currentProbability} (${Math.floor(
          100 / td.currentProbability
        )}%)`,
        displayAmount: Math.round(td.amount * 100) / 100 + " €",
        displayNetAmount: Math.round((td.amount - td.application_fee_amount / 100) * 100) / 100 + " €",
        displayState: TransactionStatus[td.state],
      }

      return {
        ...mapped,
        info: <IconButton onClick={()=>{
          console.log();
          this.setState({
            openDialog: true,
            dialogMessage: <Dettagli transaction={mapped} />,
            dialogActions: [],
            dialogClose: async (action) => {
              this.setState({
                openDialog: false,
              });
            },
          });
        }}><InfoIcon style={{ color: "#00a2e0"}}/></IconButton>
      }
    });

    if (this.state.transactions.length === 0) {
      // first data load
      this.setState({
        transactions: data,
      });
    }
    this.setState({
      waitingTransactions: false,
    });

    return data;
  }

  async closeCashRegister() {
    const user = getAuth().currentUser;
    let data = {
      storeId: user.uid,
    };
    try {
      const res = await this.cfLib.closeCashRegister(data);
      if (!res) {
        throw new Error("Non è stato possibile chiudere la cassa.");
      }

      if (res === true) {
        this.setState({
          waitingCloseCashRegister: false,
          transactions: [],
        });
        this.loadTransactions();
      } else {
        throw new Error("Non è stato possibile chiudere la cassa.");
      }
    } catch (e) {
      console.log(e.message);
      this.setState({
        openDialog: true,
        dialogTitle: "Si è verificato un errore",
        dialogMessage: e.message,
        dialogActions: ["Ok"],
        dialogClose: async (action) => {
          this.setState({
            showErrorDialog: false,
          });
        },
      });
    }
    this.setState({
      openDialog: false,
      waitingCloseCashRegister: false,
    });
  }

  handleScroll(event) {
    const scrollValue = window.innerHeight + window.pageYOffset;
    const scrollLimit = document.body.offsetHeight;

    if (scrollValue >= scrollLimit * 0.98 && !this.preventLoading) {
      this.preventLoading = true;
      this.loadTransactions();
    } else if (scrollValue < scrollLimit - 150) {
      this.preventLoading = false;
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadTransactions();
    // window.addEventListener("scroll", this.handleScroll);
    this.updateTime();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchInput !== prevProps.searchInput) {
      console.log("new search input, resetting", this.props.searchInput)
      this.pages = 0;
      this.forceLastPage = null;
      this.cursor = null;
      this.setState({
        transactions: []
      }, () => {
        this.loadTransactions();
      })
    }

    if (prevState.transactions !== this.state.transactions && this.props.onTransactionsChange) {
      this.props.onTransactionsChange(this.state.transactions);
    }
  }

  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
    clearTimeout(this.timer);
  }

  render() {
    return (
      <div className={styles.mainContainer}>
        <div className={styles.whiteBox}>
          <div className={styles.transactionsContainer}>
            {!this.state.transactions.length &&
              !this.state.waitingTransactions && (
                <div className={styles.info}>
                  Attualmente non sono presenti transazioni
                </div>
              )}
            <MayPTable
              pagination={true}
              labelRowsPerPage="Operazioni per pagina"
              loading={this.state.waitingTransactions}
              tableHeaders={[
                "Data",
                "Codice",
                "Negozio",
                "Cliente",
                "Probabilità",
                "Esito",
                "Importo Lordo",
                "",
              ]}
              dataKeys={[
                "dateString",
                "displayId",
                "storeName",
                "buyerName",
                "displayCurrentProbability",
                "displayState",
                "displayAmount",
                "info"
              ]}
              tableData={{
                rows: this.state.transactions || [],
                totalRows: -1,
              }}
              rowsPerPage={this.limit}
              updateData={(p, l) => {
                if (l !== this.limit) {
                  this.limit = l;
                  this.cursor = 0;
                }
                return this.loadTransactions();
              }}
              onRowClick={(item, row, column) => {
                this.setState({
                  openDialog: true,
                  dialogMessage: <Dettagli transaction={item} />,
                  dialogActions: [],
                  dialogClose: async (action) => {
                    this.setState({
                      openDialog: false,
                    });
                  },
                })
              }}
            />
          </div>
        </div>

        <MayPDialog
          forMerchant={true}
          open={this.state.openDialog}
          title={this.state.dialogTitle}
          message={this.state.dialogMessage}
          actions={this.state.dialogActions}
          onClose={this.state.dialogClose}
          showCloseButton={true}
        />
      </div>
    );
  }
}

export default TransactionsHistory;
