import React from "react";
import Icon from "@material-ui/core/Icon";
import SelectedIc from "../assets/mp_coin.svg";

function CustomIcon(props) {
  return (
    <Icon {...props} style={{ ...props.style, position: "relative" }}>
      <img
        src={SelectedIc}
        alt="selected"
        style={{
          width: "32px",
          height: "32px",
          position: "absolute",
          zIndex: "0",
          left: "0px",
          top: "0px",
          transition: "opacity 500ms",
          opacity: props.selected ? "1" : "0",
        }}
      />
      <img
        src={props.icon}
        alt={props.icon}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      />
    </Icon>
  );
}

export default CustomIcon;
