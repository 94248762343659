import { combineReducers } from "redux";
import login from "./login";
import user from "./user";
import app from "./app";
import preferences from "./preferences";
import store from "./store";

const appState = combineReducers({
  login,
  user,
  store,
  preferences,
  app,
});

export default appState;
