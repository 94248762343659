import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import { wrap } from "../../common/components/utils/MayPButton";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import { MayPButtonTextWrap } from "../../common/components/utils/MayPButtonText";
import CheckIcon from "../../common/components/assets/@MAYPAY_UIKIT_BUYER/mp_check_icon.svg";
import CheckIconGray from "../../common/components/assets/@MAYPAY_UIKIT_BUYER/mp_check_icon_gray.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { Browser } from "@capacitor/browser";
import styles from "./Termini.module.css";
import { withRouter } from "react-router-dom";

const ButtonRound = wrap({
  root: {
    minWidth: 0,
    width: "75px",
    height: "75px",
    borderRadius: "100%",
    backgroundImage: "none",
    backgroundColor: "#00e091",
    boxShadow: "none",
  },
});
const Buttontext = MayPButtonTextWrap({
  root: {
    fontSize: "16px",
    letterSpacing: "1.6px",
    fontWeight: "normal",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #606060",
    color: "#606060",
  },
  disabled: {
    color: "#cccccc",
  },
});

const ButtontextSmall = MayPButtonTextWrap({
  root: {
    fontSize: "13px",
    letterSpacing: "0px",
    fontWeight: "normal",
    fontFamily: '"MavenPro Regular", Arial, sans-serif',
    lineHeight: "16px",
    color: "#343434",
    textAlign: "left",
    padding: "0px",
    paddingLeft: "0px",
    paddingRigth: "0px",
  },
  disabled: {
    color: "#cccccc",
  },
});

class Termini extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;

    this.onAccept = this.onAccept.bind(this);

    this.state = {
      termsAccepted: false,
      privacyAccepted: false,
      error: 0,
      errorMessage: "",
      waiting: false,
    };
  }

  onAccept() {
    if (
      this.state.termsAccepted &&
      this.state.privacyAccepted &&
      this.props.onSubmit
    ) {
      this.props.onSubmit(this.props.page, "terms-accepted", true);
    }
  }

  render() {
    return (
      <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <h3>Leggi e accetta</h3>

        <MayPWithSpinner waiting={this.state.waiting}>
          {this.state.error === 0 && (
            <div className="message">
              Ricorda che per usare Maypay devi essere maggiorenne.
            </div>
          )}
          {this.state.error !== 0 && (
            <div className="message error">{this.state.errorMessage}</div>
          )}
        </MayPWithSpinner>

        <div className={styles.boxContainer}>
          <FormControlLabel
            label={
              <span className={styles.optionLabel}>Termini e Condizioni</span>
            }
            labelPlacement="start"
            classes={{
              root: styles.optionLabelRoot,
              label: styles.optionLabelLabel,
              labelPlacementStart: styles.optionLabelLabel,
            }}
            control={
              <Radio
                style={{ color: "#00a2e0" }}
                className={styles.radio}
                checked={this.state.termsAccepted}
                onClick={(e) => {
                  const checked = !this.state.termsAccepted;
                  this.setState({
                    termsAccepted: checked,
                  });
                }}
                value="terms"
              />
            }
          />
          <div className={styles.link}>
            <ButtontextSmall
              onClick={async (e) => {
                await Browser.open({
                  url: "https://www.maypay.com/termini-condizioni-esercenti/",
                });
              }}
            >
              <p>
                <span className={styles.green}>Leggi</span> gli accordi che
                disciplinano i diritti e gli obblighi tuoi e di Maypay, nonché
                il regolamento del concorso a premi Maypay
              </p>
            </ButtontextSmall>
          </div>
          <FormControlLabel
            label={<span className={styles.optionLabel}>Privacy Policy</span>}
            labelPlacement="start"
            classes={{
              root: styles.optionLabelRoot,
              label: styles.optionLabelLabel,
              labelPlacementStart: styles.optionLabelLabel,
            }}
            control={
              <Radio
                style={{ color: "#00a2e0" }}
                className={styles.radio}
                checked={this.state.privacyAccepted}
                onClick={(e) => {
                  const checked = !this.state.privacyAccepted;
                  this.setState({
                    privacyAccepted: checked,
                  });
                }}
                value="terms"
              />
            }
          />
          <div className={styles.link}>
            <ButtontextSmall
              onClick={async (e) => {
                await Browser.open({
                  url: "https://www.maypay.com/privacy-policy/",
                });
              }}
            >
              <p>
                <span className={styles.green}>Leggi</span> le informazioni sul
                trattamento dei dati personali operato da Maypay
              </p>
            </ButtontextSmall>
          </div>
        </div>

        <div style={{ flexGrow: 1 }}>
          <div className="round-button-container" style={{ marginTop: "50px", marginBottom: "16px" }}>
            <ButtonRound
              onClick={(e) => {
                if (this.props.onSubmit) {
                  this.props.onSubmit(
                    this.props.page,
                    "terms-accepted",
                    undefined
                  );
                }
              }}
              disabled={
                !this.state.termsAccepted || !this.state.privacyAccepted
              }
            >
              {(!this.state.termsAccepted || !this.state.privacyAccepted) && (
                <img
                  src={CheckIconGray}
                  style={{ width: "28px", height: "auto" }}
                  alt="continua"
                />
              )}
              {this.state.termsAccepted && this.state.privacyAccepted && (
                <img
                  src={CheckIcon}
                  style={{ width: "28px", height: "auto" }}
                  alt="continua"
                />
              )}
            </ButtonRound>
          </div>
          <div className="page-title2">
            <Buttontext
              onClick={(e) => {
                if (this.props.onSubmit) {
                  this.props.onSubmit(
                    this.props.page,
                    "terms-accepted",
                    undefined
                  );
                }
              }}
              style={{
                color:
                  !this.state.termsAccepted || !this.state.privacyAccepted
                    ? "#606060"
                    : "#00E091",
                "WebkitTextStroke":
                  !this.state.termsAccepted || !this.state.privacyAccepted
                    ? "0.7px #606060"
                    : "0.7px #00E091",
              }}
              disabled={
                !this.state.termsAccepted || !this.state.privacyAccepted
              }
            >
              Accetta
            </Buttontext>
          </div>
          <div className="page-title2" style={{ marginTop: "20px" }}>
            <Buttontext
              onClick={(e) => {
                if (this.props.onSubmit) {
                  this.props.onSubmit(this.props.page, "on-home", undefined);
                }
              }}
              style={{ fontSize: "14px", letterSpacing: "1.4px" }}
            >
              Torna indietro
            </Buttontext>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Termini);
