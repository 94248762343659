import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import styles from "./MayPTable.module.css";
import { Paper } from "@material-ui/core";
import MayPCheck from "./MayPCheck";

const getUUID = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerCase = "abcdefghijklmnopqrstuvwxyz";
  const symbols = "!@$%^&";
  const numbers = "0123456789";
  const sets = [characters, symbols, numbers, lowerCase];
  let counter = 0;
  while (counter < length) {
    for (let j = 0; j < sets.length && counter < length; j++) {
      result += sets[j].charAt(Math.floor(Math.random() * sets[j].length));
      counter++;
    }
  }
  return result;
};

export default function MayPMiniTable(props) {
  const [miniTables, setMiniTables] = useState([]);
  const [selectedRows, setSelectedRows] = useState(props.selectedRows || props.data.map((d) => {return false}))
  useEffect(() => {
    setSelectedRows(props.selectedRows)
  }, [props.selectedRows]) 

  useEffect(() => {
    if (props.data.length > 0) {
      const tables = [];

      props.data.forEach((row, i) => {
        const pivot = [];

        const dataKeys = props.miniComponentKeys || props.dataKeys;

        dataKeys.forEach((column, j) => {
          const data = row[column];
          pivot.push({
            header: props.tableHeaders[j],
            key: column,
            data,
          });
        });
        tables.push({
          data: pivot,
          disabled:
            (row.disabled !== undefined && row.disabled) ||
            (row.enabled !== undefined && !row.enabled),
          deleted: row.deleted !== undefined && row.deleted,
        });
      });
      setMiniTables(tables);
    } else {
      setMiniTables([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.dataKeys]);

  return (
    <>
      {/* {props.loading &&
        // LOADING ON MOBILE
        new Array(props.rowsPerPage).fill("").map((el, index) => {
          return (
            <div className={styles.miniTableSkeletonContainer} key={index}>
              {new Array(props.tableHeaders.length)
                .fill("")
                .map((el, index) => {
                  return (
                    <React.Fragment key={"header" + index}>
                      <Skeleton
                        heigth={"100%"}
                        style={{
                          backgroundColor: "var(--secondary-color)",
                          height: "48px",
                          gridArea: `a${index + 1}`,
                          marginTop: "-15px",
                        }}
                      />
                      <Skeleton
                        key={"data" + index}
                        heigth={"100%"}
                        style={{
                          height: "48px",
                          gridArea: `b${index + 1}`,
                          marginTop: "-15px",
                        }}
                      />
                    </React.Fragment>
                  );
                })}
            </div>
          );
        })} */}

      {
        /*!props.loading && */
        miniTables.length > 0 &&
          miniTables.map((miniTable, miniTableIndex) => {
            const { disabled } = miniTable;

            if (props.MiniComponent) {
              const { MiniComponent } = props;

              const data = {};
              miniTable.data.forEach((d) => {
                if (props.miniComponentKeys.includes(d.key)) {
                  data[d.key] = d.data;
                }
              });

              return (
                <div className={styles.miniComponentContainer}>
                  <MiniComponent {...props} {...props.miniComponentProps} key={`${miniTableIndex}-${getUUID(64)}`} data={{...data}} />
                </div>
              );
            } else {
              return (
                <TableContainer
                  key={miniTableIndex}
                  component={Paper}
                  style={{
                    marginBottom: "32px",
                    // minWidth: "fit-content",
                  }}
                >
                  <Table>
                    {
                      /*!props.loading && */ props.data.length === 0 ? (
                        <TableBody
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {new Array(8).fill(0).map((row, index) => {
                            return (
                              <TableRow
                                key={index}
                                style={{
                                  "&:nth-of-type(odd)": {
                                    backgroundColor: "#f2f2f2",
                                  },
                                  "&:nth-of-type(odd):hover": {
                                    backgroundColor: "#e6f8ff",
                                  },
                                  "&:nth-of-type(even):hover": {
                                    backgroundColor: "#e6f8ff",
                                  },
                                }}
                              >
                                {props.dataKeys.map((key) => {
                                  return (
                                    <TableCell
                                      key={`${index}-${key}`}
                                      style={{ padding: "2% 0" }}
                                    ></TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      ) : (
                        <TableBody>
                          {miniTable.data.map((row, index) => {
                            const { header, data, key } = row;
                            const cellSx = {
                              fontSize: "12px",
                              padding: "0.5em 0.1em",
                            };
                            return (
                              <TableRow
                                key={index}
                                onDrag={(e) => e.stopPropagation()}
                                onClick={props.onClick}
                                style={{
                                  opacity: disabled ? "30%" : "100%",
                                  "&:nth-of-type(odd)": {
                                    backgroundColor: "#f2f2f2",
                                  },
                                  "&:nth-of-type(odd):hover": {
                                    backgroundColor: "#e6f8ff",
                                  },
                                  "&:nth-of-type(even):hover": {
                                    backgroundColor: "#e6f8ff",
                                  },
                                }}
                              >
                                <TableCell
                                  variant="head"
                                  align="center"
                                  style={{
                                    ...cellSx,
                                    width: "30%",
                                    // backgroundColor: "var(--secondary-color)",
                                    color: "var(--primary-color)",
                                    fontFamily: "Giorgio",
                                    WebkitTextStroke: "0.5px",
                                  }}
                                >
                                  {header}
                                </TableCell>
                                <TableCell
                                  id={`${miniTableIndex}-${key}`}
                                  align="center"
                                  style={
                                    props.onRowClick
                                      ? {
                                          ...cellSx,
                                          width: "70%",
                                          // textDecoration: deleted
                                          //   ? "line-through"
                                          //   : "",

                                          "&:hover": {
                                            cursor: "pointer",
                                          },
                                        }
                                      : {
                                          ...cellSx,
                                          width: "70%",
                                          // textDecoration: deleted
                                          //   ? "line-through"
                                          //   : "",
                                        }
                                  }
                                >
                                  {key === "avatar" ? (
                                    <div>
                                      <img
                                        id={disabled ? "disabledPic" : ""}
                                        src={data}
                                        className={styles.avatarIcon}
                                        alt="avatarIcon"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          const e = {
                                            target: {
                                              id: `${miniTableIndex}-${key}`,
                                            },
                                          };
                                          if (props.onClick) {
                                            props.onClick(e);
                                          }
                                        }}
                                        // onError={({ currentTarget }) => {
                                        //   currentTarget.onerror = null;
                                        //   currentTarget.src =
                                        //     "/icons/avatar_default_dark.png";
                                        // }}
                                      />
                                    </div>
                                  ) : (
                                    data
                                  )}

                                  {
                                key === "selected" && (
                                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                  <MayPCheck 
                                  appearance="radio" 
                                  checked={selectedRows[miniTableIndex] === true} 
                                  onChange={(isChecked) => {
                                    setSelectedRows((prev) => {
                                      prev[miniTableIndex] = !prev[miniTableIndex];
                                      return [...prev];
                                    })

                                    if (props.onSelected) {
                                      props.onSelected(miniTableIndex, isChecked)
                                    }
                                  }}
                                  />
                                  </div>
                                )
                              }
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )
                    }
                  </Table>
                </TableContainer>
              );
            }
          })
      }

      {
        /*!props.loading &&*/ miniTables.length === 0 && (
          // EMPTY DATA => show 1 mini table with just headers
          <>
            {/* <div className={"noDataContainer"}>
            <img
              src={"/icons/no_data.png"}
              className={"noDataIcon"}
              alt="nodata"
            />
          </div> */}
            <TableContainer
              component={Paper}
              style={{
                boxShadow: "var(--box-shadow)",
                marginBottom: "5%",
                // minWidth: "fit-content",
              }}
            >
              <Table>
                <TableBody>
                  {props.tableHeaders.map((header, index) => {
                    const cellSx = {
                      fontSize: "12px",
                      padding: "0.5em 0.1em",
                    };
                    return (
                      <TableRow
                        key={index}
                        style={{
                          "&:nth-of-type(odd)": {
                            backgroundColor: "#f2f2f2",
                          },
                          "&:nth-of-type(odd):hover": {
                            backgroundColor: "#e6f8ff",
                          },
                          "&:nth-of-type(even):hover": {
                            backgroundColor: "#e6f8ff",
                          },
                        }}
                      >
                        <TableCell
                          variant="head"
                          align="center"
                          style={{
                            ...cellSx,
                            width: "30%",
                            // backgroundColor: "var(--secondary-color)",
                            color: "var(--primary-color)",
                            fontFamily: "Giorgio",
                            WebkitTextStroke: "0.5px",
                          }}
                        >
                          {header}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ ...cellSx, width: "70%" }}
                        >
                          {""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }
    </>
  );
}
