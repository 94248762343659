import {
  getFirestore,
  collection,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
  query,
  doc,
  getDoc,
  endBefore,
  endAt,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { currencyFormatter } from "../common/libs/utils";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const getTransactionsData = async (start, limit_, storeId =null, startTime=null, endTime=null) => {
    let cursor = start;
    let transactions = [];
    console.log({start, limit_, storeId, startTime, endTime})
    try {
      const currentUser = getAuth().currentUser;
      const db = getFirestore();
      if (currentUser && currentUser.uid) {
        const queryParameters = [
          where("merchantId", "==", currentUser.uid),
          where("state", "in", [4, 5, 8, 9, 10, 11]),
        ];

        if (storeId) {
          queryParameters.push(
            where("storeId", "==", storeId)
          )
        }

        if (startTime) {
          queryParameters.push(
            orderBy("startTime"),
            startAfter(start || startTime),
          )

          if (endTime) {
            queryParameters.push(
              endAt(endTime),
            )
          }
        } else if (endTime) {
          queryParameters.push(
            orderBy("startTime"),
            endAt(endTime),
          )
          if (start) {
            queryParameters.push(
              startAfter(start),
            )
          }
        } else {
          queryParameters.push(
            orderBy("endTime", "desc"),
            startAfter(start || "0"),
          )
        }
          
        queryParameters.push(
          limit(limit_),
        )
        console.log(queryParameters)
        let docRef = collection(db, "transactions");

        let result;
        result = await getDocs(query(docRef, ...queryParameters));
        if (result.docs.length > 0) {
          cursor = result.docs[result.docs.length - 1];
        }
        for (let i = 0; i < result.docs.length; i++) {
          const item = result.docs[i];

          let _transaction = { index: i, id: item.id, data: item.data() };
          transactions.push(_transaction);
        }
        console.log(transactions)
        return {
          success: true,
          transactions: transactions,
          cursor: cursor,
        };
      }
    } catch (error) {
      console.log("error getting transactions data", error);
    }

    return { success: false, transactions: transactions, cursor: cursor };
  };

  const getTransactionById = async (id) => {
    console.log(id);
    try {
      const currentUser = getAuth().currentUser;
      const db = getFirestore();
      if (currentUser && currentUser.uid) {
        let docRef = doc(collection(db, "transactions"), id);
        let result;
        result = await getDoc(docRef);
        console.log(result);
        if (result.exists()) {
          return {
            success: true,
            transaction: result.data(),
          };
        }
      }
    } catch (error) {
      console.log("error getting transaction data", error);
    }

    return { success: false, transaction: undefined };
  };

  const getTotalSavings = async () => {
    let savings = 0.0;
    try {
      const currentUser = getAuth().currentUser;
      const db = getFirestore();
      if (currentUser && currentUser.uid) {
        let docRef = doc(
          collection(
            doc(collection(db, "merchants"), currentUser.uid),
            "stats"
          ),
          "transactions"
        );
        const document = await getDoc(docRef);
        if (document.exists()) {
          const data = document.data();
          console.log(data);
          for (const v of Object.values(data)) {
            if (v.hasOwnProperty("savings")) {
              savings += v.savings;
            }
          }
        }
      }
    } catch (error) {
      console.log("error getting transactions stats data", error);
    }

    return currencyFormatter(savings);
  };

  return {
    getTransactionsData: getTransactionsData,
    getTotalSavings: getTotalSavings,
    getTransactionById: getTransactionById,
  };
}
