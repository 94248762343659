import React, { useCallback } from "react";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "./menudrawer/Menu";
import "./menudrawer/menudrawer.css";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";
import clsx from "clsx";

const drawerWidth = 300;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: "#f5f5f5",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: "0px",
      left: "0px",
      zIndex: 1900,
      width: "100%"
    },
  },
  drawerClose: {
    backgroundColor: "#f5f5f5",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,//theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function MenuDrawer(props) {
  const classes = useStyles();

  const onItemClick = useCallback(
    (page, action, data) => {
      if (props.onAction) {
        props.onAction(action, page, data);
      }
    },
    [props]
  );

  return (
    <Container
      style={{
        display: "flex",
        minHeight: "100%",
        minWidth: "100%",
        padding: 0,
        paddingTop: "60px",
      }}
    >
    {true && (<Drawer
        anchor="left"
        open={props.open}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: props.open,
            [classes.drawerClose]: !props.open,
          }),
        }}
        onClose={(evt) => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <div className="container">
          <Menu onItemClick={onItemClick} />
        </div>
      </Drawer>
    )}
      <main className={`mainContainer`}>
        <div
          style={{
            // height: "calc(100% - 60px)",
            width: "100%",
            // width: props.open
            // ? `calc(100% - ${drawerWidth}px)`
            // : `calc(100% - 73px)`,
          }}
        >
          {props.children}
        </div>
      </main>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user || { profile: {} },
  };
};
export default connect(mapStateToProps, null, null)(MenuDrawer);
