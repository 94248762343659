import React, { useCallback, useEffect, useState } from "react";
import MayPWithSpinner from "../common/components/utils/MayPWithSpinner";
import MayPStoreBadge from "../common/components/utils/MayPStoreBadge";
import StoreLib from "../libs/store";
import { wrap } from "../common/components/utils/MayPButton";

import { collection, updateDoc, doc, getFirestore } from "firebase/firestore";

import AddIcon from "../common/components/assets/@MAYPAY_UIKIT_BUYER/mp_plus_icon.svg";
import CheckIcon from "../common/components/assets/@MAYPAY_UIKIT_BUYER/mp_check_icon.svg";
import CFLib from "../libs/cloudfunction";
import styles from "./Home.module.css";
import { getAuth } from "firebase/auth";
import MayPPageTemplate from "../common/components/utils/MayPPageTemplate";
import { StoreType } from "../common/libs/utils";
import MayPOnlineStoreBadge from "../common/components/utils/MayPOnlineStoreBadge";
import { redirectUrl } from "../appenv";

const style = {
  root: {
    textTransform: "none",
    backgroundColor: "#ffffff",
    backgroundImage: "none",
    boxShadow: "0px 3px 15px #00000029",
    padding: "16px",
    width: "100%",
    minWidth: "inherit",
    borderRadius: "20px",
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    lineHeight: "inherit",
    letterSpacing: "inherit",
    color: "#00000",
    WebkitTextStroke: "none",
    maxWidth: "400px",
  },
  disabled: {
    backgroundImage: "none",
    color: "#606060",
  },
};

const ButtonWrap = wrap(style);

const ButtonRound = wrap({
  root: {
    minWidth: 0,
    width: "45px",
    height: "45px",
    borderRadius: "100%",
    backgroundImage: "none",
    backgroundColor: "#00e091",
    boxShadow: "none",
  },
});

let pageIndexes = [];

export default function Home(props) {
  const [stores, setStores] = useState([]);
  const [loadingStores, setLoadingStores] = useState(true);
  const [requirements, setRequirements] = useState({
    allDone: false,
    hasStore: false,
    isStripeAccountActive: false,
    requiresAccountData: false,
    accountStatus: "",
    accountStatusMessage: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [storeQueryParam, setStoreQueryParam] = useState({
    page: 0,
    limit: 10,
    onlyEnabledStores: false,
    filterValue: null,
  });

  useEffect(() => {
    setTimeout(() => {
      loadStores();
    }, 500);
    checkStripeRequirements();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !requirements.allDone &&
      requirements.hasStore &&
      requirements.isStripeAccountActive
    ) {
      setRequirements((prev) => {
        return {
          ...prev,
          allDone: true,
        };
      });
    }
  }, [requirements]);

  const loadStores = useCallback(async () => {
    setLoadingStores(true);
    const { page, limit, onlyEnabledStores } = storeQueryParam;

    let filter, startAfter, startAt, endAt;
    let filterValue = storeQueryParam.filterValue;

    if (pageIndexes.length > 0) {
      if (page < pageIndexes.length) {
        // already visited page
        startAt = pageIndexes[page].first;
        endAt = pageIndexes[page].last;
      } else {
        // forward
        startAfter = pageIndexes[page - 1].last;
      }
    }

    if (filterValue) {
      if (filterValue.startsWith("id:")) {
        filter = "_id";
        filterValue = filterValue.replace("id:", "").trim();
      } else {
        filter = "_q";
        filterValue = filterValue.toLowerCase();
      }
    }

    const storeLib = new StoreLib();

    try {
      const res = await storeLib.getStoresPaginated(
        limit,
        filter,
        filterValue,
        startAfter,
        startAt,
        endAt,
        onlyEnabledStores
      );

      if (res.success === false) {
        throw res.error;
      }

      let data = res.data;
      const first = data[0]._id;
      const last = data[data.length - 1]._id;

      if (pageIndexes.length === 0 || page >= pageIndexes.length) {
        //first load
        pageIndexes.push({ first, last });
      } else if (page < pageIndexes.length) {
        pageIndexes[page] = {
          first,
          last,
        };
      }

      data = res.data.map((b) => {
        return {
          ...b,
          key: b._id,
          display_store_probability: `1/${b.store_probability} (${
            Math.round((100 / b.store_probability) * 100) / 100
          }%)`,
          avatar: b.profilePhotoUrl,
          displayId: b._id,
        };
      });

      console.log(data);
      setStores(data);
      setRequirements((prev) => {
        return { ...prev, hasStore: true };
      });
    } catch (error) {
      console.log(error);
      console.log("No stores");
      setStores([]);
      setRequirements((prev) => {
        return { ...prev, hasStore: false };
      });
    } finally {
      setLoadingStores(false);
    }
  }, [storeQueryParam]);

  const checkStripeRequirements = useCallback(() => {
    console.log(props);
    const newStatus = {
      isStripeAccountActive: false,
      requiresAccountData: false,
      accountStatus: "",
      accountStatusMessage: "",
    };

    const { merchant } = props;
    console.log("Checking requirements", merchant);
    if (merchant) {
      if (merchant.stripe_requirements) {
        switch (merchant.stripe_requirements.disabled_reason) {
          case "requirements.past_due":
            newStatus.accountStatus = "Non attivo";
            newStatus.accountStatusMessage = "Informazioni mancanti";
            newStatus.isStripeAccountActive = false;
            newStatus.requiresAccountData = true;

            break;
          case "requirements.pending_verification":
            newStatus.accountStatus = "Non attivo";
            newStatus.accountStatusMessage =
              "Stiamo verificando le informazioni fornite";
            newStatus.isStripeAccountActive = false;
            break;
          case null:
            newStatus.accountStatus = "Attivo";
            newStatus.accountStatusMessage = "";
            newStatus.isStripeAccountActive = true;
            break;
          default:
            newStatus.accountStatus = "Non Attivo";
            newStatus.accountStatusMessage =
              "Ci sono problemi con i dati che ci hai fornito, contattaci";
            newStatus.isStripeAccountActive = false;
            break;
        }
      } else {
        newStatus.accountStatus = "Non Attivo";
        newStatus.accountStatusMessage = "Informazioni mancanti";
      }
      if (
        !merchant.stripe_account_id ||
        !merchant.stripe_ext_account_id ||
        !merchant.stripe_owner_id
      ) {
        newStatus.accountStatus = "Non Attivo";
        newStatus.accountStatusMessage =
          "Ci sono problemi con i dati che ci hai fornito, contattaci";
        newStatus.isStripeAccountActive = false;
      }
    }

    setRequirements((prev) => {
      return {
        ...prev,
        ...newStatus,
      };
    });

    return newStatus;
  }, [props]);

  return (
    <MayPPageTemplate
      title={`Benvenuto ${props.merchant.company_name || ""}`}
      description={
        // !requirements.allDone
        //   ? "Ben fatto! Manca poco per cominciare a offrire Maypay ai tuoi clienti:"
        //   :

        "Ben fatto! Hai registrato il tuo account Maypay. Ora puoi cominciare a configurare uno o più negozi fisici e/o il tuo eCommerce."
      }
    >
      <action>
        <div className={styles.actionsContainer}>
          {!loadingStores && !requirements.hasStore && (
            <ButtonWrap
              onClick={() => {
                if (props.onAction) {
                  props.onAction("addFirstStore");
                }
              }}
            >
              <div className={styles.left}>
                <div className={styles.title}>
                  Aggiungi il tuo primo negozio
                </div>
                <div className={styles.state}></div>
                <div className={styles.message}>Informazioni mancanti</div>
              </div>
              <div className={styles.right}>
                <div className={styles.roundIcon}>
                  <img
                    src={AddIcon}
                    style={{ width: "15px", height: "auto" }}
                    alt="crea profilo"
                  />
                </div>
              </div>
            </ButtonWrap>
          )}

          {!loadingStores && !requirements.isStripeAccountActive && (
            <ButtonWrap
              onClick={async () => {
                if (requirements.requiresAccountData) {
                  const cfLib = new CFLib();

                  try {
                    const res = await cfLib.getStripeCustomAccount({
                      refresh_url:
                        redirectUrl /*"https://localhost:3001/redirect.html",/**/,
                      return_url:
                        redirectUrl /*"https://localhost:3001/redirect.html"/**/,
                    });


                    if (res.success) {
                      if (
                        res.data.account.requirements.disabled_reason === null
                      ) {
                        //DB is not up-to-date
                        await updateDoc(
                          doc(
                            collection(getFirestore(), "merchants"),
                            getAuth().currentUser.uid
                          ),
                          {
                            stripe_requirements: res.data.account.requirements,
                          }
                        );
                      } else {
                        //Openlink
                        window.open(
                          res.data.accountLink.url,
                          "_blank"
                        );
                        //console.log(rwindow);
                        window.addEventListener("message", (e) => {
                          if (e.data === "redirected") {
                            //this.checkStripe();
                          }
                        });
                      }
                    }
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  window.open(
                    "https://www.maypay.com/assistenza-esercenti/",
                    "_blank"
                  );
                }
              }}
            >
              <div className={styles.left}>
                <div className={styles.title}>Account</div>
                <div className={styles.status}>
                  {requirements.accountStatus}:{" "}
                  {requirements.accountStatusMessage}
                </div>
                <div className={styles.message}></div>
              </div>
              <div className={styles.right}>
                {requirements.requiresAccountData && (
                  <MayPWithSpinner waiting={loadingStores}>
                    <div className={styles.roundIcon}>
                      <img
                        src={AddIcon}
                        style={{ width: "15px", height: "auto" }}
                        alt="completa account"
                      />
                    </div>
                  </MayPWithSpinner>
                )}
              </div>
            </ButtonWrap>
          )}
          {requirements.allDone && (
            <div className={styles.reqBox}>
              <div className={styles.left}>
                <div className={styles.title}>Profilo</div>
                <div className={styles.state}></div>
                <div className={styles.message}>Esercente Abilitato</div>
              </div>
              <div className={styles.right}>
                <ButtonRound onClick={() => {}}>
                  <img
                    src={CheckIcon}
                    style={{ width: "15px", height: "auto" }}
                    alt="All done"
                  />
                </ButtonRound>
              </div>
            </div>
          )}
        </div>
      </action>
      <content>
        <div className={styles.container}>
          <MayPWithSpinner waiting={loadingStores}>
            {requirements.allDone && (
              <>
                <div className={styles.welcomeMessage}>
                  Ecco come appaiono i profili dei tuoi negozi sull'app Maypay
                  degli utenti:
                </div>

                <div className={"titleH2"}>E-commerce</div>
                {stores.filter((s) => s.store_type === StoreType.ONLINE)
                  .length === 0 && (
                  <div className={styles.infoMessage}>
                    Non hai ancora registrato un e-commerce.
                  </div>
                )}
                <div className={styles.onlineStoreContainer}>
                  {stores
                    .filter((s) => s.store_type === StoreType.ONLINE)
                    .map((store, i) => {
                      return (
                        <MayPOnlineStoreBadge
                          key={i}
                          store={store}
                          hideStarred={true}
                          onItemClick={(item) => {
                            if (props.onAction) {
                              props.onAction("onStoreClick", {
                                storeId: store._id,
                              });
                            }
                          }}
                        />
                      );
                    })}
                </div>
                <div className={"titleH2"}>Negozi fisici</div>
                {stores.filter((s) => s.store_type === StoreType.PHYSICAL)
                  .length === 0 && (
                  <div className={styles.infoMessage}>
                    Non hai ancora registrato negozi fisici.
                  </div>
                )}
                <div className={styles.contentContainer}>
                  {stores
                    .filter((s) => s.store_type === StoreType.PHYSICAL)
                    .map((store, i) => {
                      return (
                        <MayPStoreBadge
                          key={i}
                          store={store}
                          hideStarred={true}
                          onItemClick={(item) => {
                            if (props.onAction) {
                              props.onAction("onStoreClick", {
                                storeId: store._id,
                              });
                            }
                          }}
                        />
                      );
                    })}
                </div>
              </>
            )}
          </MayPWithSpinner>
        </div>
      </content>
    </MayPPageTemplate>
  );
}
