import React from "react";

class MayPPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    if (this.props.onShow) {
      this.props.onShow(this.props.page);
    }
  }

  render() {
    //Must be extended
    return;
  }
}

class MayPPager extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { currentPage: props.page };
    //this.currentPageRef = React.createRef();

  }

  render() {
    const { page, pages, ...other } = this.props;
    console.log("rendering page", this.props.page);
    if (
      this.props.pages &&
      this.props.pages.length > this.props.page &&
      this.props.page >= 0
    ) {
      let page = this.props.pages[this.props.page];
      return (
        <div className="MayPPage-root">
          <page.el {...page.props} {...other} />
        </div>
      );
    } else {
    }
  }
}

export default MayPPager;

export { MayPPage };
