import React from "react";
import styles from "./MayPCheck.module.css"
import CheckedIc from "../../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_checkbox_checked.svg"
import UncheckedIc from "../../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_checkbox_empty.svg"

class MayPCheck extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: props.checked
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.checked !== this.props.checked){
            this.setState({
                checked: this.props.checked
            })
        }
    }

    render(){
        return(
            <>
                <div className={styles.container}>

                    {this.props.appearance === 'checkbox' &&
                    (
                        <button className={styles.checkbox} id={this.props.id} onClick={(event) => {
                            const isChecked = !this.state.checked
                            this.setState({checked: isChecked})
                            this.props.onChange(isChecked)
                        }}>
                            {this.state.checked && 
                                <img src={CheckedIc} alt="checkbox-checked"/>
                            }
                            {!this.state.checked && 
                                <img src={UncheckedIc} alt="checkbox-checked"/>
                            }
                        </button>
                    )
                    }
                    {this.props.appearance === 'radio' && 
                    (
                        <button id={this.props.id} className={styles.outerCircle} onClick={(event) => {
                            const isChecked = !this.state.checked
                            this.setState({checked: isChecked})
                            this.props.onChange(isChecked)
                        }}>
                            {this.state.checked &&
                                <div className={styles.innerCircle}></div>
                            }
                        </button>
                    )}
                    <div className={styles.label}>{this.props.label}</div>

                </div>
            </>
        )
    }
}

export default MayPCheck;