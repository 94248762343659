import React from "react";
import styles from "./MayPOnlineStoreBadge.module.css";
import { StoreType } from "../../libs/utils";
import { Avatar } from "@material-ui/core";

class MayPOnlineStoreBadge extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      storePic: undefined,
      bottomMessage: "",
      missingContest: false,
      missingPhotos: false,
    };
    this.details = "";

    if (this.props.store.store_type === StoreType.PHYSICAL) {
      this.details = () => {
        return (
          <>
            {this.props.store.store_address},{" "}
            {this.props.store.store_address_number}
            <br />
            {this.props.store.store_cap}, {this.props.store.store_city}
          </>
        );
      };
    } else if (this.props.store.store_type === StoreType.ONLINE) {
      this.details = () => {
        return <>{this.props.store.store_web_site}</>;
      };
    }
  }

  componentDidMount() {
    if (!this.props.store.registered_to_contest) {
      this.setState({
        bottomMessage: "In attesa di attivazione del concorso",
        missingContest: true,
      });
    } else if (
      !this.props.store.profilePhotoUrl ||
      !this.props.store.storePhotoUrl
    ) {
      this.setState({
        bottomMessage: "Immagini mancanti",
        missingPhotos: true,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={styles.container}
          onClick={() => {
            if (this.props.onItemClick) {
              this.props.onItemClick(this.props.store);
            }
          }}
        >
          {/* {this.props.store.profilePhotoUrl && ( */}
            <>
              <Avatar
                src={this.props.store.profilePhotoUrl}
                style={{
                  width: "82px",
                  height: "82px",
                  boxShadow: "0px 5px  40px #00000029",
                  backgroundColor: "#00A2E0",
                  fontSize: "48px",
                  margin: "0 auto",
                }}
              >
                {this.props.store.store_name
                  ? this.props.store.store_name[0].toUpperCase()
                  : ""}
              </Avatar>
              <div className={styles.storeName}>
                {this.props.store.store_name
                  ? this.props.store.store_name
                  : ""}
              </div>
            </>
          {/* )} */}
        </div>
      </React.Fragment>
    );
  }
}

export default MayPOnlineStoreBadge;
