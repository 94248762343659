export const clearStoreData = () => ({
  type: "CLEAR_STORE_DATA",
});

export const updateStore = (store, profilePicURL, storePicURL) => ({
  type: "UPDATE_STORE",
  store,
  profilePicURL,
  storePicURL,
});
