import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPDialog from "../../common/components/utils/MayPDialog";
import StoreLib from "../../libs/store";
import { withRouter } from "react-router-dom";
import ProfileStorePreview from "./profileStorePreview";
import ProfileStoreEditor from "./profileStoreEditor";
import { Routes } from "../../common/libs/utils";
import MayPPageTemplate from "../../common/components/utils/MayPPageTemplate";

class Profile extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;

    // this.onContinue = this.onContinue.bind(this);
    this.storeLib = StoreLib();

    this.state = {
      editing: false,
      waiting: false,
      loadingStore: false,
      error: 0,
      errorMessage: "",
      openDialog: false,
      dialogActions: [],
      onDialogClose: null,
      dialogMessage: "",
      dialogTitle: "",
      store: null,
    };

    this.contentRef = React.createRef();
    this.storeId = null;
    // this.fillFormItems = this.fillFormItems.bind(this);
    this.addingStore = this.storeId === null;
  }

  async componentDidMount() {
    window.scroll(0, 0);
    if (this.contentRef && this.contentRef.current) {
      this.contentRef.current.parentElement.parentElement.scroll(0, 0);
    }

    if (this.props.location.pathname === Routes.AddStore) {
      this.setState({
        editing: false,
      });
      this.setState({
        store: {},
      });
    } else {
      this.setState({
        loadingStore: true,
      });
      const storeLib = new StoreLib();
      const split = this.props.location.pathname.split("/");
      this.storeId = split[split.length - 1];
      const result = await storeLib.getStoreById(this.storeId);
      console.log(result);
      if (!result.success) {
        console.log(result.error);
        this.setState({
          loadingStore: false,
        });
        return;
      }
      console.log(result.data);
      this.setState({
        store: result.data,
      });

      // this.fillFormItems(result.data);
    }
  }

  render() {
    return (
      <MayPPageTemplate title="Profilo Negozio">
        <content>
          <div ref={this.contentRef} className="profileMainContainer">
            <div className="store-content profile">
              {!this.storeId && (
                <ProfileStoreEditor
                  store={null}
                  editing={this.state.editing}
                  onAdding={(data) => {
                    if (this.props.onSubmit) {
                      this.props.onSubmit("on-store-added", data);
                    }
                  }}
                />
              )}
              {this.storeId && this.state.store && !this.state.editing && (
                <ProfileStorePreview
                  store={this.state.store}
                  onEditing={() => {
                    this.setState({
                      editing: true,
                    });
                  }}
                />
              )}
              {this.storeId && this.state.store && this.state.editing && (
                <ProfileStoreEditor
                  store={this.state.store}
                  editing={this.state.editing}
                  onSave={() => {
                    window.location.reload();
                    this.setState({
                      editing: false,
                    });
                  }}
                />
              )}
            </div>

            <MayPDialog
              forMerchant={true}
              open={this.state.openDialog}
              title={this.state.dialogTitle}
              message={this.state.dialogMessage}
              actions={this.state.dialogActions}
              onClose={this.state.onDialogClose}
            />
          </div>
        </content>
      </MayPPageTemplate>
    );
  }
}

export default withRouter(Profile);
