import React from "react";
import { Container } from "@material-ui/core";
import Carousel from "react-elastic-carousel";
import ButtonBase from "@material-ui/core/ButtonBase";
import { wrap } from "../common/components/utils/MayPButton";
import { MayPPage } from "../common/components/utils/MayPPager";
import { MayPButtonTextWrap } from "../common/components/utils/MayPButtonText";
import styles from "./Presentazione.module.css";
import pageIc1 from "../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_merch_pres1.svg";
import pageIc2 from "../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_merch_pres2.svg";
import pageIc3 from "../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_merch_pres3.svg";
import pageIc4 from "../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_merch_pres4.svg";
import pageIc5 from "../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_merch_pres5.svg";
import pageIc6 from "../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_merch_pres6.svg";
import leftArrow from "../assets/icons/@MAYPAY_UIKIT_MERCHANT/left_arrow_white.svg";
import rightArrow from "../assets/icons/@MAYPAY_UIKIT_MERCHANT/right_arrow_white.svg";
import CheckIcon from "../assets/registrazione/mp_check_icon.svg";
import { connect } from "react-redux";
import { setPresentationVisibility } from "../redux/actions/preferences";

const ButtonRound = wrap({
  root: {
    minWidth: 0,
    width: "75px",
    height: "75px",
    borderRadius: "100%",
    backgroundImage: "none",
    backgroundColor: "#00e091",
    boxShadow: "0px 20px 50px #00000029",
  },
});

const Buttontext = MayPButtonTextWrap({
  root: {
    fontSize: "16px",
    letterSpacing: "1.6px",
    lineHeight: "22px",
    fontWeight: "normal",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #00E091",
    color: "#00E091",
  },
});

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <div className={styles.page} key={this.props.k}>
        <div className={styles["page-icon-box"]}>
          <img src={this.props.icon} alt="icon" />
        </div>
        <div className={styles["page-title"]}>{this.props.title}</div>
        <div className={styles["page-content"]}>{this.props.children}</div>
      </div>
    );
  }
}

class Presentazione extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Container
        classes={{ root: styles["presentazione-root"] }}
        style={{ padding: 0, maxWidth: "100%" }}
        onClick={()=>{

        }}
      >
        <Carousel
          itemsToShow={1}
          className={styles.carousel}
          showArrows={true}
          renderArrow = {({type, onClick}) =>{
            console.log(type);
            return(
              
              <div className={styles.arrowContainer}>
                <div onClick={onClick} className={styles.arrow}>
                  {type === 'PREV' &&<img src={leftArrow} alt="left"/>}
                  {type === 'NEXT' &&<img src={rightArrow} alt="right"/>}
                </div>
              </div>
              )
            } 
          }
          renderPagination={({ pages, activePage, onClick }) => {
            if (activePage !== 5 || this.props.hideActions) {
              return (
                <div className={styles["rec-pagination"]}>
                  <div className={styles["pagination"]}>
                    <div className={styles["pagination-wrapper"]}>
                      {pages.map((page) => {
                        const isActivePage = activePage === page;
                        let dot_class = styles["rec-dot"];
                        if (isActivePage) {
                          dot_class += " " + styles["rec-dot_active"];
                        }
                        return (
                          <div
                            className={dot_class}
                            key={page}
                            onClick={() => onClick(page)}
                            active={isActivePage.toString()}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className={styles["scorri"]}>Scorri</div>
                  <div className={styles["salta"]}>
                    {!this.props.hideActions && (
                      <ButtonBase>
                        <span
                          className={styles["salta-btn"]}
                          onClick={this.props.hidePresentation}
                        >
                          Salta presentazione
                        </span>
                      </ButtonBase>
                    )}
                  </div>
                </div>
              );
            } else {
              return <span />;
            }
          }}
          transitionMs={250}
        >
          <Page
            k="0"
            title={
              <span>
                Tutti vorranno
                <br />
                pagare
              </span>
            }
            icon={pageIc1}
          >
            <p>
              Con Maypay, i tuoi clienti
              <br /> hanno sempre una possibilità
              <br /> di vincere il loro acquisto.
              <br /> Sarà Maypay a saldare
              <br /> l’importo al loro posto.
            </p>
          </Page>
          <Page
            k="1"
            title={
              <span>
                Crea il tuo profilo
                <br />
                Business
              </span>
            }
            icon={pageIc2}
          >
            <p>
              Dopo aver registrato il tuo account,
              <br />
              crea il profilo del tuo negozio
              <br />
              che sarà visualizzato
              <br />
              da tutti i clienti Maypay.
              <br />
            </p>
          </Page>
          <Page
            k="2"
            title={
              <span>
                Conferma
                <br />
                l’importo
              </span>
            }
            icon={pageIc3}
          >
            <p>
              Il cliente ti invia
              <br />
              la richiesta di pagamento
              <br />
              per una conferma.
            </p>
          </Page>
          <Page
            k="3"
            title={
              <span>
                Se il cliente
                <br />
                vince, non paga
              </span>
            }
            icon={pageIc4}
          >
            <p>
              In caso di vincita,
              <br /> l’importo non è corrisposto dal cliente,
              <br /> ma saldato da Maypay.
              <br />
              Puoi sempre visualizzare
              <br /> la cronologia delle transazioni.
            </p>
          </Page>
          <Page
            k="4"
            title={
              <span>
                Se il cliente
                <br />
                non vince, paga
              </span>
            }
            icon={pageIc5}
          >
            <p>
              Quando il cliente non vince,
              <br />
              il pagamento avviene direttamente
              <br />
              via app, tramite la carta di credito o<br />
              prepagata registrata dal cliente.
              <br />
            </p>
          </Page>
          <Page
            k="5"
            title={
              <span>
                Inizia a vendere
                <br />
                con le emozioni
              </span>
            }
            icon={pageIc6}
          >
            <p>
              Rendi unica ed emozionante <br />
              l’esperienza d’acquisto <br />
              dei tuoi clienti, trasformando <br />
              il pagamento in intrattenimento!
            </p>
            {!this.props.hideActions && (
              <React.Fragment>
                <div className={styles["round-button-container"]}>
                  <ButtonRound onClick={this.props.hidePresentation}>
                    <img
                      src={CheckIcon}
                      style={{ width: "28px", height: "auto" }}
                      alt="continua"
                    />
                  </ButtonRound>
                </div>
                <div className={styles["page-title2"]}>
                  <Buttontext
                    style={{ "WebkitTextStroke": "0.7px #00E091" }}
                    onClick={this.props.onend}
                  >
                    Inizia ora
                  </Buttontext>
                </div>
              </React.Fragment>
            )}
          </Page>
        </Carousel>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hidePresentation: () => dispatch(setPresentationVisibility(false)),
  };
};

export default connect(null, mapDispatchToProps)(Presentazione);
