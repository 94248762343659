import React from "react";
import styles from "./MayPDialog.module.css";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExitIc from "../assets/@MAYPAY_UIKIT_BUYER/maypay_icon_x_blue.svg"
import { IconButton } from "@material-ui/core";

class MayPDialog extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
          classes={{
            root: styles.root,
            container: this.props.forMerchant
              ? styles.containerBlue
              : styles.container,
            paper: styles.paper,
          }}
          open={this.props.open}
          onClose={() => {
            console.log("outside click")
            if (this.props.onClose) {
              this.props.onClose();
            }
          }}
          style={{
            display:  this.props.open? "inherit": "none"
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={this.props.maxWidth || false}
        >
          {this.props.showCloseButton && (
            <div className={styles.closeButtonContainer}>
              <IconButton onClick={() => {
                if (this.props.onClose) {
                  this.props.onClose();
                }
              }}>
                <img src={ExitIc} alt="close dialog"/>
              </IconButton>
            </div>
          )}
          {this.props.title && (
            <DialogTitle
              id="alert-dialog-title"
              classes={{
                root: styles.title,
              }}
            >
              {this.props.title || ""}
            </DialogTitle>
          )}
          {this.props.message && (
            <DialogContent
              classes={{
                root: this.props.title ? styles.content : styles.onlyContent,
              }}
            > 
              {this.props.message}
            </DialogContent>
          )}
          {this.props.actions && this.props.actions.length > 0 && (
            <DialogActions
              classes={{
                root: styles.actions,
              }}
            >
              {this.props.actions.map((action, i) => {
                return (
                  <Button
                    style={{
                      fontFamily: "Giorgio",
                      fontSize: "12px",
                      letterSpacing: "1.2px",
                      lineHeight: "20px",
                      textTransform: "none",
                      "WebkitTextStroke": "0.7px #00A2E0",
                      color: "#00A2E0",
                    }}
                    key={"action-" + action + "-" + i}
                    onClick={() => {
                      if (this.props.onClose) {
                        this.props.onClose(action);
                      }
                    }}
                    color="primary"
                  >
                    {action}
                  </Button>
                );
              })}
            </DialogActions>
          )}
        </Dialog>
      </React.Fragment>
    );
  }
}

export default MayPDialog;