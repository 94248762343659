/**
 * Helper Class representing an Error
 */
export class MPError {
  /**
   * Create a new MPError
   * @param {String} message - Error message
   * @param {Number} code - Error Code
   * @param {Object} trace - Previous Error
   */
  constructor(message, code, trace = undefined) {
    this.message = message;
    this.code = code;

    if (trace && trace.errorInfo) {
      this.trace = trace.errorInfo;
    } else if (trace instanceof Error) {
      this.trace = trace.message;
    } else {
      this.trace = trace;
    }
  }

  /**
   * Static builder for MPError
   * @param {Object} error - Error
   * @param {Object} trace - Previous Error
   * @returns instance of MPError
   */
  static build(error /* Error from MPErrors */, trace = undefined) {
    if ("code" in error && "message" in error) {
      return new MPError(error.message, error.code, trace);
    }
    throw MPError.build(MPErrors.InvalidArgument);
  }
}

/**
 * Class used to wrap HTTP errors
 */
export class ApiError {
  /**
   * Create a new ApiError
   * @param {MPError} mpError
   * @param {Number} httpStatus - HTTP Response code
   */
  constructor(mpError, httpStatus) {
    this.httpStatus = httpStatus;
    this.error = mpError;
  }
}

export const parseError = (error) => {
  let lastCode, lastError;

  if (typeof error === "string") {
    return {
      message: "Dettagli: " + error,
      code: null,
    };
  }

  if (error instanceof Error) {
    return {
      message: "Dettagli: " + error.message,
      code: null,
    };
  }

  const parse = (error) => {
    if (!error) {
      return;
    }
    if (error.code) {
      lastCode = error.code;
      lastError = error.message;
    }
    if (error.trace && typeof error.trace === "string") {
      lastError = error.trace;
      return;
    }
    parse(error.trace?.error || error.trace);
  };

  parse(error);

  const messageCode = `Codice Errore: ${lastCode}. `;
  const messageError = lastError ? "Dettagli: " + lastError : "";
  return {
    message: messageCode + messageError,
    code: lastCode,
  };
};

/**
 * List of possible errors
 */
export const MPErrors = {
  /* -------------------------------------------------------------------------- */
  /*                          Logic Errors | 100 - 199                          */
  /* -------------------------------------------------------------------------- */
  InvalidArgument: {
    code: 1,
    message: "Invalid Argument",
  },
  DuplicationError: {
    code: 2,
    message: "Unique entity found twice",
  },
  /* -------------------------------------------------------------------------- */
  /*                            Local DB | 300 - 399                            */
  /* -------------------------------------------------------------------------- */
  UserNotFound: {
    code: 301,
    message: "User not found",
  },
  LogNotFound: {
    code: 302,
    message: "Log not found",
  },
  AlreadyExists: {
    code: 303,
    message: "Already exists",
  },
  FailedTransferNotFound: {
    code: 304,
    message: "Failed Transfer not found",
  },
  TrendPointsNotFound: {
    code: 305,
    message: "Trend Points not found",
  },
  CommunicationNotFound: {
    code: 306,
    message: "Communication not found",
  },
  ResetPasswordRequestNotFound: {
    code: 304,
    message: "Reset password request not found",
  },
  /* -------------------------------------------------------------------------- */
  /*                         Request Errors | 400 - 499                         */
  /* -------------------------------------------------------------------------- */

  NotAuthorized: {
    code: 401,
    message: "Permission Denied",
  },
  LoginRequired: {
    code: 402,
    message: "Expired refresh token. Re-login required",
  },
  MissingRequired: {
    code: 403,
    message: "Missing required information",
  },
  UnknownProperty: {
    code: 404,
    message: "Unknown Property",
  },
  InvalidMethod: {
    code: 405,
    message: "Route does not support this HTTP Request Method",
  },
  CannotBeUpdated: {
    code: 406,
    message: "This property cannot be updated",
  },
  WeakPassword: {
    code: 407,
    message: "Password too weak",
  },
  InvalidPermissionLevel: {
    code: 409,
    message: "Invalid Permission Level",
  },
  WrongPassword: {
    code: 411,
    message: "Wrong Password",
  },
  CannotOrderBy: {
    code: 412,
    message: "Cannot orderBy this property",
  },
  BadFilterParameters: {
    code: 413,
    message: "Error parsing filters",
  },
  CannotSearchOnProperty: {
    code: 414,
    message: "Cannot search on this property",
  },
  InvalidDateString: {
    code: 415,
    message: "Invalid Date String",
  },
  InvalidMultipartFormData: {
    code: 416,
    message: "Invalid Multipart Form Data",
  },
  AuthenticatedRequest: {
    code: 417,
    message:
      "Authenticated request on a page that doesn't require authentication. Redirecting to homepage...",
  },
  InvalidContentType: {
    code: 418,
    message: "Invalid Content-Type.",
  },
  WrongAPI: {
    code: 419,
    message: "Invalid API usage. See error.trace for details.",
  },
  /* -------------------------------------------------------------------------- */
  /*                          Server Error | 500 - 599                          */
  /* -------------------------------------------------------------------------- */

  UpdateError: {
    code: 501,
    message: "Something went wrong during update",
  },
  DeleteError: {
    code: 502,
    message: "Something went wrong during delete",
  },
  SearchError: {
    code: 503,
    message: "Something went wrong during search",
  },
  AddError: {
    code: 504,
    message: "Something went wrong during insertion",
  },
  ListError: {
    code: 505,
    message: "Something went wrong listing",
  },
  LogError: {
    code: 506,
    message: "Something went wrong logging",
  },
  MailError: {
    code: 507,
    message: "Something went wrong sending mail",
  },
  ResetError: {
    code: 508,
    message: "Something went wrong resetting password",
  },
  /* -------------------------------------------------------------------------- */
  /*                         Firebase Errors | 600 - 699                        */
  /* -------------------------------------------------------------------------- */
  FirebaseUnknownProperty: {
    code: 601,
    message: "Unknown Firebase Property",
  },
  FirebaseEmptyRequest: {
    code: 602,
    message: "Empty Firebase request",
  },
  FirebaseUpdateAuthError: {
    code: 603,
    message: "Something went wrong updating Authentication",
  },
  FirebaseUpdateFirestoreError: {
    code: 604,
    message: "Something went wrong updating Firestore data",
  },
  FirebaseInvalidCollection: {
    code: 605,
    message: "Colletion does not exists",
  },
  FirebaseSearchError: {
    code: 606,
    message: "Something went wrong searching account",
  },
  FirebaseDataNotFound: {
    code: 607,
    message: "Data not found",
  },
  FirebaseUnsupportedTimeFrame: {
    code: 608,
    message: "Unsupported time frame",
  },
  FirebaseContestError: {
    code: 609,
    message: "Something went wrong reading Contest",
  },
  FirebaseAddContestError: {
    code: 610,
    message: "Something went wrong adding Contest",
  },
  FirebaseAwsErrorsError: {
    code: 611,
    message: "Something went wrong reading AWS Errors",
  },
  FirebaseCommunicationsError: {
    code: 612,
    message: "Something went wrong reading Communications",
  },
  FirebaseAddCommunicationsError: {
    code: 613,
    message: "Something went wrong adding Communications",
  },
  FirebaseStorageUploadError: {
    code: 614,
    message: "Something went wrong uploading files to storage",
  },
  FirebaseSettingError: {
    code: 615,
    message: "Something went wrong getting setting",
  },
  FirebaseBuyerSearchError: {
    code: 621,
    message: "Something went wrong searching Buyer",
  },
  FirebaseBuyerProfilePicError: {
    code: 622,
    message: "Something went wrong updating buyer's profile picture",
  },
  FirebaseMerchantSearchError: {
    code: 641,
    message: "Something went wrong searching Merchant",
  },
  FirebaseFailedTransferError: {
    code: 660,
    message: "Something went wrong searching failed transfers",
  },
  FirebaseResolveTransferError: {
    code: 661,
    message: "Something went wrong resolving failed transfers",
  },
  FirebaseCreateTransferError: {
    code: 662,
    message: "Something went wrong creating transfers",
  },
  FirebaseTransactionStatisticsError: {
    code: 663,
    message: "Something went wrong computing transactions statistics",
  },
  FirebaseTransactionSearchError: {
    code: 664,
    message: "Something went wrong searching transactions",
  },
  FirebaseStoreError: {
    code: 680,
    message: "Something went wrong searching store",
  },
  FirebaseAddStoreError: {
    code: 681,
    message: "Something went wrong adding store",
  },
  FirebaseUpdateStoreStatusError: {
    code: 682,
    message: "Something went wrong updateting store status",
  },
  FirebaseCreateApiKeyError: {
    code: 683,
    message: "Something went wrong creating API Key"
  },
  FirebaseRevokeApiKeyError: {
    code: 684,
    message: "Something went wrong revoking API Key"
  },
  /* -------------------------------------------------------------------------- */
  /*                          Stripe Errors | 700 - 799                         */
  /* -------------------------------------------------------------------------- */
  StripeCreateTransferError: {
    code: 701,
    message: "Something went wrong creating stripe transfer",
  },
  StripeRetrieveBalanceError: {
    code: 702,
    message: "Stripe.balance.retrieve failed",
  },
  StripePayoutsError: {
    code: 703,
    message: "Something went wrong retrieving stripe payouts",
  },
  StripeAccountNotFound: {
    code: 704,
    message: "Account id not found on Stripe",
  },
  StripeTransactionError: {
    code: 705,
    message: "Something went wrong retrieving stripe transactions",
  },
  StripeTransactionNotFound: {
    code: 706,
    message: "Stripe Transaction not found",
  },
  StripeAccountUpdateError: {
    code: 707,
    message: "Cannot update account",
  },
  /* -------------------------------------------------------------------------- */
  /*                           JWT Errors | 800 - 899                           */
  /* -------------------------------------------------------------------------- */
  JwtInvalidAccessToken: {
    code: 801,
    message: "Invalid Access Token",
  },
  JwtInvalidRefreshToken: {
    code: 802,
    message: "Invalid Refresh Token",
  },
};
