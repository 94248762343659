const MAX_PRECISION = 7;
const MAX_PRECISION_MUL = 1e7;

/**
 *  Class used to work with coordinates
 */
export class GStringNumber {
  /**
   * @param value {number | string} the value of the coordinate es. 90.1203
   * @param precision {number} Used only if value is a number. Express the number of decimal places to consider
   */
  constructor(value, precision = undefined) {
    this.sign = 1;
    if (typeof value === "number") {
      if (precision === undefined) throw new Error("precision missing");

      if (value < 0) {
        this.sign = -1;
      }
      this.num = Math.abs(Math.round(value * MAX_PRECISION_MUL));
      this.precision = precision;
      this.toPrecision(precision);

      //this.pad();
    } else {
      const a = value.split(".");
      if (a.length < 2) {
        a.push(false);
      }
      let decimals = a[1] || "";

      this.num = Math.abs(
        parseInt(Math.round(parseFloat(value) * MAX_PRECISION_MUL))
      );
      this.sign = 1;
      if (parseFloat(value) < 0) {
        this.sign = -1;
      }
      this.precision = decimals.length;
    }
  }

  static parse(value) {
    let num = parseInt(value);
    let str = num.toString();
    let p = 7;
    for (let i = 0; i < MAX_PRECISION; i++) {
      if (str[str.length - 1 - i] === "0") {
        p--;
      } else {
        break;
      }
    }
    let f = (num / MAX_PRECISION_MUL).toFixed(p);

    return new GStringNumber(f);
  }
  pad(num, size) {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  }

  /**
   * Get the string value of the coordinate
   * @return {string} coordinate value
   */
  get str() {
    return this.pad(this.num, 3 + MAX_PRECISION);

    //return parseInt(this.num).toString();
  }

  get value() {
    return (
      parseFloat((this.num / MAX_PRECISION_MUL).toFixed(this.precision)) *
      this.sign
    );
  }

  /**
   * Get the numeric value of the decimal place for a specific precision
   * @param precision {number} The desired precision
   */
  getPrecisionValue(precision) {
    if (precision <= this.precision) {
      return parseInt(this.decimals[precision - 1]);
    } else {
      return 0;
    }
  }

  /**
   * Returns the GStringNumber of this GStringNumber with only a specific precision level set
   * Example: 123.123 partial(2) -> 0.023
   * @return {GStringNumber}
   */
  partial(precision) {
    const r = this.copy();
    if (precision <= r.precision && precision > 0) {
      const str = r.num.toString();
      const diff = str.length - 1 - MAX_PRECISION + precision;

      r.num = parseInt(str.substr(diff));
      //r.precision=precision;
    }
    return r;
  }

  /**
   * Set the current precision to the desired level
   */
  toPrecision(precision) {
    const mul = Math.pow(10, precision);
    this.num = parseInt(
      parseInt((this.num * mul) / MAX_PRECISION_MUL) * (MAX_PRECISION_MUL / mul)
    );
    //this.num =parseInt( this.str.substring(0,3+precision));

    this.precision = precision;
    return this;
  }

  /**
   * Get next value
   */
  next(steps = 1, precision = undefined) {
    let r = this.copy();
    if (precision === undefined) {
      precision = r.precision;
    }
    r.toPrecision(precision);
    const v = Math.pow(10, MAX_PRECISION - r.precision) * steps;

    r.num += v;

    return r;
  }

  /**
   * get prev value
   */
  prev(steps = 1, precision = undefined) {
    let r = this.copy();
    if (precision === undefined) {
      precision = r.precision;
    }
    r.toPrecision(precision);
    const v = Math.pow(10, MAX_PRECISION - r.precision) * steps;

    r.num -= v;

    return r;
  }

  /**
   * Sums a GStringNumber to this
   */
  add(strnum) {
    let r = this.copy();
    if (strnum.precision < r.precision) {
      strnum.toPrecision(r.precision);
    } else {
      r.toPrecision(strnum.precision);
    }
    r.num = r.num * r.sign + strnum.num * strnum.sign;
    r.sign = Math.sign(r.num);
    r.num = Math.abs(r.num);
    return r;
  }
  /**
   * Sums a numeric coordinates to this
   */
  addNum(n) {
    const strnum = new GStringNumber(n.toString());
    return this.add(strnum);
  }

  /**
   * Subtracts a GStringNumber to this
   */
  sub(strnum) {
    let r = this.copy();
    if (strnum.precision < r.precision) {
      strnum.toPrecision(r.precision);
    } else {
      r.toPrecision(strnum.precision);
    }
    r.num = r.num * r.sign - strnum.num * strnum.sign;
    r.sign = Math.sign(r.num);
    r.num = Math.abs(r.num);
    return r;
  }

  /**
   * Subtracts a GStringNumber to this
   */
  subNum(n) {
    const strnum = new GStringNumber(n.toString());

    return this.sub(strnum);
  }

  static min() {
    if (arguments.length) {
      let m = arguments[0];
      for (let i = 0; i < arguments.length; i++) {
        if (arguments[i].num * arguments[i].sign < m.num * m.sign) {
          m = arguments[i];
        }
      }
      return m;
    }
    return null;
  }

  static max() {
    if (arguments.length) {
      let m = arguments[0];
      for (let i = 0; i < arguments.length; i++) {
        if (arguments[i].num * arguments[i].sign > m.num * m.sign) {
          m = arguments[i].copy();
        }
      }
      return m;
    }
    return null;
  }

  /**
   * Returns the string rappresentation of the coordinate
   */
  toString() {
    return this.str;
  }

  /**
   * Returns a copy of this
   */
  copy() {
    const c = GStringNumber.parse(this.str);
    c.sign = this.sign;
    c.precision = this.precision;
    return c;
  }
}

export class GHash {
  /**
   * Approximation of precision in meters of lat lon coordinates based on number
   * of decimal digits
   */
  static precision_levels = [111120, 11112, 1111.2, 111.12, 11.112, 1.1112];

  static R = 6378.1; //Radius of the Earth in Km

  /**
   * Truncates a float to a fixed decimal position
   */
  static truncateDecimals(num, digits) {
    var numS = num.toString(),
      decPos = numS.indexOf("."),
      substrLength = decPos === -1 ? numS.length : 1 + decPos + digits,
      trimmedResult = numS.substr(0, substrLength),
      finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

    return parseFloat(finalResult);
  }

  /**
   * Calculates the GHash with the desired precision for the given lat lon coordinates
   */
  static encode(p, precision = 3) {
    const i = p[0];
    const j = p[1];

    const sim = new GStringNumber(i, MAX_PRECISION).addNum(90);
    const sjm = new GStringNumber(j, MAX_PRECISION).addNum(180);
    const si = new GStringNumber(i, precision).addNum(90);
    const sj = new GStringNumber(j, precision).addNum(180);

    return {
      i: si,
      j: sj,
      hash:
        si.str.substr(0, 3 + precision) +
        "." +
        sjm.str +
        "." +
        sim.str.substring(3) +
        "." +
        precision,
      hash_r: sj.str.substr(0, 3 + precision) + "." + sim.str,
    };
  }

  /**
   * Retrives the lat lon coordinates of a given GHash.hash string
   */
  static decode(h) {
    let ah = h.split(".");
    const precision = GHash.getPrecision(parseInt(ah[ah.length - 1]));

    return [
      parseFloat(
        (
          parseInt(Number(ah[0]) / precision.mul) -
          90 +
          Number(ah[2]) / MAX_PRECISION_MUL
        ).toFixed(MAX_PRECISION)
      ),
      parseFloat(
        (Number(ah[1]) / MAX_PRECISION_MUL - 180).toFixed(MAX_PRECISION)
      ),
    ];
  }

  /**
   * Returns the radians value of an angle expressed in degrees
   */
  static radians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  /**
   * Returns the degrees value of an angle expressed in radians
   */
  static degrees(radians) {
    return (radians * 180) / Math.PI;
  }

  static distance(p1, p2) {
    const R = GHash.R * 1e3; // metres
    const f1 = GHash.radians(p1[0]); // φ, λ in radians
    const f2 = GHash.radians(p2[0]);
    const d1 = ((p2[0] - p1[0]) * Math.PI) / 180;
    const d2 = ((p2[1] - p1[1]) * Math.PI) / 180;

    const a =
      Math.sin(d1 / 2) * Math.sin(d1 / 2) +
      Math.cos(f1) * Math.cos(f2) * Math.sin(d2 / 2) * Math.sin(d2 / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d;
  }

  /**
   * Returns the coordinates o a point moved in a specific direciton and a specific
   * distance from the center
   */
  static move(center, meters, angle) {
    const brng = GHash.radians(angle);
    const d = meters / 1000;

    const lat1 = GHash.radians(center[0]);
    const lon1 = GHash.radians(center[1]);

    let lat2 = Math.asin(
      Math.sin(lat1) * Math.cos(d / GHash.R) +
        Math.cos(lat1) * Math.sin(d / GHash.R) * Math.cos(brng)
    );

    let lon2 =
      lon1 +
      Math.atan2(
        Math.sin(brng) * Math.sin(d / GHash.R) * Math.cos(lat1),
        Math.cos(d / GHash.R) - Math.sin(lat1) * Math.sin(lat2)
      );

    lat2 = GHash.degrees(lat2);
    lon2 = GHash.degrees(lon2);

    return [lat2, lon2];
  }

  /**
   * Returns the precision object for a specific radius
   */
  static calcPrecision(radius) {
    let p = 0.0;
    let decimals = 0;
    let mul = 0;
    let d = Math.abs(GHash.precision_levels[0] - radius);
    let ref = GHash.precision_levels[0];

    for (let i = 1; i < GHash.precision_levels.length; i++) {
      let d1 = Math.abs(GHash.precision_levels[i] - radius);
      if (d1 < d) {
        d = d1;
        ref = GHash.precision_levels[i];
        mul = Math.pow(10, i);
        decimals = 1 / mul;
        p = i;
      }
    }
    return {
      p: p,
      decimals: new GStringNumber(decimals, p).num,
      mul: mul,
      meters: ref,
    };
  }

  static getPrecision(precision) {
    if (precision < 0) {
      precision = 0;
    }
    let p = 0.0;
    let ref = GHash.precision_levels[precision];

    let mul = Math.pow(10, precision);
    let dec = 1 / mul;
    p = precision;

    return {
      p: p,
      decimals: new GStringNumber(dec, p).num,
      mul: mul,
      meters: ref,
    };
  }

  /**
   * Calculates the bounds from a center position and a given radius
   */
  static getBounds(center, radius, approximation = 7) {
    const d = radius; // * Math.SQRT2;

    const nd = GHash.distance(center, [90, center[1]]);
    const sd = GHash.distance(center, [-90, center[1]]);
    const wd = GHash.distance(center, [center[0], -180]);
    const ed = GHash.distance(center, [center[0], 180]);

    let precision = GHash.calcPrecision(d);

    if (precision.p > approximation) {
      precision = GHash.getPrecision(approximation);
    }
    if (precision.p <= 0) {
      precision = GHash.getPrecision(0);
    }

    const hc = {
      i: new GStringNumber(center[0], precision.p).addNum(90),
      j: new GStringNumber(center[1], precision.p).addNum(180),
    };

    let min = { i: hc.i, j: hc.j };
    let max = { i: hc.i, j: hc.j };

    [0, 180].forEach((angle) => {
      let pos = GHash.move(center, d, angle);

      if (angle === 0) {
        if (pos[0] < center[0]) {
          pos[0] = 90;
        }
        if (d > nd) {
          pos[0] = 90;
        }
      } else if (angle === 180) {
        if (pos[0] > center[0]) {
          pos[0] = -90;
        }
        if (d > sd) {
          pos[0] = -90;
        }
      }

      let hpos = {
        i: new GStringNumber(pos[0], precision.p).addNum(90),
        j: new GStringNumber(pos[1], precision.p).addNum(180),
      };

      min.i = GStringNumber.min(min.i, hpos.i);
      //min.j = GStringNumber.min(min.j, hpos.j);
      max.i = GStringNumber.max(max.i, hpos.i.next());
      //max.j = GStringNumber.max(max.j, hpos.j.next());
    });

    [90, 270].forEach((angle) => {
      let pos = GHash.move(center, d, angle);

      if (angle === 90) {
        if (pos[1] < center[1]) {
          pos[1] = 180;
        }
        if (d > ed) {
          //pos[1]=180;
        }
      } else if (angle === 270) {
        if (pos[1] > center[1]) {
          pos[1] = -180;
        }
        if (d > wd) {
          //pos[1]=-180;
        }
      }
      let hpos = {
        i: new GStringNumber(pos[0], precision.p).addNum(90),
        j: new GStringNumber(pos[1], precision.p).addNum(180),
      };

      //min.i = GStringNumber.min(min.i, hpos.i);
      min.j = GStringNumber.min(min.j, hpos.j);
      //max.i = GStringNumber.max(max.i, hpos.i.next());
      max.j = GStringNumber.max(max.j, hpos.j.next());
    });

    return {
      precision: precision,
      min: min,
      max: max,
    };
  }

  /**
   * Given a bound object claculate the new bounds by expanding the original bound for
   * the desired distance
   */
  static expandBounds(bounds, offset) {
    const newBounds = {
      precision: {},
      min: { i: 0, j: 0 },
      max: { i: 0, j: 0 },
    };
    const precision = GHash.calcPrecision(offset);
    const n = Math.ceil(offset / precision.meters);
    const min_i = GStringNumber.parse(bounds.min.i); //.toPrecision(precision.p);
    const max_i = GStringNumber.parse(bounds.max.i); //.toPrecision(precision.p);
    const min_j = GStringNumber.parse(bounds.min.j); //.toPrecision(precision.p);
    const max_j = GStringNumber.parse(bounds.max.j); //.toPrecision(precision.p);

    newBounds.min.i = min_i.prev(n, precision.p);
    newBounds.min.j = min_j.prev(n, precision.p);
    newBounds.max.i = max_i.next(n, precision.p);
    newBounds.max.j = max_j.next(n, precision.p);

    newBounds.precision = precision;

    return newBounds;
  }
}

export class GQuery {
  constructor(center, radius, approximation = 3) {
    this.center = center;
    this.radius = radius;
    this.approximation = approximation;
    this.__approximateCenter(approximation);
    this.bounds = GHash.getBounds(center, radius, approximation);
  }

  setApproximation(approximation) {
    this.approximation = approximation;
    //this.__approximateCenter(approximation);
    //this.bounds = GHash.getBounds(this.center, this.radius, this.approximation);
  }

  __approximateCenter(precision = 3, level = 3) {
    if (precision > 0) {
      let gci = new GStringNumber(this.center[0], precision);
      let gcj = new GStringNumber(this.center[1], precision);
      const previ = gci.copy().toPrecision(precision - 1);
      const prevj = gcj.copy().toPrecision(precision - 1);
      const nexi = gci
        .copy()
        .toPrecision(precision - 1)
        .next();
      const nexj = gcj
        .copy()
        .toPrecision(precision - 1)
        .next();
      const o = GHash.getPrecision(precision).decimals;
      if (gci.num - previ.num <= level * o) {
        gci = previ;
      } else if (nexi.num - gci.num <= level * o) {
        gci = nexi;
      }
      if (gcj.num - prevj.num <= level * o) {
        gcj = prevj;
      } else if (nexj.num - gcj.num <= level * o) {
        gcj = nexj;
      }
      this.center = [gci.value, gcj.value];
    }
  }

  /**
   * Method to get the first array of indexes for the initial bound
   */
  getBoundQueries() {
    return this.__getRowsOrCols(this.bounds);
  }

  /**
   * Method to get the first array of indexes for the initial bound
   */
  expandBounds(distance) {
    //const newBounds = GHash.expandBounds(this.bounds, distance);
    const newBounds = GHash.getBounds(
      this.center,
      this.radius + distance,
      this.approximation
    );

    const q1_1 = this.__getRowsOrCols(
      {
        precision: newBounds.p,
        min: { i: newBounds.min.i, j: newBounds.min.j },
        max: { i: this.bounds.min.i, j: newBounds.max.j },
      },
      {
        rows: [],
        cols: [],
      }
    );

    const q2_1 = this.__getRowsOrCols(
      {
        precision: newBounds.p,
        min: { i: this.bounds.max.i, j: newBounds.min.j },
        max: { i: newBounds.max.i, j: newBounds.max.j },
      },
      {
        rows: [],
        cols: [],
      }
    );

    const q3_1 = this.__getRowsOrCols(
      {
        precision: newBounds.p,
        min: { i: this.bounds.min.i, j: newBounds.min.j },
        max: { i: this.bounds.max.i, j: this.bounds.min.j },
      },
      {
        rows: [],
        cols: [],
      }
    );

    const q4_1 = this.__getRowsOrCols(
      {
        precision: newBounds.p,
        min: { i: this.bounds.min.i, j: this.bounds.max.j },
        max: { i: this.bounds.max.i, j: newBounds.max.j },
      },
      {
        rows: [],
        cols: [],
      }
    );

    const q1_2 = this.__getRowsOrCols(
      {
        precision: newBounds.p,
        min: { i: newBounds.min.i, j: newBounds.min.j },
        max: { i: newBounds.max.i, j: this.bounds.min.j },
      },
      {
        rows: [],
        cols: [],
      }
    );

    const q2_2 = this.__getRowsOrCols(
      {
        precision: newBounds.p,
        min: { i: newBounds.min.i, j: this.bounds.max.j },
        max: { i: newBounds.max.i, j: newBounds.max.j },
      },
      {
        rows: [],
        cols: [],
      }
    );

    const q3_2 = this.__getRowsOrCols(
      {
        precision: newBounds.p,
        min: { i: newBounds.min.i, j: this.bounds.min.j },
        max: { i: this.bounds.min.i, j: this.bounds.max.j },
      },
      {
        rows: [],
        cols: [],
      }
    );

    const q4_2 = this.__getRowsOrCols(
      {
        precision: newBounds.p,
        min: { i: this.bounds.max.i, j: this.bounds.min.j },
        max: { i: newBounds.max.i, j: this.bounds.max.j },
      },
      {
        rows: [],
        cols: [],
      }
    );

    let indexes = [];
    const l1 = q1_1.length + q2_1.length + q3_1.length + q4_1.length;
    const l2 = q1_2.length + q2_2.length + q3_2.length + q4_2.length;

    switch (Math.min(l1, l2)) {
      case l1:
        indexes = [...q1_1, ...q2_1, ...q3_1, ...q4_1];
        break;
      case l2:
        indexes = [...q1_2, ...q2_2, ...q3_2, ...q4_2];
        break;
      default: break;
    }

    this.bounds = newBounds;
    this.radius = this.radius + distance;
    return indexes;
  }

  __getRowsOrCols(bounds, excludes = { rows: [], cols: [] }) {
    const rows = { length: 0, indexes: [] };
    const cols = { length: 0, indexes: [] };
    let reversed = false;
    let indexes = {};

    [0, 1, 2, 3].forEach((p) => {
      let _rows = this.__calcQuerySteps(
        bounds.min.i,
        bounds.max.i,
        p,
        excludes.rows
      );
      let _cols = this.__calcQuerySteps(
        bounds.min.j,
        bounds.max.j,
        p,
        excludes.cols
      );

      rows.length += _rows.length;
      cols.length += _cols.length;

      rows.indexes.push({ p: p, data: _rows });
      cols.indexes.push({ p: p, data: _cols });
    });
    let data = rows.indexes;
    if (cols.length < rows.length) {
      data = cols.indexes;
      reversed = true;
    }
    data.forEach((idx) => {
      idx.data.forEach((value) => {
        const v = value.from;
        const v2 = value.to;

        const minj = bounds.min.j.copy();
        const maxj = bounds.max.j.copy();
        const mini = bounds.min.i.copy();
        const maxi = bounds.max.i.copy();

        let h1, h2;
        if (!reversed) {
          h1 = GHash.encode(
            [v.subNum(90).value, minj.subNum(180).value],
            idx.p
          );
          h2 = GHash.encode(
            [v.subNum(90).value, maxj.subNum(180).value],
            idx.p
          );

          let k = h1.hash + h2.hash + idx.p + reversed;
          indexes[k] = {
            min: h1.hash,
            max: h2.hash,
            precision: idx.p,
            to: v2.num,
            reversed: reversed,
          };
        } else {
          h1 = GHash.encode(
            [mini.subNum(90).value, v.subNum(180).value],
            idx.p
          );
          h2 = GHash.encode(
            [maxi.subNum(90).value, v.subNum(180).value],
            idx.p
          );
          let k = h1.hash_r + h2.hash_r + idx.p + reversed;
          indexes[k] = {
            min: h1.hash_r,
            max: h2.hash_r,
            precision: idx.p,
            to: v2.num,
            reversed: reversed,
          };
        }
      });
    });

    return new Array(...Object.values(indexes));
  }

  __calcQuerySteps(from, to, precision, excludes = []) {
    const res = {};

    let maxPrecision = Math.max(from.precision, to.precision);

    if (precision > maxPrecision) {
      return [];
    }
    const p = GHash.getPrecision(precision);
    const p2 = GHash.getPrecision(precision - 1);

    let start1 = from.copy().toPrecision(p.p);
    let end2 = to.copy().toPrecision(p.p);
    let end1 = from.copy().toPrecision(p2.p).next(1, p2.p);
    let start2 = to.copy().toPrecision(p2.p);

    if (precision === 0) {
      for (let i = start1.copy(); i.num < to.num; i = i.next(1, p.p)) {
        const gto = i.copy().next(1, p.p);
        if (i.num >= from.num && i.num < to.num && gto.num <= to.num) {
          if (!res[i.str] && excludes.indexOf(i.str) === -1) {
            res[i.str] = { from: i, to: gto };
          }
        }
      }
    } else {
      for (let i = start1.copy(); i.num < end1.num; i = i.next(1, p.p)) {
        const gto = i.copy().next(1, p.p);
        if (i.num >= from.num && i.num < to.num && gto.num <= to.num) {
          if (!res[i.str] && excludes.indexOf(i.str) === -1) {
            res[i.str] = { from: i, to: gto };
          }
        }
      }
      for (let i = start2.copy(); i.num < end2.num; i = i.next(1, p.p)) {
        const gto = i.copy().next(1, p.p);
        if (i.num >= from.num && i.num < to.num && gto.num <= to.num) {
          if (!res[i.str] && excludes.indexOf(i.str) === -1) {
            res[i.str] = { from: i, to: gto };
          }
        }
      }
    }

    return Object.values(res);
  }

  static hash(position) {
    const ret = {};

    [0, 1, 2, 3].forEach((p) => {
      const h = GHash.encode(position, p);
      ret["h" + p] = h.hash;
      ret["h" + p + "r"] = h.hash_r;
    });
    return ret;
  }
}
