//Actions used for registration and login process

export const setCurrentStep = (step) => ({
  type: "SET_STEP",
  step,
});

export const setPhone = (email) => ({
  type: "SET_EMAIL",
  email,
});

export const clearCache = () => ({
  type: "CLEAR_CACHE",
});

export const setCompanyCache = (data) => ({
  type: "SET_COMPANY_CACHE",
  data,
});

export const setOwnerCache = (data) => ({
  type: "SET_OWNER_CACHE",
  data,
});

export const setBankCache = (data) => ({
  type: "SET_BANK_CACHE",
  data,
});

export const setStripeCache = (data) => ({
  type: "SET_STRIPE_CACHE",
  data,
});
