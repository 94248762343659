import React from "react";
import styles from "./MayPSearchFilter.module.css";
import ExitIc from "../../../assets/icons/@MAYPAY_UIKIT_MERCHANT/maypay_icon_x_gray.svg" 
import { IconButton } from "@material-ui/core";

class MayPSearchFilter extends React.Component {
    constructor(props){
        super(props)
        this.props = props
    }

    render(){
        return(
            <>
                <div className={styles.container}>
                    <div className={styles.label}>{this.props.label}</div>
                    <IconButton onClick={() => {
                        if (this.props.onClose) {
                        this.props.onClose();
                        }
                    }}>
                        <img className={styles.deleteIcon} src={ExitIc} alt="delete filter"/>
                    </IconButton>
                </div>
            </>
        )
    }
}

export default MayPSearchFilter;
