import React from "react";
import MayPDatePicker from "./MayPDatePicker";
import MayPInput from "./MayPInput";
import RadioGroup from "@material-ui/core/RadioGroup";
import MayPRadio from "./MayPRadio";

class MayPFormBuider extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formIsValid: false,
      formData: {},
      items: {},
      toValidateOnMount: [],
    };
    console.log("formData", props.items)
    this.validateElement = this.validateElement.bind(this);
    this.isOlderThan18 = this.isOlderThan18.bind(this);

    for (let i = 0; i < props.items.length; i++) {
      const {
        type,
        defaultValue,
        validation,
        options,
        wrapperClass,
        wrapperStyle,
        name,
        nextFocus,
        isOptional,
        disabled,
        multiline,
        ...itemProps
      } = props.items[i];

      let isDefaultValid = false;

      if (
        type === "radiogroup" ||
        isOptional ||
        type === "custom" ||
        type === "hidden"
      ) {
        isDefaultValid = true;
      }

      this.state.items[name] = {
        name: name,
        type: type || "text",
        props: itemProps,
        value: defaultValue,
        validation: validation,
        isValid: isDefaultValid,
        lastError: null,
        options: options,
        wrapperClass: wrapperClass,
        wrapperStyle: wrapperStyle || {},
        ref: React.createRef(),
        nextFocus: nextFocus,
        isOptional: isOptional,
        multiline: multiline,
      };
      if (defaultValue && defaultValue !== "") {
        this.state.toValidateOnMount.push(name);
      }
    }
  }

  componentDidMount() {
    for (let i = 0; i < this.state.toValidateOnMount.length; i++) {
      const item = this.state.items[this.state.toValidateOnMount[i]];
      if (
        item.type === "text" ||
        item.type === "select" ||
        item.type === "number" ||
        item.type === "email" ||
        item.type === "password" ||
        item.type === "date" ||
        item.type === "tel"
      ) {
        this.validateElement(item.value, item.name, true);
      }
    }
  }
  static getDerivedStateFromProps(props, state) {
    let newState = state;
    for (let i = 0; i < props.items.length; i++) {
      const {
        type,
        defaultValue,
        validation,
        options,
        wrapperClass,
        wrapperStyle,
        name,
        nextFocus,
        isOptional,
        disabled,
        multiline,
        ...itemProps
      } = props.items[i];
      newState.items[name].props = itemProps;
      newState.items[name].validation = validation;
      newState.items[name].options = options;
      newState.items[name].wrapperClass = wrapperClass;
      newState.items[name].wrapperStyle = wrapperStyle || {};
      newState.items[name].nextFocus = nextFocus;
      newState.items[name].disabled = disabled;
      newState.items[name].multiline = multiline;
    }
    return newState;
  }

  isOlderThan18(birthday) {
    let today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    let dm = today.getMonth() - birthday.getMonth();
    if (dm < 0 || (dm === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
    return age >= 18;
  }

  validateElement(value, name, showError) {
    let items = this.state.items;
    items[name].value = value;
    items[name].lastError = null;
    let itemValid = true;
    if (this.state.items[name].validation) {
      for (let i = 0; i < items[name].validation.length; i++) {
        const re = items[name].validation[i].re;
        const cbk = items[name].validation[i].callback;
        const m = items[name].validation[i].message;
        if (re instanceof RegExp) {
          if (re.test(items[name].value)) {
            itemValid = true;
          } else {
            itemValid = false;
            if (showError && value) items[name].lastError = m;
            break;
          }
        }
        if (cbk instanceof Function) {
          const ret = cbk(items);
          itemValid = ret.isValid;
          if (ret.items) {
            for (let k of Object.keys(ret.items)) {
              if (items.hasOwnProperty(k)) {
                items[k].isValid = ret.items[k].isValid;
              }
            }
          }

          if (!itemValid) {
            if (showError && value) items[name].lastError = m;
          }
        }
      }
    }
    if (
      items[name].value &&
      items[name].value.trim &&
      items[name].value.trim() === ""
    ) {
      if (items[name].isOptional) {
        items[name].isValid = true;
      } else {
        items[name].isValid = false;
      }
    } else {
      // FIXME valid even if required 
      items[name].isValid = itemValid;
    }

    if (items[name].type === "date") {
      console.log("checking date:", items[name].value);
      const d = new Date(items[name].value);
      if (
        items[name].value &&
        d instanceof Date &&
        !isNaN(d) &&
        this.isOlderThan18(d)
      ) {
        items[name].isValid = true;
      } else {
        items[name].isValid = false;
      }
    }
    if (items[name].type === "select") {
      console.log("checking date:", items[name].value);
      if (
        items[name].value !== undefined &&
        items[name].value !== null &&
        items[name].value !== ""
      ) {
        items[name].isValid = true;
      } else {
        items[name].isValid = false;
      }
    }
    let fValid = true;
    let formData = {};
    for (let k of Object.keys(items)) {
      if (items[k].type !== "custom") {
        formData[k] = items[k].value;
      }
      if (!items[k].isValid) {
        fValid = false;
      }
    }
    this.setState(
      { formIsValid: fValid, items: items, formData: formData },
      () => {
        if (this.props.onValid instanceof Function) {
          this.props.onValid(this.state.formIsValid, this.state.formData);
        }
      }
    );
  }

  onKeyDown(e, item) {
    try {
      if (!item.multiline) {
        if (e.key === "Enter" && item.nextFocus) {
          e.preventDefault();
          const ref = this.state.items[item.nextFocus].ref;
          ref.current.focus();
        }
      } else {
        if (e.key === "Enter") {
          item.ref.current.focus();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className={this.props.containerClass || "MayPFormBuilder-container"}>
        {Object.keys(this.state.items).map((name, i) => {
          const item = this.state.items[name];
          //console.log(item);
          let el;
          let label;
          label = item.props.label;
          if (label && !item.isOptional) {
            label += "*";
          }
          item.props.label = label;
          if (
            item.type === "text" ||
            item.type === "number" ||
            item.type === "email" ||
            item.type === "password" ||
            item.type === "tel"
          ) {
            el = (
              <MayPInput
                size="medium"
                {...item.props}
                inputRef={item.ref}
                id={this.props.id + "_" + name}
                type={item.type}
                onChange={(e) =>
                  this.validateElement(e.target.value, name, true)
                }
                error={
                  !item.isValid && item.value !== undefined && item.value !== ""
                }
                helperText={item.isValid ? "" : item.lastError}
                onKeyDown={(e) => {
                  this.onKeyDown(e, item);
                }}
                defaultValue={item.value}
                multiline={item.multiline}
                disabled={item.disabled || this.props.disableAll}
              />
            );
          } else if (item.type === "radiogroup") {
            el = (
              <RadioGroup
                {...item.props}
                id={this.props.id + "_" + i}
                onChange={(e) => {
                  console.log("Selecting...", e.target.value);
                  let items = this.state.items;
                  items[item.name].value = e.target.value;
                  this.setState({ items: items }, () => {
                    this.validateElement(
                      items[item.name].value,
                      item.name,
                      true
                    );
                  });
                }}
                value={item.value}
              >
                {item.options.map((opt) => (
                  <MayPRadio
                    {...opt}
                    key={"key_" + opt.value}
                    disabled={item.disabled || this.props.disableAll}
                  />
                ))}
              </RadioGroup>
            );
          } else if (item.type === "date") {
            const { error, ...otherProps } = item.props;
            el = (
              <MayPDatePicker
                {...otherProps}
                id="data"
                onChange={(date) => {
                  console.log(date, typeof date);

                  let items = this.state.items;
                  items[item.name].value = date;
                  items[item.name].isValid = true;

                  this.setState({ items: items }, () => {
                    this.validateElement(date, item.name, true);
                  });
                }}
                value={item.value}
                error={!item.isValid && item.value}
                helperText={item.isValid ? "" : item.lastError}
                disabled={item.disabled || this.props.disableAll}
              />
            );
          } else if (item.type === "hidden") {
            el = <input {...item.props} type="hidden" value={item.value} />;
          } else if (item.type === "custom") {
            el = <React.Fragment>{item.props.customEl}</React.Fragment>;
          } else if (item.type === "select") {
            el = (
              <MayPInput
                select
                size="medium"
                {...item.props}
                inputRef={item.ref}
                id={this.props.id + "_" + name}
                //type={item.type}
                onChange={(e) =>
                  this.validateElement(e.target.value, name, true)
                }
                error={
                  !item.isValid && item.value !== undefined && item.value !== ""
                }
                helperText={item.isValid ? "" : item.lastError}
                onKeyDown={(e) => {
                  this.onKeyDown(e, item);
                }}
                defaultValue={item.value}
                multiline={item.multiline}
                disabled={item.disabled || this.props.disableAll}
                SelectProps={{
                  native: true,
                }}
                InputProps={{
                  style: {
                    minHeight: "34px",
                    height: "34px",
                  },
                }}
                style={{
                  width: "100%",
                }}
              >
                {item.props.values.map((option, i) => (
                  <option
                    className=""
                    key={"key" + this.props.id + "_" + name + "_" + i}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </MayPInput>
            );
          }

          return (
            <div
              key={this.props.id + "_" + i}
              className={item.wrapperClass || ""}
              style={item.wrapperStyle}
            >
              {el}
            </div>
          );
        })}
      </div>
    );
  }
}

export default MayPFormBuider;
