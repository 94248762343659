import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

const _style = {
  root: {
    "& .MuiFormControlLabel-label": {
      fontFamily: '"MavenPro Regular", Arial, sans-serif',
      fontWeight: "normal",
      fontSize: "13px",
      letterSpacing: "0px",
      lineHeight: "21px",
    },
    "& .MuiButtonBase-root": {
      color: "#00a2e0",
    },
  },
  label: {
    fontFamily: '"MavenPro Regular", Arial, sans-serif',
    fontWeight: "normal",
    fontSize: "13px",
    letterSpacing: "0px",
    lineHeight: "21px",
  },
};

class MayPRadio extends React.Component {
  constructor(props) {
    super(props);
    const { classes, other } = props;
    this.classes = classes;
    this.props = other;
  }

  render() {
    const { radioProps, ...props } = this.props;
    return <FormControlLabel {...props} control={<Radio {...radioProps} />} />;
  }
}

export default withStyles(_style)(MayPRadio);
