import { useEffect } from "react";
import { useState } from "react";
const { stringify } = require('csv-stringify/lib/sync');
const JSZip = require('jszip');

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const getAllSubstrings = (str, result) => {
    var i, j;
    str = str.trim();
    str = str.replace(/\s{2,}/, " ");
    if (str.length <= 3) {
      result.add(str);
      return result;
    }
    for (i = 0; i < str.length - 2; i += 1) {
      for (j = i + 3; j < str.length + 3; j += 1) {
        const w = str.slice(i, j).trim();
        if (w.length >= 3) {
          result.add(w);
        }
      }
    }
    return result;
  };

  const extractQueryWords = (str, result) => {
    //for(let word of str.split(" ")){
    result = getAllSubstrings(str, result);
    //}
    return result;
  };

  return {
    extractQueryWords: extractQueryWords,
  };
}

export const currencyFormatter = new Intl.NumberFormat("it", {
  style: "decimal",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const APIKEY_STATUS = {
  ACTIVE: "ACTIVE",
  REVOKED: "REVOKED",
};

export const APIKEY_ENVIRONMENT = {
  SANDBOX: "sandbox",
  PRODUCTION: "production",
};

export const FirestoreCollection = {
  Merchants: "merchants",
  Buyers: "users",
  Stores: "store",
  Transactions: "transactions",
  Contests: "contests",
  FailedTransfers: "failedtransfers",
  Operations: "operations",
  Stats: "stats",
  AWSErrors: "aws_errors",
  Communications: "communications",
  Settings: "settings",
  APIKeys: "apiKeys",
};

export const Routes = {
  Home: "/main/home",
  Probability: "/main/probabilità",
  Transactions: "/main/Cronologia Transazioni",
  Credits: "/main/Accrediti",
  Preferences: "/main/Preferenze di Accredito",
  Account: "/main/Account",
  Developers: "/main/Sviluppatori",
  Stores: "/main/Gestisci Negozi",
  Store: "/main/Gestisci Negozi/",
  AddStore: "/main/Gestisci Negozi/aggiungiNegozio",
  Documents: "/main/Documenti Contabili"
};

/**
 * Labels of transactions Status
 */
export const TransactionStatus = [
  "In corso",
  "Annullata dal cliente",
  "Rifiutata",
  "Scaduta",
  "Pagata",
  "Vinta",
  "In attesa di autorizzazione dal cliente",
  "Errore",
  "Pre-autorizzazione",
  "In attesa di rimborso",
  "Rimborsata",
  "Rimborso fallito"
];

export const StoreType = {
  ONLINE: "online",
  PHYSICAL: "physical",
};

export const DocumentType = {
  INVOICE: "Fattura",
  ACCOUNT_BALANCE: "EstrattoConto",
  REPORT: "ResocontoAccrediti",
  DAILY_REPORT: "ResocontoGiornaliero"
}

export const FileType = {
  PDF: "pdf",
  CSV: "csv"
}

export function useChildrens({ types, defaultName, childs }) {
  const initValue = {};
  types.forEach((el) => {
    initValue[el.toLowerCase()] = undefined;
  });
  initValue[defaultName] = undefined;
  const [children, setChildren] = useState(initValue);
  useEffect(() => {
    if (childs && childs.length) {
      childs.forEach((el) => {
        if (children.hasOwnProperty(el.type.toLowerCase())) {
          children[el.type.toLowerCase()] = el.props.children;
        }
      });
    } else {
      children[defaultName] = childs;
    }
    setChildren((c) => {
      return { ...children };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childs, defaultName]);
  return children;
}

/**
 * Create a CSV of data
 * @param {Object} data
 * @returns
 */
export const exportCsvHelper = async (data) => {
  // console.log("CSV", stringify);
  const res = stringify(data,
    {
      delimiter: ";",
      header: true,
    },
  )
  return res;
}

export const zipFromUrls = async (urls, fileNames, contentTypes) => {
  // Creare un oggetto JSZip
  var zip = new JSZip();

  // Funzione per scaricare un singolo file e aggiungerlo allo zip
  function downloadAndAddToZip(url, fileName, contentType) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            let type = 'text';
            switch (contentType) {
              case FileType.PDF:
                type = 'application/pdf';
                break;
              case FileType.CSV:
                type = 'text/csv';
                break;
              default:
                break;
            }

            var blob = new Blob([xhr.response], { type: type });
            zip.file(fileName, blob);
            resolve(); // Risolve la promise quando il download è completato
          } else {
            reject(new Error(`Impossibile scaricare il file ${fileName}`));
          }
        }
      };

      xhr.responseType = 'arraybuffer';
      xhr.open("GET", url);
      xhr.send();
    });
  }

  // Array di promises per tracciare lo stato di ogni download
  var downloadPromises = [];

  // Itera attraverso gli URL e avvia il download di ciascun file
  for (var i = 0; i < urls.length; i++) {
    var fileName = fileNames[i];
    var contentType = contentTypes[i];
    downloadPromises.push(downloadAndAddToZip(urls[i], fileName, contentType));
  }

  try {
    // Attendi che tutte le promises si completino prima di procedere
    await Promise.all(downloadPromises);

    // Crea lo zip e permetti il download
    var content = await zip.generateAsync({ type: "blob" });
    var url = window.URL.createObjectURL(content);

    var a = document.createElement("a");
    a.href = url;
    a.download = "reports.zip";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw error;
  }
};
