import React, { useState, useCallback, useEffect, useRef } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import MayPMiniTable from "./MayPMiniTable";
import MayPDesktopTable from "./MayPDesktopTable";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import styles from "./MayPTable.module.css";

let totalPages = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  caption: {
    display: "none",
  },
  selectRoot: {
    display: "none",
  },
  toolbar: {
    display: "block",
    margin: 0,
    padding: 0,
  },
}));

export default function MayPTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage || props.fixedLimit || 10
  );
  const [endOfData, setEndOfData] = useState(false);
  const [data, setData] = useState({
    global: [],
    view: [],
  });
  const classes = useStyles();

  let limit = props.rowsPerPage || 10;
  const headerRef = useRef();
  const mobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    if (data.global.length > 0 && totalPages > 0) {
      computePagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const requestData = (reset = false) => {
    console.log("Request data", limit, reset);
    if (props.updateData) {
      props.updateData(page, limit).then((newData) => {
        if (!newData) {
          if (!reset) {
            console.log("No more data, setting previous page", page, newData);
            setEndOfData(true);
            setPage(page - 1);
          }
        } else {
          setData((prev) => {
            let _g;
            let end;
            let start;

            if (reset) {
              _g = newData;
              start = 0;
              end = newData.length;
            } else {
              _g = [...prev.global, ...newData];

              const length = _g.length;
              if (length % limit === 0) {
                start = length - limit;
              } else {
                start = length - (length % limit);
              }
              end = length;
            }

            totalPages = totalPages + 1;
            console.log("New data", {
              _g,
              view: _g.slice(start, end),
              totalPages,
              page,
              limit,
              start,
              end,
            });
            return {
              global: _g,
              view: _g.slice(start, end),
            };
          });
        }
      });
    }
  };

  const computePagination = () => {
    const _g = [...data.global];
    let start, end;

    if (page >= totalPages) {
      // caricamento dei dati
      data.view.forEach((d, i) => {
        if (props.onSelected) {
          props.onSelected(i, false)
        }
      })

      requestData();

    } else {
      const length = _g.length;
      const offset = totalPages - page;
      const limit = props.rowsPerPage;

      if (length % limit === 0) {
        start = length - limit * offset;
        end = length - limit * (offset - 1);
      } else {
        start = length - limit * (offset - 1) - (length % limit);
        end = length - limit * (offset - 2) - (length % limit);
      }
      console.log("Data already present", {
        totalPages,
        page,
        rowsPerPage,
        start,
        end,
        offset,
        length,
        _g,
        view: _g.slice(start, end),
      });
      setData({
        global: _g,
        view: _g.slice(start, end),
      });
    }
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
    setEndOfData(false);
  };

  const onChangeRowsPerPage = (event) => {
    const rpp = parseInt(event.target.value, 10);
    console.log("New rpp", rpp);
    setRowsPerPage(rpp);
    setPage(0);
    limit = rpp;
    totalPages = 0;
    requestData(true);
  };

  const onRowClick = useCallback(
    (event) => {
      const selection = window.getSelection();
      if (selection && selection.toString()) {
        return;
      }
      const row = event.target.id.split("-")[0];
      const column = event.target.id.split("-")[1];
      const item = data.view[row];

      // console.log(item, row);
      if (props.onRowClick && row && column) {
        props.onRowClick(item, column, row);
      }
    },
    [data, props]
  );

  useEffect(() => {
    if (data.view.length > 0) {
      headerRef.current.scrollIntoView();
    }

    const viewedData = page * rowsPerPage + data.view.length;
    if (props.tableData.totalRows > -1) {
      setEndOfData(viewedData === props.tableData.totalRows);
    }

    if (props.onPageChange) {
      props.onPageChange(data.view);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.view]);

  useEffect(() => {
    if (props.tableData.rows.length === 0 && data.global.length > 0) {
      console.log("no data, resetting", data.global.length);
      setPage(0);
      setData({
        global: [],
        view: [],
      });
      totalPages = 0;
    } else {
      if (!props.pagination) {
        setData({
          global: props.tableData.rows,
          view: props.tableData.rows,
        });
      } else if (data.global.length === 0) {
        console.log("Setting first data", props.tableData);
        totalPages = 1;
        setData({
          global: props.tableData.rows,
          view: props.tableData.rows,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tableData]);

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) => {
      if (mobile) {
        return `${from}–${to}`;
      }

      if (data.view.length >= rowsPerPage) {
        return `${from}–${to} di ${count !== -1 ? count : `più di ${to}`}`;
      }
      const end = from + data.view.length - 1;
      return `${from}–${end} ${count !== -1 ? `di ${count}` : ""}`;
    },
    [rowsPerPage, data, mobile]
  );

  return (
    <div className={styles.tableBox}>
      <div
        ref={headerRef}
        style={{ visibility: "hidden", position: "relative", top: "-128px" }}
      />
      {!mobile && (
        <MayPDesktopTable
          {...props}
          onClick={onRowClick}
          data={data.view}
          rowsPerPage={rowsPerPage}
        />
      )}

      {mobile && (
        <MayPMiniTable
          {...props}
          onClick={onRowClick}
          data={data.view}
          rowsPerPage={rowsPerPage}
        />
      )}

      {data.view.length > 0 && props.pagination && (
        <TablePagination

          SelectProps={{
            style: {
              fontSize: "12px",
              marginRight: "2%",
            },
          }}
          classes={{
            root: mobile ? classes.root : "",
            caption: mobile ? classes.caption : "",
            selectRoot: mobile ? classes.selectRoot : "",
            toolbar: mobile ? classes.toolbar : "",
          }}
          rowsPerPageOptions={props.fixedLimit > 0 ? [] : [10, 25, 50, 100]}
          component="div"
          labelRowsPerPage={props.labelRowsPerPage}
          count={props.tableData.totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelDisplayedRows={labelDisplayedRows}
          backIconButtonProps={{
            disabled: props.loading || page === 0,
          }}
          nextIconButtonProps={{
            disabled:
              props.loading || data.view.length < rowsPerPage || endOfData,
          }}
        />
      )}
    </div>
  );
}
