import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./MayPWithSpinner.module.css";

const MayPWithSpinner = (props) => {
  const { waiting, children } = props;

  const cls = waiting ? styles.hide : "";
  return (
    <div className={styles.spinnerContainer}>
      <div className={`${styles.spinner} ${cls}`}>
        <CircularProgress className={styles.spin} />
      </div>
      <div className={`${styles.spinnerContent} ${cls}`}>{children}</div>
    </div>
  );
};

MayPWithSpinner.propTypes = {
  waiting: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default MayPWithSpinner;
