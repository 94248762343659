import React from "react";
import MayPButtonBlue, { wrap } from "../../common/components/utils/MayPButton";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import MayPFormBuider from "../../common/components/utils/MayPFormBuilder";
import MayPDialog from "../../common/components/utils/MayPDialog";
import CustomIcon from "../../common/components/utils/CustomIcon";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_edit.svg";
import StoreLib from "../../libs/store";
import categories from "../../common/categories";
import ProfilePicCropper from "./ProfilePicCropper";
import StorePicCropper from "./StorePicCropper";
import { StoreType } from "../../common/libs/utils";

const ButtonStorePic = wrap({
  root: {
    fontSize: "16px",
    letterSpacing: "1.6px",
    fontWeight: "normal",
    lineHeight: "24px",
    backgroundColor: "#ffffff",
    backgroundImage: "none",
    color: "#00E091",
    paddingTop: "4px",
    paddingBottom: "4px",
    fontFamily: '"Giorgio", Arial, sans-serif',
    boxShadow: "none",
    WebkitTextStroke: "0.7px #00E091",
  },
});

class ProfileStoreEditor extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.onContinue = this.onContinue.bind(this);
    this.storeLib = StoreLib();

    this.state = {
      formValid: false,
      waiting: false,
      error: 0,
      errorMessage: "",
      formData: {},
      profilePic: this.props.editing ? this.props.store.profilePhotoUrl : "",
      storePic: this.props.editing ? this.props.store.storePhotoUrl : "",
      formItems: [],
      formType: "",
      openDialog: false,
      dialogActions: [],
      onDialogClose: null,
      dialogMessage: "",
      dialogTitle: "",
    };

    this.fillFormItems = this.fillFormItems.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.fillFormItems(this.props.store || {});
  }

  fillFormItems(data) {
    const disabled =
      Object.keys(data).filter((d) => d !== "store_type").length > 0;

    let formItems = [];
    if (!this.props.editing) {
      if (!data.store_type) {
        data = {
          store_type: StoreType.PHYSICAL,
        };
      }

      formItems.push({
        type: "select",
        label: "Tipologia negozio",
        name: "store_type",
        defaultValue: data.store_type,
        wrapperClass: "form-data w100",
        nextFocus: "store_name",
        values: [
          {
            label: "Punto vendita",
            value: StoreType.PHYSICAL,
          },
          {
            label: "eCommerce",
            value: StoreType.ONLINE,
          },
        ],
      });
    }

    if (data.store_type === StoreType.PHYSICAL) {
      formItems = [
        ...formItems,
        {
          type: "text",
          label: "Nome negozio",
          name: "store_name",
          defaultValue: data.store_name || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_address",
          disabled,
        },
        {
          type: "text",
          label: "Indirizzo",
          name: "store_address",
          defaultValue: data.store_address || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_address_number",
          disabled,
        },
        {
          type: "text",
          label: "Civico",
          name: "store_address_number",
          defaultValue: data.store_address_number || "",
          wrapperClass: "form-data w50",
          nextFocus: "store_cap",
          disabled,
        },
        {
          type: "number",
          label: "CAP",
          name: "store_cap",
          defaultValue: data.store_cap || "",
          wrapperClass: "form-data w50",
          nextFocus: "store_city",
          disabled,
        },
        {
          type: "text",
          label: "Città",
          name: "store_city",
          defaultValue: data.store_city || "",
          wrapperClass: "form-data w50",
          nextFocus: "store_state",
          disabled,
        },
        {
          type: "text",
          label: "Stato",
          name: "store_state",
          defaultValue: data.store_state || "IT",
          wrapperClass: "form-data w50",
          validation: [{ re: /^[A-Za-z]{2,3}$/, message: "Valore non valido" }],
          nextFocus: "store_category",
          disabled,
        },
        // {
        //   type: "text",
        //   label: "Probabilità (1/N)",
        //   name: "probability",
        //   defaultValue: data.store_probability || "100",
        //   wrapperClass: "form-data w50",
        //   nextFocus: "store_category",
        //   disabled: true,
        // },
        {
          type: "select",
          label: "Categoria",
          name: "store_category",
          defaultValue: data.store_category || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_description",
          values: categories,
        },
        {
          type: "text",
          label: "Descrizione",
          name: "store_description",
          defaultValue: data.store_description || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_opening_time",
          multiline: true,
          minRows: 4,
          variant: "outlined",
        },
        {
          type: "custom",
          name: "title2",
          customEl: "Informazioni utili",
          wrapperClass: "profile-title",
        },
        {
          type: "text",
          label: "Orari di apertura",
          name: "store_opening_time",
          defaultValue: data.store_opening_time || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_other",
          multiline: true,
          minRows: 4,
          variant: "outlined",
        },
        {
          type: "text",
          label: "Altro",
          name: "store_other",
          defaultValue: data.store_other || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_phone",
          multiline: true,
          minRows: 2,
          variant: "outlined",
          isOptional: true,
        },
        {
          type: "custom",
          name: "title3",
          customEl: "Contatti",
          wrapperClass: "profile-title",
        },
        {
          type: "tel",
          label: "Telefono",
          name: "store_phone",
          defaultValue: data.store_phone || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_web_site",
          isOptional: true,
        },
        {
          type: "text",
          label: "Sito internet",
          name: "store_web_site",
          defaultValue: data.store_web_site || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_email",
          isOptional: true,
        },
        {
          type: "text",
          label: "Indirizzo e-mail",
          name: "store_email",
          defaultValue: data.store_email || "",
          wrapperClass: "form-data w100",
          validation: [
            {
              re: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
              message: "E-mail non valida",
            },
          ],
          nextFocus: "store_fb_account",
          isOptional: true,
        },
        {
          type: "text",
          label: "Account Facebook",
          name: "store_fb_account",
          defaultValue: data.store_fb_account || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_instagram_account",
          isOptional: true,
        },
        {
          type: "text",
          label: "Account Instagram",
          name: "store_instagram_account",
          defaultValue: data.store_instagram_account || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_linkedin_account",
          isOptional: true,
        },
        {
          type: "text",
          label: "Account Linkedin",
          name: "store_linkedin_account",
          defaultValue: data.store_linkedin_account || "",
          wrapperClass: "form-data w100",
          isOptional: true,
        },
        {
          type: "hidden",
          name: "profilePic",
          defaultValue: data.profilePic || "",
        },
        {
          type: "hidden",
          name: "storePic",
          defaultValue: data.storePic || "",
        },
        {
          type: "hidden",
          name: "store_probability",
          defaultValue: data.storeProbability || 100,
        },
        {
          type: "hidden",
          name: "enabled",
          defaultValue: data.enabled || false,
        },
      ];
    } else {
      formItems = [
        ...formItems,
        {
          type: "text",
          label: "Nome negozio",
          name: "store_name",
          defaultValue: data.store_name || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_web_site",
          disabled,
        },
        {
          type: "text",
          label: "Sito internet",
          name: "store_web_site",
          defaultValue: data.store_web_site || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_email",
          isOptional: false,
        },
        {
          type: "select",
          label: "Categoria",
          name: "store_category",
          defaultValue: data.store_category || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_description",
          values: categories,
        },
        {
          type: "text",
          label: "Indirizzo e-mail",
          name: "store_email",
          defaultValue: data.store_email || "",
          wrapperClass: "form-data w100",
          validation: [
            {
              re: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
              message: "E-mail non valida",
            },
          ],
          nextFocus: "store_category",
          isOptional: true,
        },
        {
          type: "text",
          label: "Descrizione",
          name: "store_description",
          defaultValue: data.store_description || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_fb_account",
          multiline: true,
          minRows: 4,
          variant: "outlined",
        },
        {
          type: "custom",
          name: "title2",
          customEl: "Social Media",
          wrapperClass: "profile-title",
        },

        {
          type: "text",
          label: "Account Facebook",
          name: "store_fb_account",
          defaultValue: data.store_fb_account || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_instagram_account",
          isOptional: true,
        },
        {
          type: "text",
          label: "Account Instagram",
          name: "store_instagram_account",
          defaultValue: data.store_instagram_account || "",
          wrapperClass: "form-data w100",
          nextFocus: "store_linkedin_account",
          isOptional: true,
        },
        {
          type: "text",
          label: "Account Linkedin",
          name: "store_linkedin_account",
          defaultValue: data.store_linkedin_account || "",
          wrapperClass: "form-data w100",
          isOptional: true,
        },
        {
          type: "hidden",
          name: "profilePic",
          defaultValue: data.profilePic || "",
        },
        {
          type: "hidden",
          name: "storePic",
          defaultValue: data.storePic || "",
        },
        {
          type: "hidden",
          name: "store_probability",
          defaultValue: data.storeProbability || 100,
        },
        {
          type: "hidden",
          name: "enabled",
          defaultValue: data.enabled || false,
        },
      ];
    }

    this.setState({
      formType: data.store_type,
      formItems: [...formItems],
    });
  }

  componentDidUpdate(props, state) {
    if (state.formData.store_type !== this.state.formData.store_type) {
      if (!this.props.editing) {
        this.fillFormItems({ store_type: this.state.formData.store_type });
      }
    }
  }

  async onContinue() {
    if (!this.state.profilePic) {
      this.setState({
        openDialog: true,
        dialogActions: ["Ok"],
        dialogTitle: "Informazioni mancanti",
        dialogMessage: "Scegli un'immagine di profilo.",
        onDialogClose: async (action) => {
          if (action === "Ok") {
            this.setState(
              {
                openDialog: false,
              },
              () => {
                try {
                  window.scroll(0, 0);
                } catch (e) {
                  //..nothing to do
                }
              }
            );
          }
        },
      });
    } else if (!this.state.formValid) {
      /*else if(!this.state.storePic){

    }*/
      this.setState({
        openDialog: true,
        dialogActions: ["Ok"],
        dialogTitle: "Informazioni mancanti",
        dialogMessage: "Compila tutti i campi obbligatori.",
        onDialogClose: async (action) => {
          if (action === "Ok") {
            this.setState(
              {
                openDialog: false,
              },
              () => {
                try {
                  //this.contentRef.current.parentElement.parentElement.scroll(0,0);
                } catch (e) {
                  //..nothing to do
                }
              }
            );
          }
        },
      });
    } else {
      this.setState({
        waiting: true,
      });
      try {
        if (this.props.editing) {
          const data = {
            storeId: this.props.store.storeId,
            store_type: this.state.formType,
            ...this.state.formData,
          };
          const ret = await Promise.race([
            this.storeLib.saveStoreFirebase(
              data,
              this.state.profilePic,
              this.state.storePic
            ),
            new Promise((resolve, reject) => {
              setTimeout(() => {
                reject({ message: "Richiesta scaduta." });
              }, 60000);
            }),
          ]);
          if (ret.success) {
            window.scroll(0, 0);
            this.setState({
              editing: false,
              openDialog: true,
              dialogActions: ["Ok"],
              dialogTitle: "Negozio Aggiornato",
              dialogMessage: "",
              onDialogClose: async (action) => {
                if (action === "Ok") {
                  this.setState({
                    openDialog: false,
                  });

                  if (this.props.onSave) {
                    this.props.onSave();
                  }
                }
              },
            });
          } else {
            console.log(ret.error);
            if (
              ret.error &&
              ret.error.code &&
              ret.error.code === "auth/network-request-failed"
            ) {
              throw new Error(
                "Errore di connessione. Assicurati di essere connesso ad internet."
              );
            } else {
              throw new Error("Non è stato possibile completare l'operazione");
            }
          }
        } else {
          const data = {
            ...this.state.formData,
          };
          const ret = await Promise.race([
            this.storeLib.addStoreFirebase(
              data,
              this.state.profilePic,
              this.state.storePic
            ),
            new Promise((resolve, reject) => {
              setTimeout(() => {
                reject({ message: "Richiesta scaduta." });
              }, 60000);
            }),
          ]);
          if (ret.success) {
            this.setState({
              editing: false,
              openDialog: true,
              dialogActions: ["Ok"],
              dialogTitle: "Nuovo negozio aggiunto",
              dialogMessage: "",
              onDialogClose: async (action) => {
                if (action === "Ok") {
                  this.setState(
                    {
                      openDialog: false,
                    },
                    () => {
                      if (this.props.onAdding) {
                        this.props.onAdding({
                          newStoreId: ret.data,
                        });
                      }
                    }
                  );
                }
              },
            });
          } else {
            if (
              ret.error &&
              ret.error.code &&
              ret.error.code === "auth/network-request-failed"
            ) {
              throw new Error(
                "Errore di connessione. Assicurati di essere connesso ad internet."
              );
            } else {
              throw new Error("Non è stato possibile completare l'operazione");
            }
          }
        }
      } catch (e) {
        console.log(e);
        this.setState({
          editing: false,
          openDialog: true,
          dialogActions: ["Ok"],
          dialogTitle: "Si è verificato un errore",
          dialogMessage: e.message,
          onDialogClose: async (action) => {
            if (action === "Ok") {
              this.setState(
                {
                  openDialog: false,
                },
                () => {
                  if (this.props.onSubmit) {
                    this.props.onSubmit("on-back", null);
                  }
                }
              );
            }
          },
        });
      }
      this.setState({
        waiting: false,
      });
    }
  }

  render() {
    return (
      <>
        <div className="store-profile-header">
          <div className="store-pic-container">
            {this.state.storePic && <img src={this.state.storePic} alt=" " />}
            <span className="profile-store-button">
              <ButtonStorePic
                disabled={this.state.waiting}
                onClick={() => {
                  this.setState({
                    openDialog: true,
                    dialogTitle: "Ritaglia immagine",
                    onDialogClose: () => {
                      this.setState({
                        openDialog: false,
                      });
                    },
                    dialogMessage: (
                      <div style={{minWidth: "600px", minHeight: "400px"}}>
                        <StorePicCropper
                          onSubmit={(action, data) => {
                            if (action === "submit-store-pic") {
                              this.setState({
                                openDialog: false,
                                storePic: data,
                              });
                            } else if (action === "close") {
                              this.setState({
                                openDialog: false,
                              });
                            }
                          }}
                        />
                      </div>
                    ),
                  });
                }}
              >
                Cambia immagine
              </ButtonStorePic>
            </span>
          </div>
          <div className="avatar-container">
            <Badge
              onClick={() => {
                if (!this.state.waiting) {
                  this.setState({
                    openDialog: true,
                    dialogTitle: "Ritaglia immagine",
                    onDialogClose: () => {
                      this.setState({
                        openDialog: false,
                      });
                    },
                    dialogMessage: (
                      <div style={{minWidth: "600px", minHeight: "400px"}}>
                        <ProfilePicCropper
                          onSubmit={(action, data) => {
                            if (action === "submit-profile-pic") {
                              this.setState({
                                openDialog: false,
                                profilePic: data,
                              });
                            } else if (action === "close") {
                              this.setState({
                                openDialog: false,
                              });
                            }
                          }}
                        />
                      </div>
                    ),
                  });
                }
              }}
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={
                <span className="avatar-edit-icon">
                  <CustomIcon icon={EditIcon} />
                </span>
              }
            >
              <Avatar
                style={{
                  width: "128px",
                  height: "128px",
                  boxShadow: "0px 5px  40px #00000029",
                  backgroundColor: "#00A2E0",
                  fontSize: "48px",
                }}
                alt=""
                src={this.state.profilePic}
              >
                {" "}
                {this.state.formData.store_name
                  ? this.state.formData.store_name[0].toUpperCase()
                  : ""}{" "}
              </Avatar>
            </Badge>
          </div>
        </div>

        {this.state.formItems.length > 0 && (
          <>
            <div className="profile-init-message">
              Inserisci i dati della tua attività per presentare il tuo profilo
              business.
            </div>
            {this.state.formType === StoreType.PHYSICAL && (
              <MayPFormBuider
                items={this.state.formItems}
                id="userProfile"
                onValid={(v, data) => {
                  this.setState({
                    formValid: v,
                    formData: data,
                  });
                }}
                containerClass="form-data-container"
                disableAll={this.state.waiting}
              />
            )}

            {this.state.formType === "online" && (
              <MayPFormBuider
                items={this.state.formItems}
                id="userProfile"
                onValid={(v, data) => {
                  this.setState({
                    formValid: v,
                    formData: data,
                  });
                }}
                containerClass="form-data-container"
                disableAll={this.state.waiting}
              />
            )}
          </>
        )}
        <div
          style={{
            marginTop: "80px",
            textAlign: "center",
            width: "100%",
            marginBottom: "80px",
          }}
        >
          <MayPWithSpinner waiting={this.state.waiting}>
            <MayPButtonBlue
              disabled={this.props.waiting}
              onClick={this.onContinue}
            >
              {`${this.props.editing ? "Salva dati" : "Pubblica Negozio"}`}
            </MayPButtonBlue>

            <div style={{ marginTop: "30px" }}></div>
          </MayPWithSpinner>
        </div>
        <MayPDialog
          forMerchant={true}
          open={this.state.openDialog}
          title={this.state.dialogTitle}
          message={this.state.dialogMessage}
          actions={this.state.dialogActions}
          onClose={this.state.onDialogClose}
        />
      </>
    );
  }
}

export default ProfileStoreEditor;
