import React from "react";
import styles from "./MayPProbabilityBadge.module.css";
import LogoProbability from "../assets/@MAYPAY_UIKIT_BUYER/maypay_logo_probability.svg";

class MayPProbabilityBadge extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.probability} style={this.props.style || {}}>
          <div className={styles.probabilityLogo}>
            <img src={LogoProbability} alt="logo" />
          </div>
          <div className={styles.probabilityText}>
            1 su {this.props.probability}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MayPProbabilityBadge;
