import React from "react";
import { Container } from "@material-ui/core";
import MayPLinearProgress from "../common/components/utils/MayPLinearProgress";
import MayPDialog from "../common/components/utils/MayPDialog";
import Accedi from "./login/Accedi";
import Registrazione1 from "./login/Registrazione1";
import Termini from "./login/Termini";
import VerificaEmail from "./login/VerificaEmail";
import Reimposta1 from "./login/Reimposta1";
import DatiAziendali from "./login/DatiAziendali";
import DatiTitolare from "./login/DatiTitolare";
import ExternalAccount from "./login/ExternalAccount";
import RegistrationEnd from "./login/RegistrationEnd";
import "./LoginPage.css";

import { getAuth, signOut, updateProfile } from "firebase/auth";
import { getDoc, doc, collection, getFirestore } from "firebase/firestore";

import { connect } from "react-redux";
import { setAuth } from "../redux/actions/user";
import { setKeepView } from "../redux/actions/preferences";
import { clearCache, setStripeCache } from "../redux/actions/login";
import { format } from "date-fns";
import CFLib from "../libs/cloudfunction";
import { Route, withRouter, Switch } from "react-router-dom";
import MayPWithSpinner from "../common/components/utils/MayPWithSpinner";
import Logo from "../assets/maypay_logo_white_merchant.svg";

const P_LOGIN = { label: "accedi", index: 0 };
const P_TERMS = { label: "termini", index: 1 };
const P_REGISTER = { label: "registrazione", index: 2 };
const P_VERIFYEMAIL = { label: "verificaEmail", index: 3 };
const P_RESETPASSWORD_1 = { label: "resetPassword", index: 4 };
const P_USERDATA_1 = { label: "datiAziendali", index: 5 };
const P_USERDATA_2 = { label: "datiTitolare", index: 6 };
const P_BANKDATA = { label: "datiBancari", index: 7 };
const P_REGISTRATION_END = { label: "registrazioneCompletata", index: 8 };

const LoginLoading = () => {
  return (
    <MayPWithSpinner waiting={true}>
      <div
        style={{
          width: "100vw",
          height: "100vh",
        }}
      ></div>
    </MayPWithSpinner>
  );
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.onAccediSubmit = this.onAccediSubmit.bind(this);
    this.onTermsSubmit = this.onTermsSubmit.bind(this);
    this.onUserCreated = this.onUserCreated.bind(this);
    this.onVerificationAction = this.onVerificationAction.bind(this);
    this.onResetEmailAction = this.onResetEmailAction.bind(this);
    this.onCompanyData = this.onCompanyData.bind(this);
    this.onUserData = this.onUserData.bind(this);
    this.onBankData = this.onBankData.bind(this);
    this.onRegistrationEnd = this.onRegistrationEnd.bind(this);
    this.calculateLoginStep = this.calculateLoginStep.bind(this);
    this.onReset = this.onReset.bind(this);

    console.log("REDUX PAGE", props.currentPage);
    this.state = {
      waiting: false,
      waitingStripe: false,
      currentPage: props.currentPage,
      step: 0,
      maxSteps: 5,
      userInfo: {},
      company_data: {},
      user_data: {},
      bank_data: {},
      openDialog: false,
      dialogTitle: "",
      dialogMessage: "",
      dialogActions: [],
      pages: [
        {
          el: Accedi,
          props: {
            page: P_LOGIN.label,
            onShow: undefined,
            onSubmit: this.onAccediSubmit,
          },
        },
        {
          el: Termini,
          props: {
            page: P_TERMS.label,
            onShow: undefined,
            onSubmit: this.onTermsSubmit,
          },
        },
        {
          el: Registrazione1,
          props: {
            page: P_REGISTER.label,
            onShow: undefined,
            onSubmit: this.onUserCreated,
          },
        },
        {
          el: VerificaEmail,
          props: {
            page: P_VERIFYEMAIL.label,
            onShow: undefined,
            onSubmit: this.onVerificationAction,
          },
        },
        {
          el: Reimposta1,
          props: {
            page: P_RESETPASSWORD_1.label,
            onShow: undefined,
            onSubmit: this.onResetEmailAction,
          },
        },
        {
          el: DatiAziendali,
          props: {
            page: P_USERDATA_1.label,
            onShow: undefined,
            onSubmit: this.onCompanyData,
          },
        },
        {
          el: DatiTitolare,
          props: {
            page: P_USERDATA_2.label,
            onShow: undefined,
            onSubmit: this.onUserData,
            waiting: false,
          },
        },
        {
          el: ExternalAccount,
          props: {
            page: P_BANKDATA.label,
            onShow: undefined,
            onSubmit: this.onBankData,
            waiting: false,
          },
        },
        {
          el: RegistrationEnd,
          props: {
            page: P_REGISTRATION_END.label,
            onShow: undefined,
            onSubmit: this.onRegistrationEnd,
          },
        },
      ],
    };
    this.db = getFirestore();
    //this.calculateLoginStep();
    //console.log(this.state)
  }

  onReset() {
    console.log("SignOut request");
    signOut(getAuth()).catch((error) => {
      console.error(error);
    });

    this.setState({
      currentPage: P_LOGIN.label,
      step: 0,
    });
    this.props.history.push("/login/" + P_LOGIN.label);
  }

  onTermsSubmit(page, evt, data) {
    if (evt === "terms-accepted") {
      this.setState({ currentPage: P_REGISTER.label, step: 1 });
      this.props.history.push("/login/" + P_REGISTER.label);
    } else if (evt === "on-home") {
      this.setState({ currentPage: P_LOGIN.label, step: 0 });
      this.props.history.push("/login/" + P_LOGIN.label);
    }
  }

  onAccediSubmit(page, evt, data) {
    if (evt === "goto-registration") {
      //goto registrazione
      console.log("goto-registration");
      this.setState({ currentPage: P_TERMS.label, step: 0 });
      this.props.history.push("/login/" + P_TERMS.label);
    } else if (evt === "goto-resetpassword") {
      this.setState({ currentPage: P_RESETPASSWORD_1.label, step: 0 });
      this.props.history.push("/login/" + P_RESETPASSWORD_1.label);
    } else if (evt === "on-login") {
      //Login avvenuto con successo
      // this.props.history.push("/main/home");
    }
  }

  onUserCreated(page, evt, data) {
    //onUserCreated
    if (evt === "on-usercreated") {
      this.setState({ currentPage: P_VERIFYEMAIL.label, step: 2 });
      this.props.history.push("/login/" + P_VERIFYEMAIL.label);
    } else if (evt === "on-home") {
      this.setState({ currentPage: P_TERMS.label, step: 0 });
      this.props.history.push("/login/" + P_TERMS.label);
    }
  }

  onVerificationAction(page, evt, data) {
    if (evt === "goto-register") {
      this.setState({ currentPage: P_REGISTER.label, step: 1 });
      this.props.history.push("/login/" + P_REGISTER.label);
    } else {
      this.setState({ currentPage: P_USERDATA_1.label, step: 3 });
      this.props.history.push("/login/" + P_USERDATA_1.label);
      this.props.setKeepView(true);
      this.props.updateStoredUser(data);
    }
  }

  onResetEmailAction(page, evt, data) {
    console.log(evt);
    if (evt === "goto-login") {
      this.setState({ currentPage: P_LOGIN.label, step: 0 });
      this.props.history.push("/login/" + P_LOGIN.label);
    } else if (evt === "goto-register") {
      this.setState({ currentPage: P_TERMS.label, step: 0 });
      this.props.history.push("/login/" + P_TERMS.label);
    }
  }

  onCompanyData(page, evt, data) {
    if (evt === "on-userdata1-back") {
      this.onReset();
    } else {
      this.setState({
        company_data: data,
        currentPage: P_USERDATA_2.label,
        step: 4,
      });
      this.props.history.push("/login/" + P_USERDATA_2.label);
    }
  }

  async onUserData(page, evt, data) {
    if (evt === "on-userdata") {
      this.setState({
        user_data: data,
        currentPage: P_BANKDATA.label,
        step: 5,
      });
      this.props.history.push("/login/" + P_BANKDATA.label);
    } else if (evt === "on-userdata-back") {
      this.setState({
        currentPage: P_USERDATA_1.label,
        step: 3,
      });
      this.props.history.push("/login/" + P_USERDATA_1.label);
    }
  }

  async onBankData(page, evt, data) {
    console.log(this.props, getAuth().currentUser, this.state);
    if (evt === "on-bankdata" && this.props.isAuth) {
      const cd = this.state.company_data;
      const ud = this.state.user_data;

      let configDoc = await getDoc(
        doc(collection(this.db, "settings"), "config")
      );
      let termsVersion = "1.0";
      if (configDoc.exists()) {
        let config = configDoc.data();
        termsVersion = config.termsVersion;
      }

      const md = Object.assign({}, cd, ud);
      md.termsAccepted = [
        {
          version: termsVersion,
          date: format(new Date(), "dd/MM/yyyy"),
        },
      ];
      md.stripe_account_id = this.props.stripeCache.account_id || null;
      md.stripe_owner_id = this.props.stripeCache.owner_id || null;
      md.type = "merchant";
      // md.registered_to_contest = false;
      md.stripe_requirements = null;

      const user = getAuth().currentUser;
      let pages = this.state.pages;
      // pages[P_BANKDATA.index].props.waiting = true;
      this.setState(
        {
          waiting: true,
          pages: pages,
        },
        async () => {
          if (user) {
            const cfLib = new CFLib();
            //if(!this.props.stripeCache.account_id || !this.props.stripeCache.owner_id){
            console.log(md);
            const stripeAccount = await cfLib.registerStripeCustomAccount(md);

            console.log("Stripe account:", stripeAccount);

            if (stripeAccount && !stripeAccount.success) {
              pages[P_BANKDATA.index].props.waiting = false;
              let m = "";
              let p = P_USERDATA_1.label;
              if (!stripeAccount.data.account) {
                m =
                  "Si è verificato un errore nella creazione dell'account aziendale.";
              } else if (!stripeAccount.data.owner) {
                m =
                  "Si è verificato un errore nella creazione dell'account del rappresentante.";
                p = P_USERDATA_2.label;
              }
              this.props.history.push("/login/" + p);

              this.setState({
                waiting: false,
                pages: pages,
                currentPage: p,
                openDialog: true,
                dialogActions: ["OK"],
                dialogTitle: "Si è verificato un errore",
                dialogMessage: m + " " + stripeAccount.error,
                onDialogClose: async (action) => {
                  if (action === "OK") {
                    this.setState({
                      openDialog: false,
                    });
                  }
                },
              });
              let c = this.props.stripeCache || {};
              if (stripeAccount.data) {
                c.account_id = stripeAccount.data.account
                  ? stripeAccount.data.account.id
                  : null;
                c.owner_id = stripeAccount.data.owner
                  ? stripeAccount.data.owner.id
                  : null;
              }
              this.props.setStripeCache(c);

              md.registration_completed = false;
              const userRef = await cfLib.upsertMerchantAccount(md);
              
              if (userRef && userRef.success) {
                console.log("partial registration success")
              } else {
                console.log("Cannot save partial merchant data");
              }
              return;
            } //else{


            let c = this.props.stripeCache || {};
            if (stripeAccount.data) {
              c.account_id = stripeAccount.data.account
                ? stripeAccount.data.account.id
                : null;
              c.owner_id = stripeAccount.data.owner
                ? stripeAccount.data.owner.id
                : null;
              md.stripe_requirements = stripeAccount.data.account
                ? stripeAccount.data.account.requirements
                : null;

              md.stripe_account_id = c.account_id;
              md.stripe_owner_id = c.owner_id;
            }

            //}
            //}

            //bank account
            const bd = {
              accountId: this.props.stripeCache.account_id,
              ...data,
            };

            //if(!this.props.stripeCache.ext_account_id){
            const stripeExtAccount = await cfLib.setStripeExternalAccount(bd);

            console.log("Stripe account:", stripeExtAccount);

            if (!stripeExtAccount.success) {
              pages[P_BANKDATA.index].props.waiting = false;
              this.setState({
                waiting: false,
                pages: pages,
                openDialog: true,
                dialogActions: ["OK"],
                dialogTitle: "Si è verificato un errore",
                dialogMessage: stripeExtAccount.error,
                onDialogClose: async (action) => {
                  if (action === "OK") {
                    this.setState({
                      openDialog: false,
                    });
                  }
                },
              });
              let c = this.props.stripeCache || {};
              if (stripeExtAccount.data) {
                c.ext_account_id = stripeExtAccount.data.bank_account
                  ? stripeExtAccount.data.bank_account.id
                  : null;
              }
              this.props.setStripeCache(c);

              // save partial merchant document 
              md.registration_completed = false;
              const userRef = await cfLib.upsertMerchantAccount(md);
              
              if (userRef && userRef.success) {
                console.log("partial registration success")
              } else {
                console.log("Cannot save partial merchant data");
              }
              return;
            } 

            let c1 = this.props.stripeCache || {};
            if (stripeExtAccount.data) {
              c1.ext_account_id = stripeExtAccount.data.bank_account
                ? stripeExtAccount.data.bank_account.id
                : null;
            }
            this.props.setStripeCache(c1);

            //}
            //}

            //Update profile of firebase user

            updateProfile(getAuth().currentUser, {
              displayName: ud.owner_name,
            })
              .then(async () => {
                // Update successful, set other profile data in DB .
                md.stripe_account_id = this.props.stripeCache.account_id;
                md.stripe_owner_id = this.props.stripeCache.owner_id;
                md.stripe_ext_account_id =
                  this.props.stripeCache.ext_account_id;
                //Call to cloud function
                md.registration_completed = true;
                console.log("registration completed");
                const userRef = await cfLib.upsertMerchantAccount(md);
                console.log(userRef);

                if (userRef && !userRef.success) {
                  const error = userRef.error.code || "";
                  console.log(error);
                  this.setState({
                    waiting: false,
                    pages: pages,
                    currentPage: P_BANKDATA,
                    step: 5,
                    openDialog: true,
                    dialogActions: ["OK"],
                    dialogTitle: "Si è verificato un errore",
                    dialogMessage:
                      "Non è stato possibile completare l'operazione. " + error,
                    onDialogClose: async (action) => {
                      if (action === "OK") {
                        this.setState({
                          openDialog: false,
                        });
                      }
                    },
                  });
                  return;
                }

                /*profileRef.set(md).then(()=>{*/
                this.props.updateStoredUser(getAuth().currentUser);
                this.props.setKeepView(true);
                this.props.clearCache();
                try {
                  // window.notificationManager.configureMessaging("merchants");
                } catch (e) {
                  console.log(e);
                }
                pages[P_BANKDATA.index].props.waiting = false;
                /*if(stripeAccount.data.account.requirements.disabled_reason){
                current_page = P_STRIPE_LINK;
              }*/
                this.props.history.push("/login/" + P_REGISTRATION_END.label);
                this.setState(
                  {
                    waiting: false,
                    bank_data: data,
                    currentPage: P_REGISTRATION_END.label,
                    step: 6,
                    pages: pages,
                  },
                  () => {}
                );
                /*}).catch((error)=>{
              //error
              console.error(error);
            }).finally(()=>{

            });*/
              })
              .catch(function (error) {
                console.error(error);
                this.setState({
                  waiting: false,
                  openDialog: true,
                  dialogActions: ["OK"],
                  dialogTitle: "Si è verificato un errore",
                  dialogMessage:
                    "Non è stato possibile completare l'operazione. " +
                    error.message,
                  onDialogClose: async (action) => {
                    if (action === "OK") {
                      this.setState({
                        openDialog: false,
                      });
                    }
                  },
                });
              });
          }
        }
      );
    } else if (evt === "on-bankdata-back") {
      this.setState({
        currentPage: P_USERDATA_2.label,
        step: 4,
      });
      this.props.history.push("/login/" + P_USERDATA_2.label);
    }
  }

  onRegistrationEnd(page, evt, data) {
    if (evt === "on-reset") {
      this.onReset();
    } else if ("on-registrationend") {
      this.props.setKeepView(false);
      const user = getAuth().currentUser;
      user.registration_completed = true;
      this.props.updateStoredUser(user);
    }
  }

  onBackButtonEvent(event) {
    event.preventDefault();
    console.log("back button pressed");
  }

  componentDidMount() {
    console.log("LOGINPAGE", this.props);
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = this.onBackButtonEvent;
    this.calculateLoginStep();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAuth && this.props.isAuth) {
      console.log("LOGGED");
      this.calculateLoginStep();
    } else if (prevProps.isAuth && !this.props.isAuth) {
      console.log("LOGGED OUT", this.props);
    }
  }

  calculateLoginStep() {
    console.log(this.props);
    if (!this.props.isAuth) {
      this.setState({ currentPage: P_LOGIN.label });
      this.props.history.push("/login/" + P_LOGIN.label);
    } else if (this.props.isAuth && !this.props.isVerified) {
      this.setState({ currentPage: P_VERIFYEMAIL.label, step: 2 });
      this.props.history.push("/login/" + P_VERIFYEMAIL.label);
    } else if (
      this.props.isAuth &&
      this.props.isVerified &&
      !this.props.isRegistered
    ) {
      this.setState({
        currentPage: P_USERDATA_1.label,
        step: 3,
      });
      this.props.history.push("/login/" + P_USERDATA_1.label);

      this.props.setKeepView(true);
    } else {
      if (this.props.keepView) {
        //console.log(this.props.isAuth, this.props.isVerified, this.props.isRegistered)

        this.setState({
          currentPage: P_REGISTRATION_END.label,
        });
        this.props.history.push("/login/" + P_REGISTRATION_END.label);
      } else {
        //this.props.setKeepView(false);
        this.props.history.push("/login/" + P_TERMS.label);
      }
    }
    //return <LoginPage/>
  }

  render() {
    const progress = Math.ceil((this.state.step / this.state.maxSteps) * 100);
    /*let minH = "670px";
    if(this.state.currentPage===P_USERDATA_1.label){
      minH = "800px"
    }
    if(this.state.currentPage===P_USERDATA_2.label){
      minH = "950px"
    }*/
    let loginRootClass = "login-root";
    if (this.state.step > 0 && this.state.step < 6) {
      loginRootClass = "login-root gray";
    }

    return (
      <Container
        classes={{ root: loginRootClass }}
        style={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          minWidth: "100%",
          minHeight: "100vh",
          margin: 0,
        }}
      >
        <div className={"background"}>
          <img
            className={"background"}
            alt="background"
            src="/login_background.svg"
          />
          <img className={"logo"} alt="logo" src={Logo} />
          {/* <div className={"title"}>Area Esercenti</div> */}
        </div>
        {(this.state.step <= 0 || this.state.step >= 6) && (
          <div className="empty"></div>
        )}
        <div className="content-container">
          <div className="content">
            {this.state.step > 0 && this.state.step < 6 && (
              <div className="login-progress-wrapper">
                <MayPLinearProgress
                  variant="determinate"
                  color="primary"
                  value={progress}
                  style={{
                    minWidth: "200px",
                  }}
                />
                <div className="login-progress-step">
                  {this.state.step}/{this.state.maxSteps}
                </div>
              </div>
            )}
            <Switch>
              <Route
                path={"/login/" + P_LOGIN.label}
                children={
                  <Accedi page={P_LOGIN.label} onSubmit={this.onAccediSubmit} />
                }
              />
              <Route
                path={"/login/" + P_TERMS.label}
                children={
                  <Termini page={P_TERMS.label} onSubmit={this.onTermsSubmit} />
                }
              />
              <Route
                path={"/login/" + P_REGISTER.label}
                children={
                  <Registrazione1
                    page={P_REGISTER.label}
                    onSubmit={this.onUserCreated}
                  />
                }
              />
              <Route
                path={"/login/" + P_VERIFYEMAIL.label}
                children={
                  <VerificaEmail
                    page={P_VERIFYEMAIL.label}
                    onSubmit={this.onVerificationAction}
                  />
                }
              />
              <Route
                path={"/login/" + P_RESETPASSWORD_1.label}
                children={
                  <Reimposta1
                    page={P_RESETPASSWORD_1.label}
                    onSubmit={this.onResetEmailAction}
                  />
                }
              />
              <Route
                path={"/login/" + P_USERDATA_1.label}
                render={() => {
                  if (getAuth().currentUser) {
                    return (
                      <DatiAziendali
                        page={P_USERDATA_1.label}
                        onSubmit={this.onCompanyData}
                      />
                    );
                  }
                  return <LoginLoading />;
                }}
              />
              <Route
                path={"/login/" + P_USERDATA_2.label}
                render={() => {
                  if (getAuth().currentUser) {
                    return (
                      <DatiTitolare
                        page={P_USERDATA_2.label}
                        onSubmit={this.onUserData}
                        waiting={false}
                      />
                    );
                  }
                  return <LoginLoading />;
                }}
              />
              <Route
                path={"/login/" + P_BANKDATA.label}
                render={() => {
                  if (getAuth().currentUser) {
                    return (
                      <ExternalAccount
                        page={P_BANKDATA.label}
                        onSubmit={this.onBankData}
                        waiting={this.state.waiting}
                      />
                    );
                  }
                  return <LoginLoading />;
                }}
              />
              <Route
                path={"/login/" + P_REGISTRATION_END.label}
                render={() => {
                  if (getAuth().currentUser) {
                    return (
                      <RegistrationEnd
                        page={P_REGISTRATION_END.label}
                        onSubmit={this.onRegistrationEnd}
                      />
                    );
                  }
                  return <LoginLoading />;
                }}
              />
            </Switch>
          </div>
        </div>
        <MayPDialog
          forMerchant={true}
          open={this.state.openDialog}
          title={this.state.dialogTitle}
          message={this.state.dialogMessage}
          actions={this.state.dialogActions}
          onClose={() => {
            this.setState({
              openDialog: false,
            });
          }}
          maxWidth={"xs"}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("redux state", state);

  return {
    db: state.app.db,
    currentPage: state.login.currentStep,
    storedUser: state.user,
    isAuth: state.user.uid ? true : false,
    isRegistered: state.user.registered ? true : false,
    isVerified: state.user.verified ? true : false,
    keepView: state.preferences.keepView,
    stripeCache: state.login.stripeCache || {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateStoredUser: (user) => dispatch(setAuth(user)),
    setKeepView: (keep) => dispatch(setKeepView(keep)),
    clearCache: () => dispatch(clearCache()),
    setStripeCache: (data) => dispatch(setStripeCache(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPage));
