import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import MayPFormBuider from "../../common/components/utils/MayPFormBuilder";
import { connect } from "react-redux";
import { setCompanyCache } from "../../redux/actions/login";
import province from "./province";
import countries from "./countries";
import styles from "./DatiAziendali.module.css";
import allCountryCodes from "country-telephone-data";
import { MayPButtonTextWrap } from "../../common/components/utils/MayPButtonText";
import { withRouter } from "react-router-dom";

let countryPhoneCodes = [];
allCountryCodes.allCountries.reduce((acc, value) => {
  if (acc.indexOf(value.dialCode) === -1) {
    acc.push(value.dialCode);
    countryPhoneCodes.push({
      label: "+" + value.dialCode,
      value: "+" + value.dialCode,
    });
  }
  return acc;
}, []);

countryPhoneCodes.sort((a, b) => {
  if (a.value < b.value) {
    return -1;
  } else if (a.value > b.value) {
    return 1;
  }
  return 0;
});

const ButtonBack = MayPButtonTextWrap({
  root: {
    fontSize: "14px",
    letterSpacing: "1.6x",
    fontWeight: "normal",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #606060",
    color: "#606060",
  },
  disabled: {
    color: "#cccccc",
  },
});

class DatiAziendali extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formValid: false,
      waiting: false,
      error: 0,
      errorMessage: "",
      formData: props.cache,
    };
    this.formItems = [
      // {
      //   type: "custom",
      //   name: "title1",
      //   customEl: "Dati del negozio",
      //   wrapperClass: styles.profile_title,
      // },
      // {
      //   type: "text",
      //   label: "Nome profilo/negozio",
      //   name: "store_name",
      //   defaultValue: this.state.formData.store_name,
      //   wrapperClass: "form-data w100",
      //   nextFocus: "store_address",
      // },
      // {
      //   type: "text",
      //   label: "Indirizzo",
      //   name: "store_address",
      //   defaultValue: this.state.formData.store_address,
      //   wrapperClass: "form-data w100",
      //   nextFocus: "store_address_number",
      // },
      // {
      //   type: "text",
      //   label: "Civico",
      //   name: "store_address_number",
      //   defaultValue: this.state.formData.store_address_number,
      //   wrapperClass: "form-data w50",
      //   nextFocus: "store_cap",
      // },
      // {
      //   type: "number",
      //   label: "CAP",
      //   name: "store_cap",
      //   defaultValue: this.state.formData.store_cap,
      //   wrapperClass: "form-data w50",
      //   nextFocus: "store_city",
      // },
      // {
      //   type: "text",
      //   label: "Città",
      //   name: "store_city",
      //   defaultValue: this.state.formData.store_city,
      //   wrapperClass: "form-data w50",
      //   nextFocus: "store_state",
      // },
      // {
      //   type: "text",
      //   label: "Stato",
      //   name: "store_state",
      //   defaultValue: this.state.formData.store_state || "IT",
      //   wrapperClass: "form-data w50",
      //   validation: [{ re: /^[A-Za-z]{2,3}$/, message: "Valore non valido" }],
      //   nextFocus: "store_category",
      //   disabled: true,
      // },
      {
        type: "custom",
        name: "title2",
        customEl: "Dati aziendali",
        wrapperClass: styles.profile_title,
      },
      {
        type: "text",
        label: "Ragione Sociale",
        name: "company_name",
        defaultValue: props.cache.company_name,
        wrapperClass: "form-data w100",
        nextFocus: "company_vat",
      },
      {
        type: "text",
        label: "Partita IVA",
        name: "company_vat",
        defaultValue: props.cache.company_vat,
        wrapperClass: "form-data w100",
        nextFocus: "company_rea",
      },
      {
        type: "text",
        label: "N. iscr. al Registro delle Imprese",
        name: "company_rea",
        defaultValue: props.cache.company_rea,
        wrapperClass: "form-data w100",
        nextFocus: "company_address",
      },
      {
        type: "text",
        label: "Indirizzo sede legale",
        name: "company_address",
        defaultValue: props.cache.company_address,
        wrapperClass: "form-data w100",
        nextFocus: "company_address_number",
      },
      {
        type: "text",
        label: "Civico",
        name: "company_address_number",
        defaultValue: props.cache.company_address_number,
        wrapperClass: "form-data w50",
        nextFocus: "company_postal_code",
      },
      {
        type: "number",
        label: "CAP",
        name: "company_postal_code",
        defaultValue: props.cache.company_postal_code,
        wrapperClass: "form-data w50",
        nextFocus: "company_city",
      },
      {
        type: "text",
        label: "Città",
        name: "company_city",
        defaultValue: props.cache.company_city,
        wrapperClass: "form-data w50",
        nextFocus: "company_state",
      },
      {
        type: "select",
        label: "Provincia",
        name: "company_state",
        defaultValue: props.cache.company_state,
        wrapperClass: "form-data w50",
        nextFocus: "company_country",
        values: province,
      },
      {
        type: "select",
        label: "Stato",
        name: "company_country",
        defaultValue: props.cache.company_country || "IT",
        wrapperClass: "form-data w100",
        isOptional: false,
        disabled: true,
        validation: [{ re: /^\w+$/, message: "Stato non valido" }],
        nextFocus: "company_phone_code",
        values: countries,
      },
      {
        type: "select",
        label: "Prefisso",
        name: "company_phone_code",
        defaultValue: props.cache.company_phone_code || "+39",
        wrapperClass: "form-data w30",
        nextFocus: "company_phone",
        values: countryPhoneCodes,
      },
      {
        type: "text",
        label: "Numero di telefono",
        name: "company_phone",
        defaultValue: props.cache.company_phone,
        wrapperClass: "form-data w70",
        isOptional: false,
        disabled: false,
        validation: [
          { re: /^[ 0-9]+$/, message: "Numero di telefono non valido" },
        ],
        nextFocus: "company_url",
      },
      {
        type: "text",
        label: "Sito internet",
        name: "company_url",
        defaultValue: props.cache.company_urln || "",
        wrapperClass: "form-data w100",
        isOptional: true,
        disabled: false,
        nextFocus: "company_product_description",
        //validation:[{re:/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/, message:"IBAN non valido"}],
      },
      {
        type: "custom",
        customEl: (
          <div className={styles.formLabel}>
            Non hai un sito web? Nel box sottostante puoi condividere il link di
            un App Store, un profilo aziendale sui social media o aggiungere la
            descrizione del prodotto.
          </div>
        ),
      },
      {
        type: "text",
        label: "Link, profilo o descrizione",
        name: "company_product_description",
        multiline: true,
        rows: 2,
        variant: "outlined",
        defaultValue: props.cache.company_product_description || "",
        wrapperClass: "form-data w100",
        isOptional: true,
        disabled: false,
      },
    ];

    this.onContinue = this.onContinue.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  onContinue() {
    if (this.props.onSubmit) {
      this.props.onSubmit(
        this.props.page,
        "on-companydata",
        this.state.formData
      );
    }
  }

  onBack() {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.page, "on-userdata1-back", this.state.formData);
    }
  }

  componentDidMount() {
    console.log(this.state.formData);
    window.scroll(0, 0);
  }

  render() {
    return (
      <div>
        <MayPFormBuider
          items={this.formItems}
          id="companyForm"
          onValid={(v, data) => {
            this.setState({
              formValid: v,
              formData: data,
            });
            this.props.setCache(data);
          }}
          containerClass={styles.formDataContainer}
        />

        <div
          style={{
            marginTop: "40px",
            textAlign: "center",
            width: "100%",
            marginBottom: "40px",
          }}
        >
          <MayPButtonBlue
            disabled={this.state.waiting || !this.state.formValid}
            onClick={this.onContinue}
          >
            Continua
          </MayPButtonBlue>
        </div>
        <div className="login-page-back">
          <ButtonBack onClick={this.onBack} disabled={this.props.waiting}>
            Annulla Registrazione
          </ButtonBack>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cache: state.login.companyCache || {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCache: (data) => dispatch(setCompanyCache(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DatiAziendali));
