import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import MayPFormBuider from "../../common/components/utils/MayPFormBuilder";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import { MayPButtonTextWrap } from "../../common/components/utils/MayPButtonText";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { withRouter } from "react-router-dom";

const ButtonRegister = MayPButtonTextWrap({
  root: {
    fontSize: "16px",
    letterSpacing: "1.6px",
    fontWeight: "normal",
    lineHeight: "22px",
    color: "#00E091",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #00E091",
  },
});

const ButtonBack = MayPButtonTextWrap({
  root: {
    fontSize: "14px",
    letterSpacing: "1.4px",
    fontWeight: "normal",
    lineHeight: "22px",
    fontFamily: '"Giorgio", Arial, sans-serif',
    marginBottom: "20px",
    color: "#606060",
    "WebkitTextStroke": "0.7px #606060",
  },
  disabled: {
    color: "#CCCCCC",
    "WebkitTextStroke": "0.7px #CCCCCC",
  },
});

class Reimposta1 extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      formValid: false,
      email: "",
      waiting: false,
      error: 0,
      errorMessage: "",
      action: "Invia",
      message:
        "Per recuperare la password, inserisci l’e-mail associata al tuo account.",
      formItems: [
        {
          type: "email",
          label: "E-mail",
          name: "email",
          disabled: false,
          defaultValue: "",
          wrapperClass: "login-input2",
          validation: [
            {
              re: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
              message: "E-mail non valida",
            },
          ],
        },
      ],
    };

    this.onSendResetEmail = this.onSendResetEmail.bind(this);
    this.onGotToRegister = this.onGotToRegister.bind(this);
  }

  onGotToRegister() {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.page, "goto-register", {});
    }
  }

  onSendResetEmail() {
    if (this.state.action === "Invia") {
      this.setState(
        {
          waiting: true,
        },
        () => {
          const actionCodeSettings = {
            url: "https://www.maypay.com/",
            handleCodeInApp: true,
          };
          sendPasswordResetEmail(
            getAuth(),
            this.state.email,
            actionCodeSettings
          )
            .then(() => {
              let items = this.state.formItems;
              items[0].disabled = true;
              console.log(items);
              this.setState(
                {
                  waiting: false,
                  error: 0,
                  errorMessage: "",
                  action: "Continua",
                  message:
                    "Ti abbiamo inviato una e-mail con le istruzioni per reimpostare la password. Clicca su continua per accedere.",
                  formItems: items,
                },
                () => {
                  if (this.props.onSubmit) {
                    this.props.onSubmit(
                      this.props.page,
                      "on-resetemailsent",
                      null
                    );
                  }
                }
              );
            })
            .catch((error) => {
              console.log(error);
              let message = "";
              if (error.code && error.code === "auth/user-not-found") {
                message = "L'email inserita non è associata ad un account.";
              } else if (error.code === "auth/network-request-failed") {
                message =
                  "Errore di rete. Assicurati di essere connesso ad internet.";
              } else {
                message =
                  "Spiacenti si è verificato un errore. Riprova più tardi.";
              }

              this.setState({
                error: 1,
                errorMessage: message,
                waiting: false,
              });
            });
        }
      );
    } else if (this.state.action === "Continua") {
      if (this.props.onSubmit) {
        this.props.onSubmit(this.props.page, "goto-login", {});
      }
    }
  }

  render() {
    return (
      <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <h3 style={{ marginTop: "50px" }}>Password dimenticata</h3>
        <MayPWithSpinner waiting={this.state.waiting}>
          {this.state.error === 0 && (
            <div className="message">{this.state.message}</div>
          )}
          {this.state.error !== 0 && (
            <div className="message error">{this.state.errorMessage}</div>
          )}
        </MayPWithSpinner>
        <MayPFormBuider
          items={this.state.formItems}
          id="loinForm"
          onValid={(v, data) => {
            this.setState({
              formValid: v,
              email: data.email,
            });
          }}
        />
        <div style={{ marginTop: "40px", textAlign: "center", width: "100%" }}>
          <MayPButtonBlue
            disabled={!this.state.formValid || this.state.waiting}
            onClick={this.onSendResetEmail}
          >
            {this.state.action}
          </MayPButtonBlue>
        </div>
        <div style={{ marginTop: "40px", textAlign: "center", width: "100%" }}>
          <ButtonBack
            disabled={this.state.waiting}
            onClick={() => {
              if (this.props.onSubmit) {
                this.props.onSubmit(this.props.page, "goto-login", null);
              }
            }}
          >
            Torna indietro
          </ButtonBack>
        </div>
        <div
          style={{
            marginBottom: "20px",
            flexGrow: "1",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div className="message" style={{ marginBottom: "5px" }}>
            Non hai ancora creato un account?
          </div>
          <div style={{ marginTop: "0px", textAlign: "center", width: "100%" }}>
            <ButtonRegister
              disabled={this.state.waiting}
              onClick={this.onGotToRegister}
            >
              Registrati
            </ButtonRegister>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Reimposta1);
