import { createTheme } from "@material-ui/core/styles";

const MayPMaterialTheme = createTheme({
  overrides: {
    MuiTab: {
      root: {
        fontFamily: "MavenPro Regular",
        fontSize: "14px",
        lineHeight: "17px",
        letterSpacing: "-0.14px",
        color: "#848484",
        textTransform: "none",
      },
      textColorPrimary: {
        color: "inherit",
        "&$selected": {
          color: "#00E091",
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#2BCFB1",
      },
    },
    MuiInputAdornment: {
      root: {
        width: "auto",
        color: "#00a2e0",
      },
    },
    MuiInputLabel: {
      root: {
        //color: '#00a2e0',
        textAlign: "inherit",
        fontFamily: '"MavenPro Regular", Arial, sans-serif',
        fontWeight: "normal",
        fontSize: "15px",
        letterSpacing: "0px",
        lineHeight: "21px",
      },
      shrink: {
        color: "#00a2e0",
      },
    },
    MuiSelect: {
      root: {
        height: "auto",
      },
      icon: {
        color: "#00a2e0",
      },
    },
    MuiInput: {
      root: {
        color: "inherit",
        borderBottomColor: "#00a2e0",
        fontSize: "inherit",
        fontWeight: "inherit",
        letterSpacing: "inherit",
        lineHeight: "inherit",
        fontFamily: "inherit",
        textAlign: "inherit",
        textDecoration: "none",
      },
      input: {
        textAlign: "inherit",
      },
      underline: {
        "&:after": {
          color: "#00a2e0",
          borderBottomColor: "#00a2e0",
        },
        "&:before": {
          color: "#00a2e0",
          borderBottomColor: "#00a2e0",
        },
        "&:focused": {
          color: "#00a2e0",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        color: "inherit",
        borderColor: "#00a2e0",
        fontSize: "inherit",
        fontWeight: "inherit",
        letterSpacing: "inherit",
        lineHeight: "inherit",
        fontFamily: "inherit",
        textAlign: "inherit",
        textDecoration: "none",
        "&:hover": {
          borderColor: "#00a2e0",
        },
        "&:focused": {
          borderColor: "#00a2e0",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#00a2e0",
        },
      },
    },
  },
  typography: {
    fontFamily: ["MavenPro Regular", "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#00a2e0",
    },
    secondary: {
      main: "#00e091",
    },
    error: {
      main: "#f44336",
    },
  },
});

export default MayPMaterialTheme;
