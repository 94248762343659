import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import MayPFormBuider from "../../common/components/utils/MayPFormBuilder";
import { MayPButtonTextWrap } from "../../common/components/utils/MayPButtonText";
import MayPRadio from "../../common/components/utils/MayPRadio";
import { connect } from "react-redux";
import { setOwnerCache } from "../../redux/actions/login";
import province from "./province";
import countries from "./countries";
import styles from "./DatiTitolare.module.css";
import allCountryCodes from "country-telephone-data";
import { withRouter } from "react-router-dom";

let countryPhoneCodes = [];
allCountryCodes.allCountries.reduce((acc, value) => {
  if (acc.indexOf(value.dialCode) === -1) {
    acc.push(value.dialCode);
    countryPhoneCodes.push({
      label: "+" + value.dialCode,
      value: "+" + value.dialCode,
    });
  }
  return acc;
}, []);

countryPhoneCodes.sort((a, b) => {
  if (a.value < b.value) {
    return -1;
  } else if (a.value > b.value) {
    return 1;
  }
  return 0;
});

const ButtonBack = MayPButtonTextWrap({
  root: {
    fontSize: "14px",
    letterSpacing: "1.6px",
    fontWeight: "normal",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #606060",
    color: "#606060",
  },
  disabled: {
    color: "#cccccc",
  },
});

class DatiTitolare extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    const {
      owner_dob,
      isTheOnlyOwner,
      isOwner,
      isTheOnlyDirector,
      ...formData
    } = props.cache;

    this.state = {
      formValid: false,
      waiting: false,
      error: 0,
      errorMessage: "",
      formData: formData,
      isOwner: isOwner ?? false,
      isTheOnlyOwner: isTheOnlyOwner ?? false,
      isTheOnlyDirector: isTheOnlyDirector ?? true,
    };

    this.checkEmailsEq = this.checkEmailsEq.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.onBack = this.onBack.bind(this);

    this.formItems = [
      {
        type: "text",
        label: "Nome",
        name: "owner_name",
        defaultValue: props.cache.owner_name,
        wrapperClass: "form-data w100",
        nextFocus: "owner_surname",
      },
      {
        type: "text",
        label: "Cognome",
        name: "owner_surname",
        defaultValue: props.cache.owner_surname,
        wrapperClass: "form-data w100",
        nextFocus: "owner_dob",
      },
      {
        type: "date",
        defaultValue: props.cache.owner_dob,
        name: "owner_dob",
        wrapperClass: "form-data w100",
        label: "Data di nascita",
        format: "dd/MM/yyyy",
        style: { width: "100%" },
        disableFuture: true,
        cancelLabel: "Annulla",
        okLabel: "Imposta",
        invalidDateMessage: "",
        nextFocus: "owner_address",
      },
      {
        type: "text",
        label: "Indirizzo di residenza",
        name: "owner_address",
        defaultValue: props.cache.owner_address,
        wrapperClass: "form-data w100",
        nextFocus: "owner_address_number",
      },
      {
        type: "text",
        label: "Civico",
        name: "owner_address_number",
        defaultValue: props.cache.owner_address_number,
        wrapperClass: "form-data w50",
        nextFocus: "owner_postal_code",
      },
      {
        type: "number",
        label: "CAP",
        name: "owner_postal_code",
        defaultValue: props.cache.owner_postal_code,
        wrapperClass: "form-data w50",
        nextFocus: "owner_city",
      },
      {
        type: "text",
        label: "Città",
        name: "owner_city",
        defaultValue: props.cache.owner_city,
        wrapperClass: "form-data w50",
        nextFocus: "owner_state",
      },
      {
        type: "select",
        label: "Provincia",
        name: "owner_state",
        defaultValue: props.cache.owner_state,
        wrapperClass: "form-data w50",
        values: province,
        nextFocus: "owner_country",
      },
      {
        type: "select",
        label: "Stato",
        name: "owner_country",
        defaultValue: props.cache.owner_country || "IT",
        wrapperClass: "form-data w100",
        values: countries,
        nextFocus: "owner_country",
      },
      /*{
        type:"text",
        label:"Stato",
        name:"owner_country",
        defaultValue:props.cache.owner_country||"Italia",
        wrapperClass: "form-data w100",
        isOptional: false,
        disabled: false,
        validation:[{re:/^\w+$/, message:"Stato non valido"}],
        nextFocus: "owner_title"
      },*/
      {
        type: "text",
        label: "Ruolo (es. Proprietario)",
        name: "owner_title",
        defaultValue: props.cache.owner_title,
        wrapperClass: "form-data w100",
        nextFocus: "owner_phone",
      },
      {
        type: "select",
        label: "Prefisso",
        name: "owner_phone_code",
        defaultValue: props.cache.owner_phone_code || "+39",
        wrapperClass: "form-data w30",
        nextFocus: "owner_phone",
        values: countryPhoneCodes,
      },
      {
        type: "text",
        label: "Numero di telefono",
        name: "owner_phone",
        defaultValue: props.cache.owner_phone,
        wrapperClass: "form-data w70",
        isOptional: false,
        disabled: false,
        validation: [
          { re: /^[ 0-9]+$/, message: "Numero di telefono non valido" },
        ],
        nextFocus: "owner_email",
      },
      {
        type: "email",
        label: "E-mail",
        name: "owner_email",
        defaultValue: props.cache.owner_email,
        wrapperClass: "form-data w100",
        validation: [
          {
            re: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
            message: "E-mail non valida",
          },
          //{callback:this.checkEmailsEq, message:"Gli indirizzi e-mail non combaciano"}
        ],
        //nextFocus: "owner_email2"
      },
      /*{
        type:"email",
        label:"Conferma E-mail",
        name:"owner_email2",
        defaultValue:props.cache.owner_email2,
        wrapperClass: "form-data w100",
        validation:[{callback:this.checkEmailsEq, message:"Gli indirizzi e-mail non combaciano"}]
      },*/

      {
        type: "custom",
        name: "title1",
        customEl: "Seleziona le voci che corrispondo alla tua struttura aziendale:",
        wrapperClass: styles.radioMessage,
      },
    ];
  }

  checkEmailsEq(items) {
    console.log(items);
    if (
      ((items.owner_email.value && items.owner_email.value !== "") ||
        (items.owner_email2.value && items.owner_email2.value !== "")) &&
      items.owner_email.value !== items.owner_email2.value
    ) {
      return { isValid: false };
    }
    const checked = {
      owner_email: { isValid: true },
      owner_email2: { isValid: true },
    };
    return { isValid: true, items: checked };
  }

  onContinue() {
    if (this.props.onSubmit) {
      const { owner_dob } = this.state.formData;
      const dob = new Date(owner_dob);

      this.props.onSubmit(this.props.page, "on-userdata", {
        isOwner: this.state.isOwner,
        isTheOnlyOwner: this.state.isTheOnlyOwner,
        isTheOnlyDirector: this.state.isTheOnlyDirector,
        owner_dob2: {
          day: dob.getDate(),
          month: dob.getMonth() + 1,
          year: dob.getFullYear(),
        },
        ...this.state.formData,
      });
    }
  }

  onBack() {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.page, "on-userdata-back", {
        isOwner: this.state.isOwner,
        isTheOnlyOwner: this.state.isTheOnlyOwner,
        ...this.state.formData,
      });
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    const { owner_dob } = this.state.formData;
    const dob = new Date(owner_dob);
    console.log({
      isOwner: this.state.isOwner,
      isTheOnlyOwner: this.state.isTheOnlyOwner,
      owner_dob_2: {
        day: dob.getDate(),
        month: dob.getMonth() + 1,
        year: dob.getFullYear(),
      },
      ...this.state.formData,
    });
  }

  render() {
    return (
      <div>
        <h3 style={{ marginTop: "50px" }}>Dati del Rappresentante</h3>

        <MayPFormBuider
          items={this.formItems}
          id="companyForm"
          disableAll={this.props.waiting}
          onValid={(v, data) => {
            this.setState({
              formValid: v,
              formData: data,
            });
            const { owner_dob } = this.state.formData;
            const dob = new Date(owner_dob);

            const cache = {
              isOwner: this.state.isOwner,
              isTheOnlyOwner: this.state.isTheOnlyOwner,
              isTheOnlyDirector: this.state.isTheOnlyDirector,
              owner_dob2: {
                day: dob.getDate(),
                month: dob.getMonth() + 1,
                year: dob.getFullYear(),
              },
              ...data,
            };
            this.props.setCache(cache);
          }}
          containerClass={styles.formDataContainer}
        />
        <div className={styles.ownerOptions}>
          <MayPRadio
            label="Come Rappresentante, possiedo almeno il 25% dell'azienda"
            labelPlacement="start"
            disabled={this.props.waiting}
            style={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              margin: 0,
            }}
            classes={{
              label: styles.label,
            }}
            radioProps={{
              checked: this.state.isOwner,
              onClick: (e) => {
                const checked = !this.state.isOwner;
                console.log();
                this.setState({
                  isOwner: checked,
                });
                const { owner_dob } = this.state.formData;
                const dob = new Date(owner_dob);

                const cache = {
                  isOwner: checked,
                  isTheOnlyOwner: this.state.isTheOnlyOwner,
                  isTheOnlyDirector: this.state.isTheOnlyDirector,
                  owner_dob2: {
                    day: dob.getDate(),
                    month: dob.getMonth() + 1,
                    year: dob.getFullYear(),
                  },
                  ...this.state.formData,
                };
                this.props.setCache(cache);
              },
              value: "isOwner",
            }}
          />
          <div className={styles.separator}></div>
          <MayPRadio
            label="Sono l’unica persona a detenere almeno il 25% dell'azienda"
            labelPlacement="start"
            disabled={this.props.waiting}
            style={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              margin: 0,
            }}
            radioProps={{
              checked: this.state.isTheOnlyOwner,
              onClick: (e) => {
                const checked = !this.state.isTheOnlyOwner;
                console.log();
                this.setState({
                  isTheOnlyOwner: checked,
                });
                const { owner_dob } = this.state.formData;
                const dob = new Date(owner_dob);

                const cache = {
                  isOwner: this.state.isOwner,
                  isTheOnlyOwner: checked,
                  isTheOnlyDirector: this.state.isTheOnlyDirector,
                  owner_dob2: {
                    day: dob.getDate(),
                    month: dob.getMonth() + 1,
                    year: dob.getFullYear(),
                  },
                  ...this.state.formData,
                };
                this.props.setCache(cache);
              },
              value: "isTheOnlyOwner",
            }}
          />
          <div className={styles.separator}></div>
          <MayPRadio
            label="Ci sono altri membri nel direttivo dell'azienda"
            labelPlacement="start"
            disabled={this.props.waiting}
            style={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              margin: 0,
            }}
            radioProps={{
              checked: !this.state.isTheOnlyDirector,
              onClick: (e) => {
                const checked = !this.state.isTheOnlyDirector;
                console.log(checked);
                this.setState({
                  isTheOnlyDirector: checked,
                });
                const { owner_dob } = this.state.formData;
                const dob = new Date(owner_dob);

                const cache = {
                  isOwner: this.state.isOwner,
                  isTheOnlyOwner: this.state.isTheOnlyOwner,
                  isTheOnlyDirector: checked,
                  owner_dob2: {
                    day: dob.getDate(),
                    month: dob.getMonth() + 1,
                    year: dob.getFullYear(),
                  },
                  ...this.state.formData,
                };
                this.props.setCache(cache);
              },
              value: "isTheOnlyDirector",
            }}
          />
        </div>
        <div style={{ marginTop: "40px", textAlign: "center", width: "100%" }}>
          <MayPWithSpinner waiting={this.props.waiting}>
            <MayPButtonBlue
              disabled={
                this.props.waiting ||
                !this.state.formValid 
                // || !this.state.isOwner
              }
              onClick={this.onContinue}
            >
              Continua
            </MayPButtonBlue>
          </MayPWithSpinner>
        </div>
        <div className="login-page-back">
          <ButtonBack onClick={this.onBack} disabled={this.props.waiting}>
            Torna indietro
          </ButtonBack>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cache: state.login.ownerCache || {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCache: (data) => dispatch(setOwnerCache(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DatiTitolare));
