import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import Logo from "../../assets/registrazione/maypay_logo.svg";
import BigIcon from "../../assets/mp_verifing_account_new-01.svg";
import WelcomeIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_wellcome_icon.svg";
import CFLib from "../../libs/cloudfunction";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { withRouter } from "react-router-dom";
import { redirectUrl } from "../../appenv";
import { MayPButtonTextWrap } from "../../common/components/utils/MayPButtonText";

const Buttontext = MayPButtonTextWrap({
  root: {
    fontSize: "14px",
    letterSpacing: "1.4px",
    lineHeight: "22px",
    fontWeight: "normal",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #606060",
    color: "#606060",
  },
  disabled: {
    color: "#cccccc",
  },
});

class RegistrationEnd extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      waiting: true,
      error: 0,
      errorMessage: "",
      go2Stripe: false,
      accountLink: undefined,
    };

    this.onContinue = this.onContinue.bind(this);
    this.checkStripe = this.checkStripe.bind(this);
    this.onRedirect = this.onRedirect.bind(this);
    this.onWindowMessage = this.onWindowMessage(this);
  }

  async onContinue() {
    const res = await this.checkStripe();

    if (res && res.success && this.props.onSubmit) {
      this.props.onSubmit(this.props.page, "on-registrationend", {});
    }
  }

  onRedirect(data) {
    console.log("App loaded from custom url", data);
    if (
      data.url &&
      data.url === "maypaymerchant://page?id=stripeonboardredirect"
    ) {
      Browser.close();
      this.checkStripe();
    }
  }

  async componentDidMount() {
    App.addListener("appUrlOpen", this.onRedirect);

    App.addListener("appStateChange", (state) => {
      // state.isActive contains the active state
      console.log("App state changed. Is active?", state.isActive);
      if (state.isActive) {
        this.checkStripe();
      }
    });

    window.addEventListener("message", this.onWindowMessage);

    this.checkStripe();
  }

  componentWillUnmount() {
    App.removeAllListeners();
    window.removeEventListener("message", this.onWindowMessage);
  }

  onWindowMessage(e) {
    console.log(e);
    if (e.data === "redirected") {
      this.checkStripe();
    }
  }

  async checkStripe() {
    const cfLib = new CFLib();
    this.setState({
      waiting: true,
      go2Stripe: false,
      accountLink: undefined,
    });
    const stripeAccount = await cfLib.getStripeCustomAccount({
      refresh_url: redirectUrl /*"https://localhost:3001/redirect.html",/**/,
      return_url: redirectUrl /*"https://localhost:3001/redirect.html"/**/,
    });
    console.log("....", stripeAccount);
    if (stripeAccount && stripeAccount.success) {
      if (
        !stripeAccount.data.account.requirements.currently_due.length &&
        !stripeAccount.data.account.requirements.past_due.length
      ) {
        this.setState({
          waiting: false,
          go2Stripe: false,
        });
      } else {
        this.setState({
          waiting: false,
          go2Stripe: true,
          accountLink: stripeAccount.data.accountLink,
        });
      }
    } else {
      this.setState({
        waiting: false,
        go2Stripe: false,
      });
    }
    return stripeAccount;
  }

  render() {
    return (
      <div>
        <div style={{ display: "inline-block" /*, marginTop: "50px" */ }}>
          <img
            src={Logo}
            style={{ width: "100%", height: "auto" }}
            alt="maypay_logo"
          />
        </div>

        <MayPWithSpinner waiting={this.state.waiting}>
          {!this.state.go2Stripe && (
            <React.Fragment>
              <div style={{ display: "inline-block", marginTop: "60px" }}>
                <img
                  src={WelcomeIc}
                  style={{ width: "auto", height: "auto" }}
                  alt="WelcomeIc"
                />
              </div>
              <h3>Ti diamo il benvenuto</h3>
              <div className="message">
                Il tuo account business è stato creato con successo!
              </div>

              <div
                style={{
                  marginTop: "40px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <MayPButtonBlue
                  disabled={this.state.waiting}
                  onClick={this.onContinue}
                >
                  Inizia
                </MayPButtonBlue>
              </div>
            </React.Fragment>
          )}

          {this.state.go2Stripe && (
            <React.Fragment>
              <div style={{ display: "inline-block" }}>
                <img src={BigIcon} alt="Icon" />
              </div>
              <h3 style={{ marginTop: "0px" }}>
                Verifica dell'account necessaria
              </h3>
              <div className="message">
                Maypay collabora con Stripe per offrire servizi finanziari
                sicuri. Continua per inserire le informazioni mancanti.
                <div style={{marginTop: "20px"}}>
                Assicurati che i popup siano abilitati, per procedere correttamente.
                </div>
              </div>

              <div
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <MayPButtonBlue
                  disabled={this.state.waiting}
                  onClick={async () => {
                    await this.checkStripe();

                    if (this.state.accountLink) {
                      window.open(
                        /*"https://localhost:3001/redirect.html"*/ this.state
                          .accountLink.url,
                        "_blank"
                      );
                      //console.log(rwindow);
                      // await Browser.open({ url: this.state.accountLink.url});
                    }
                  }}
                >
                  Continua
                </MayPButtonBlue>
              </div>

              <div
                className="page-title2"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                <Buttontext
                  onClick={(e) => {
                    if (this.props.onSubmit) {
                      this.props.onSubmit(this.props.page, "on-reset", undefined);
                    }
                  }}
                  style={{ color: "#4E4E4E" }}
                >
                  Torna all'inizio
                </Buttontext>
              </div>
            </React.Fragment>
          )}
        </MayPWithSpinner>
      </div>
    );
  }
}

export default withRouter(RegistrationEnd);
