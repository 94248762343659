import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import MayPFormBuider from "../../common/components/utils/MayPFormBuilder";
import MayPDialog from "../../common/components/utils/MayPDialog";
import { connect } from "react-redux";
import styles from "./ExternalAccount.module.css";
import countries from "../login/countries";
import CFLib from "../../libs/cloudfunction";
import LockIcon from "../../common/components/assets/@MAYPAY_UIKIT_BUYER/mp_payments.svg";
import { Browser } from "@capacitor/browser";
import { withRouter } from "react-router-dom";
import MayPPageTemplate from "../../common/components/utils/MayPPageTemplate";

class ExternalAccount extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    console.log("redux", props);
    this.state = {
      formValid: false,
      loading: false,
      waiting: false,
      error: 0,
      errorMessage: "",
      formData: {},
      formItems: [],
      showAccount: true,
      bank_account: {},
      showDialog: false,
      dialogTitle: "",
      dialogMessage: "",
      dialogActions: [],
      dialogOnClose: {},
    };

    this.onContinue = this.onContinue.bind(this);
    this.onBack = this.onBack.bind(this);
    this.loadAccount = this.loadAccount.bind(this);
    this.getForm = this.getForm.bind(this);

    this.formRef = React.createRef();
  }

  onContinue() {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.page, "on-bankdata", {
        ...this.state.formData,
      });
    }
  }

  onBack() {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.page, "goto-home", {
        ...this.state.formData,
      });
    }
  }

  getForm(data, validate = true) {
    return [
      {
        type: "text",
        label: "Titolare del conto",
        name: "bank_owner",
        defaultValue: data.bank_owner,
        wrapperClass: "form-data w100",
        nextFocus: "bank_country",
      },
      {
        type: "select",
        label: "Stato",
        name: "bank_country",
        defaultValue: data.bank_country,
        wrapperClass: "form-data w50",
        nextFocus: "bank_currency",
        values: countries,
      },
      {
        type: "select",
        label: "Valuta",
        name: "bank_currency",
        defaultValue: data.bank_currency.toUpperCase(),
        wrapperClass: "form-data w50",
        nextFocus: "bank_iban",
        values: [
          { label: "EUR", value: "EUR" },
          { label: "USD", value: "USD" },
          { label: "GBP", value: "GBP" },
          { label: "SEK", value: "SEK" },
          { label: "NOK", value: "NOK" },
          { label: "DKK", value: "DKK" },
          { label: "CHF", value: "CHF" },
        ],
      },
      {
        type: "text",
        label: "IBAN",
        name: "bank_iban",
        defaultValue: data.bank_iban,
        wrapperClass: "form-data w100",
        validation: validate
          ? [
              {
                re: /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/,
                message: "IBAN non valido",
              },
            ]
          : [],
      },
    ];
  }

  loadAccount() {
    this.setState({
      loading: true,
    });
    const cfLib = new CFLib();
    cfLib.getStripeExternalAccount().then((res) => {
      console.log("Reading Stripe account");
      if (res == null || (res && !res.success)) {
        this.setState({
          loading: false,
          showDialog: true,
          dialogTitle: "Si è verificato un errore",
          dialogMessage: "Riprova più tardi.",
          dialogActions: ["OK"],
          dialogOnClose: async (action) => {
            if (action === "OK") {
              this.onBack();
            }
          },
        });
      }

      console.log(res);
      if (res.success && res.data.bank_account) {
        const data = {
          accountId: this.props.merchant.stripe_account_id || undefined,
          bank_owner: res.data.bank_account.account_holder_name,
          bank_currency: res.data.bank_account.currency,
          bank_country: res.data.bank_account.country,
          bank_iban: "XXXXXXXXXXXXXXXXXXXXX" + res.data.bank_account.last4,
        };
        const formItems = this.getForm(data, false);
        console.log("updating");
        this.setState({
          bank_account: data,
          loading: false,
          formItems: formItems,
        });
      }
    });
  }

  componentDidMount() {
    this.loadAccount();
  }

  render() {
    return (
      <MayPPageTemplate
        title="Preferenze di accredito"
        description="Qui puoi visualizzare o modificare i dati bancari necessari per ricevere i pagamenti."
      >
        <action>
          <div className={styles.actionContainer}>
            <MayPWithSpinner waiting={this.state.waiting}>
              <MayPButtonBlue
                disabled={false}
                onClick={() => {
                  // const formItems= this.getForm({
                  //   bank_owner: "",
                  //   bank_currency: "EUR",
                  //   bank_country: "IT",
                  //   bank_iban: ""
                  // }, true);
                  // this.setState({
                  //   showAccount:false,
                  //   formItems: formItems
                  // })
                  this.setState({
                    dialogTitle: "Modifica IBAN",
                    dialogMessage:
                      "Per modificare il tuo IBAN è necessario contattare l’assistenza clienti.",
                    dialogActions: ["Annulla", "Contattaci"],
                    dialogOnClose: async (action) => {
                      if (action === "Contattaci") {
                        await Browser.open({
                          url: "https://www.maypay.com/assistenza-esercenti/",
                        });
                        this.setState({
                          showDialog: false,
                        });
                      } else {
                        this.setState({
                          showDialog: false,
                        });
                      }
                    },
                    showDialog: true,
                  });
                }}
              >
                Modifica IBAN
              </MayPButtonBlue>
            </MayPWithSpinner>
          </div>
        </action>
        <content>
          <div className={styles.content}>
            <div className={styles.whiteBox}>
              <MayPWithSpinner waiting={this.state.loading}>
                <div style={{ minHeight: "200px" }}>
                  {!this.state.loading && this.state.showAccount && (
                    <div style={{ minHeight: "400px" }}>
                      <MayPFormBuider
                        ref={this.formRef}
                        items={this.state.formItems}
                        id="bankForm"
                        disableAll={true}
                        containerClass={styles.formContainer}
                      />
                      <div className={styles.secure}>
                        <div className={styles.secureBlue}>
                          <img
                            src={LockIcon}
                            style={{ width: "24px", height: "auto" }}
                            alt="pagamenti sicuri"
                          />{" "}
                          <div className={styles.blueText}>
                            Pagamenti sicuri
                          </div>
                        </div>
                      </div>
                      <div className={styles.info2}>
                        Tutti pagamenti sono processati da Stripe, leader
                        globale dei pagamenti digitali.
                      </div>
                      <div
                        style={{
                          marginTop: "40px",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <MayPDialog
                          forMerchant={true}
                          open={this.state.showDialog}
                          title={this.state.dialogTitle}
                          message={this.state.dialogMessage}
                          actions={this.state.dialogActions}
                          onClose={this.state.dialogOnClose}
                        />
                      </div>
                      {/* <div className="login-page-back">
                        <ButtonBack
                          onClick={this.onBack}
                          disabled={this.state.loading}
                        >
                          Torna alla home
                        </ButtonBack>
                      </div> */}
                    </div>
                  )}

                  {!this.state.loading && !this.state.showAccount && (
                    <div>
                      <MayPFormBuider
                        ref={this.formRef}
                        items={this.state.formItems}
                        id="bankForm"
                        disableAll={this.state.waiting}
                        onValid={(v, data) => {
                          console.log(data);
                          this.setState({
                            formValid: v,
                            formData: data,
                          });
                        }}
                        containerClass={styles.formContainer}
                      />
                      <div className={styles.secure}>
                        <div className={styles.secureBlue}>
                          <img
                            src={LockIcon}
                            style={{ width: "24px", height: "auto" }}
                            alt="pagamenti sicuri"
                          />{" "}
                          <div className={styles.blueText}>
                            Pagamenti sicuri
                          </div>
                        </div>
                      </div>
                      <div className={styles.info2}>
                        Tutti pagamenti sono processati da Stripe, leader
                        globale dei pagamenti digitali.
                      </div>
                      <div
                        style={{
                          marginTop: "40px",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        <MayPWithSpinner waiting={this.state.waiting}>
                          <MayPButtonBlue
                            disabled={
                              this.state.waiting || !this.state.formValid
                            }
                            onClick={async () => {
                              this.setState({
                                waiting: true,
                              });
                              const cfLib = new CFLib();
                              const res = await cfLib.setStripeExternalAccount({
                                accountId:
                                  this.props.merchant.stripe_account_id ||
                                  undefined,
                                ...this.state.formData,
                              });
                              console.log(res);
                              if (res.success) {
                                this.setState(
                                  {
                                    waiting: false,
                                    showAccount: true,
                                  },
                                  () => {
                                    this.loadAccount();
                                  }
                                );
                              }
                            }}
                          >
                            Salva
                          </MayPButtonBlue>
                          {/* <div className={styles.separator}></div>
                      <ButtonBack
                        onClick={() => {
                          const formItems = this.getForm(
                            this.state.bank_account,
                            false
                          );
                          this.setState({
                            showAccount: true,
                            formItems: formItems,
                          });
                        }}
                        disabled={this.state.waiting}
                      >
                        Annulla
                      </ButtonBack> */}
                        </MayPWithSpinner>
                      </div>
                    </div>
                  )}
                </div>
              </MayPWithSpinner>
            </div>
          </div>
        </content>
      </MayPPageTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user || { profile: {} },
  };
};

export default connect(mapStateToProps, null)(withRouter(ExternalAccount));
