import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import styles from "./Probability.module.css";
import { Browser } from "@capacitor/browser";
import { withRouter } from "react-router-dom";
import MayPPageTemplate from "../../common/components/utils/MayPPageTemplate";
import MayPProbabilityBadge from "../../common/components/utils/MayPProbabilityBadge"
import InfoIcon from '@material-ui/icons/Info';
import { IconButton } from "@material-ui/core";
import UserLib from "../../libs/user"
import { getAuth } from "firebase/auth";
import MayPDialog from "../../common/components/utils/MayPDialog";
import { currencyFormatter } from "../../common/libs/utils";

class Probability extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      probability: null,
      settings: null,
      dialogSettings: {
        open: false,
        title: null,
        message: null,
        actions: [],
        onClose: null,
        probability: null
      },
      dialogValues: {
        fondoMontepremi: 0,
        fondoImposteMontepremi: 0,
        compensoMaypay: 0,
        iva: 0,
        total: 0
      },
      sampleProbabilitiesLeft: [
        { probability: 1000, fee: 0 },
        { probability: 300, fee: 0 },
        { probability: 200, fee: 0 },
        { probability: 100, fee: 0 }
      ],
      sampleProbabilitiesRight: [
        { probability: 50, fee: 0 },
        { probability: 25, fee: 0 },
        { probability: 20, fee: 0 },
        { probability: 10, fee: 0 }
      ]
    };
    this.userLib = new UserLib();
    this.onContinue = this.onContinue.bind(this);
    this.loadingProbability = false

  }

  async onContinue() {
    await Browser.open({
      url: "https://www.maypay.com/assistenza-esercenti/",
    });
  }

  async loadSettings() {
    let result = await this.userLib.getSettings();
    if (result.success) {
      this.setState({
        settings: result.data,
        sampleProbabilitiesLeft: this.state.sampleProbabilitiesLeft.map((p) => { return { probability: p.probability, fee: this.calculateTaxesPercentage(p.probability, result.data) } }),
        sampleProbabilitiesRight: this.state.sampleProbabilitiesRight.map((p) => { return { probability: p.probability, fee: this.calculateTaxesPercentage(p.probability, result.data) } })
      })
    }
  }

  calculateTaxesPercentage(probability, settings) {
    console.log(settings);
    const amount = 100 * 100
    // const total = (((amount / (probability)) * (1 + settings.maypay_tax_multiplier) + (settings.maypay_fee / 100 * amount) * (1 + settings.vat)) / 100)


    const total = (( Math.round(( Math.round(amount / (probability))) * (1 + (settings.maypay_tax_multiplier))) + Math.round((Math.round(settings.maypay_fee / 100 * amount)) * (1 + 0/*settings.vat*/)) ) / 100)
    return Math.round(total * 100) / 100
  }

  async loadProbability() {
    this.loadingProbability = true
    let result = await this.userLib.getProbability()
    if (result.success) {
      this.setState({ probability: result.data.probability })
    }
    this.loadingProbability = false
  }

  calculateTaxes(probability = null) {
    console.log(this.state.settings)
    const amount = 100 * 100

    let fondoMontepremi = Math.round(amount / (probability ?? this.state.dialogSettings.probability))
    fondoMontepremi += Math.round(fondoMontepremi * this.state.settings.maypay_tax_multiplier)
    const fondoImposteMontepremi = Math.round(fondoMontepremi * this.state.settings.maypay_tax_multiplier)
    const compensoMaypay = Math.round(amount * this.state.settings.maypay_fee / 100)
    const iva = Math.round(compensoMaypay * this.state.settings.vat)
    const total = fondoMontepremi + compensoMaypay + iva

    this.setState((prev) => {
      prev.dialogValues.fondoMontepremi = currencyFormatter.format(fondoMontepremi / 100)
      prev.dialogValues.fondoImposteMontepremi = currencyFormatter.format(fondoImposteMontepremi / 100)
      prev.dialogValues.compensoMaypay = currencyFormatter.format(compensoMaypay / 100)
      prev.dialogValues.iva = currencyFormatter.format(iva / 100)
      prev.dialogValues.total = currencyFormatter.format(total / 100)
      return {
        ...prev
      }
    })

    return total
  }

  componentDidMount() {
    if (getAuth().currentUser) {
      this.loadProbability()
      this.loadSettings()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.probability == null && getAuth().currentUser?.uid && !this.loadingProbability) {
      this.loadProbability()
      this.loadSettings()
    }
  }

  render() {
    return (
      <MayPPageTemplate
        title={
          <>
            <div className={styles.titleContainer}>
              <div>Probabilità attuale</div>
              <div className={styles.box}>
                <MayPProbabilityBadge style={{ "WebkitTextStroke": "0px", margin: 'auto 0' }} probability={this.state.probability}></MayPProbabilityBadge>
                <div className={styles.probabilityText}>1 su {this.state.probability}, tra tutti gli utenti Maypay, vince il proprio acquisto.</div>
              </div>
            </div>
          </>
        }
        description={
          <div>
            La probabilità di vincita può essere variata in base a logiche di marketing a
            discrezione dell'esercente <br />
            <br /> Per modificare la probabilità di vincita offerta ai tuoi
            clienti, contattaci.
          </div>
        }
      >
        <action>
          <div className={styles.actionContainer}>
            <MayPButtonBlue onClick={this.onContinue}>
              Contattaci
            </MayPButtonBlue>
          </div>
        </action>
        <content>
          <div className={styles.mainContainer}>
            <div className={styles.contentTitle}>Costi a operazione</div>
            <div className={styles.contentText}>
              L'importo della commissione è proporzionale <br /> alla probabilità scelta
            </div>
            <div className={styles.columnsContainer}>
              <div className={styles.column}>
                <div className={styles.columnTitle}>
                  Strumento Standard
                </div>
                <div className={[styles.box, styles.column].join(" ")}>
                  {this.state.sampleProbabilitiesLeft.map((elem, index) => {
                    return (
                      <div key={index} className={styles.feeRow}>
                        <MayPProbabilityBadge style={{ margin: 'auto 0', width: '120px' }} probability={elem.probability}></MayPProbabilityBadge>
                        <div>Commissione:</div>
                        <div className={styles.feeValue}>{elem.fee || '-,--'}%</div>
                        <IconButton onClick={() => {
                          this.setState((prev) => {
                            prev.dialogSettings.open = true;
                            prev.dialogSettings.probability = elem.probability;
                            return {
                              ...prev
                            }
                          }, () => {
                            this.calculateTaxes()
                          });
                        }}><InfoIcon style={{ color: "#00a2e0" }} /></IconButton>
                      </div>)
                  })}
                </div>
              </div>
              <div className={styles.column}>
                <div className={styles.columnTitle}>
                  Campagne Mirate
                </div>
                <div className={[styles.box, styles.column].join(" ")}>
                  {this.state.sampleProbabilitiesRight.map((elem, index) => {
                    return (
                      <div key={index} className={styles.feeRow}>
                        <MayPProbabilityBadge style={{ margin: 'auto 0', width: '120px' }} probability={elem.probability}></MayPProbabilityBadge>
                        <div>Commissione:</div>
                        <div className={styles.feeValue}>{elem.fee || '-,--'}%</div>
                        <IconButton onClick={() => {
                          this.setState((prev) => {
                            prev.dialogSettings.open = true;
                            prev.dialogSettings.probability = elem.probability;
                            return {
                              ...prev
                            }
                          }, () => {
                            this.calculateTaxes()
                          });
                        }}><InfoIcon style={{ color: "#00a2e0" }} /></IconButton>
                      </div>)
                  })}
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              Servizio di Pagamento:
              <div className={styles.footerValues}>1,25% +0,20 €</div>
            </div>

          </div>

          <MayPDialog
            forMerchant={true}
            showCloseButton={true}
            open={this.state.dialogSettings.open}
            title={
              <>
                <div className={styles.rowBlue} style={{ margin: '0 1.3em', padding: '0', transform: 'translateY(8px)' }}>
                  <div className={styles.dialogTitle}>Dettagli</div>
                  <MayPProbabilityBadge style={{ margin: 'auto 0', "WebkitTextStroke": "0px" }} probability={this.state.dialogSettings.probability}></MayPProbabilityBadge>
                </div>
              </>
            }
            message={
              <>
                <div ref={this.ref} className={styles.container}>
                  <div className={styles.rowContainer}>

                    <div className={styles.rowBlue} style={{ padding: '0 0 1em 0' }}>
                      <div className={styles.label0}>Importo d'esempio</div>
                      <div className={styles.value0}>
                        100,00 €
                      </div>
                    </div>
                    <div className={styles.row} style={{ paddingTop: '1em' }}>
                      <div className={styles.label2}>Fondo Montepremi + Imposte 45%</div>
                      <div className={styles.value1}>
                        {this.state.dialogValues.fondoMontepremi} €
                      </div>
                    </div>
                    {/* <div className={styles.row}>
                      <div className={styles.label2}>Fondo Imposte Montepremi</div>
                      <div className={styles.value1}>
                        {this.state.dialogValues.fondoImposteMontepremi} €
                      </div>
                    </div> */}
                    <div className={styles.row}>
                      <div className={styles.label2}>Compenso Maypay*</div>
                      <div className={styles.value1}>
                        {this.state.dialogValues.compensoMaypay} €
                      </div>
                    </div>
                    <div className={styles.rowBlue} style={{ paddingBottom: '1em' }}>
                      <div className={styles.label2}>Iva 22% sulle operazioni imponibili</div>
                      <div className={styles.value1}>
                        {this.state.dialogValues.iva} €
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.label3}>*Operazione imponibile</div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.label0}>Totale commissione</div>
                      <div className={styles.value0}>
                        {this.state.dialogValues.total} €
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
            actions={this.state.dialogSettings.actions}
            onClose={() => {
              this.setState((prev) => {
                prev.dialogSettings.open = false;
                return {
                  ...prev
                }
              })
            }}
          />
        </content>

      </MayPPageTemplate>
    );
  }
}

export default withRouter(Probability);
