import React from "react";
import MayPAppBar from "../common/components/MayPAppBar/MayPAppBar";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./Home";
import MayPDialog from "../common/components/utils/MayPDialog";
/*Icon files*/
import MenuIc from "../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_hamb_menu.white.svg";
import MenuDrawer from "./MenuDrawer";
import { connect } from "react-redux";
import {
  updateProfile,
  clearData,
} from "../redux/actions/user";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";

import { Browser } from "@capacitor/browser";
import Probability from "./menudrawer/Probability";
import Transazioni from "./menudrawer/Transazioni";
import Documents from "./menudrawer/Documents";
import ExternalAccount from "./menudrawer/ExternalAccount";
import MenuDatiAziendali from "./menudrawer/MenuDatiAziendali";
import Developers from "./developers";
import MayPWithSpinner from "../common/components/utils/MayPWithSpinner";
import Stores from "./stores";
import ProfileStore from "./storeDrawer/profileStore";
import MovimentiStripe from "./menudrawer/transactions/MovimentiStripe";
import { Routes } from "../common/libs/utils";
import { withWidth } from "@material-ui/core";

const MainLoading = () => {
  return (
    <MayPWithSpinner waiting={true}>
      <div
        style={{
          width: "100vw",
          height: "100vh",
        }}
      ></div>
    </MayPWithSpinner>
  );
};

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.showMainMenu = this.showMainMenu.bind(this);
    this.onHomeAction = this.onHomeAction.bind(this);
    this.getAppState = this.getAppState.bind(this);
    this.getMerchant = this.getMerchant.bind(this);

    this.state = {
      appState: undefined,
      showAppBar: true,
      showMainMenu:
        this.props.width === "xs" ||
        this.props.width === "sm" ||
        this.props.width === "md"
          ? false
          : this.props.width === "lg" || this.props.width === "xl"
          ? true
          : false,
      showMenuButton: true,
      storeLoaded: false,
      appBarTitle: "",
      currentTransaction: null,
      showPageDrawer: false,
      pageDrawerContent: <div />,
      pageDrawerName: undefined,
      showUpdate: false,
      // currentPage: "Home",
      unreadNotifications: 0,
      currentMerchant: null,
      openDialog: false,
      dialogTitle: "",
      dialogMessage: "",
      dialogActions: [],
    };
    this.udRef = React.createRef();
    this.pdRef = React.createRef();
    this.menuRef = React.createRef();
    this.homeRef = React.createRef();
    this.reqRef = React.createRef();

    console.log("CurrentUser Constructor", getAuth().currentUser);
  }

  async getAppState(state) {
    let appState = "foreground";

    if (!state.isActive) {
      appState = "background";
    }
    if (this.state.appState !== appState) {
      this.setState({
        appState: appState,
        appBarTitle: this.props.location.pathname.includes("Cassa")
          ? "Cassa"
          : this.state.appBarTitle,
      });
    }
  }
  showMainMenu(show, title, others = {}, fn = null) {
    this.setState(
      {
        showMainMenu: show,
        showAppBarTitle: show,
        showMenuButton: !show,
        ...others,
      },
      () => {
        if (fn) {
          fn();
        }
      }
    );
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getMerchant();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user !== this.props.user && !this.state.currentMerchant) {
      this.getMerchant();
    }

    if (prevProps.width === "lg" && this.props.width === "md") {
      this.setState({
        showMainMenu: false,
      });
    } else if (prevProps.width === "md" && this.props.width === "lg") {
      this.setState({
        showMainMenu: true,
      });
    }
  }

  async getMerchant() {
    this.db = getFirestore();

    const result = await getDoc(
      doc(collection(this.db, "merchants"), this.props.user.uid)
    );

    console.log(result);
    if (result.exists()) {
      console.log("currentMerchant", result.data());
      const data = result.data();
      this.setState({
        currentMerchant: data,
      });
    } else {
      this.setState({
        openDialog: true,
        dialogActions: ["Contattaci"],
        dialogTitle: "Si è verificato un errore",
        dialogMessage: "Si è verificato un problema con il tuo account",
        onDialogClose: async (action) => {
          console.log(action);
          if (action === "Contattaci") {
            this.setState({
              openDialog: false,
            });
            await Browser.open({
              url: "https://www.maypay.com/assistenza-esercenti/",
            });
          }
        },
      });
    }
  }

  onHomeAction(action, data) {
    console.log(action, data);
    if (action === "addFirstStore") {
      this.props.history.push(Routes.AddStore);
    } else if (action === "onStoreClick") {
      this.props.history.push(Routes.Store + data.storeId);
    }
  }

  render() {
    return (
      <React.Fragment>
        <MayPAppBar
          bgColor="#FFFFFF"
          defaultColor="#FFFFFF"
          elevation={4}
          height="60px"
          hideonscroll={false}
          avatarName={this.state.avatarName}
          avatarURL={null}
          appBarTitle={this.state.appBarTitle}
          showNotificationButton={this.state.showMenuButton}
          showAppBar={this.state.showAppBar}
          onMenuClick={() => {
            console.log("Menu clicked");
            if (this.menuRef && this.menuRef.current) {
              this.menuRef.current.onBack(true);
            }
            this.showMainMenu(!this.state.showMainMenu, "Menu");
          }}
          onAvatarClick={() => {
            // console.log("Avatar clicked");
            // this.showMainMenu(false, "");
          }}
          rightIcon={MenuIc}
          notifications={0}
          notificationAvatar={0}
          ios={true}
        />

        <MenuDrawer
          open={this.state.showMainMenu}
          onClose={() => {
            console.log("main menu closed");
            this.showMainMenu(false, "");
          }}
          onAction={async (action, page, data = undefined) => {
            console.log("clicked", action, page, data);
            if (action === "logout") {
              signOut(getAuth())
                .then(async () => {
                  console.log("signed out");
                  this.props.clearUserProfile();
                })
                .catch((e) => {
                  console.log(e);
                });
            } else if (action === "on-back") {
              if (data === 0) {
                this.showMainMenu(false, "");
              }
            } else if (action === "on-page") {
              console.log("ON PAGE", action, page, data);
              this.setState({
                appBarTitle: data,
                // currentPage: data
              });
              this.props.history.push("/main/" + data);

              if (
                this.state.showMainMenu &&
                (this.props.width === "xs" ||
                  this.props.width === "sm" ||
                  this.props.width === "md")
              ) {
                this.setState({
                  showMainMenu: false,
                });
              }
            } else if (action === "on-account-contact") {
              //open link
            } else if (action === "goto-home") {
              console.log("closing menu...");

              this.setState({
                // currentPage: "Home",
                appBarTitle: "",
              });
              this.props.history.push("/main/home");
            } else if (action === "goto-cassa") {
              console.log("closing menu...");
              this.setState({
                // currentPage: "Cassa",
                appBarTitle: "Cassa",
              });
              this.props.history.push("/main/Cassa");
            }
          }}
        >
          <Switch>
            <Route
              exact
              path="/main"
              render={() => {
                window.scroll(0, 0);
                this.props.history.replace(Routes.Home);
                return <MainLoading />;
              }}
            />
            <Route
              path={Routes.Home}
              render={() => {
                console.log(getAuth().currentUser, this.state.currentMerchant);
                if (getAuth().currentUser && this.state.currentMerchant) {
                  window.scroll(0, 0);
                  return (
                    <Home
                      ref={this.homeRef}
                      onAction={this.onHomeAction}
                      storeLoaded={true}
                      merchant={this.state.currentMerchant}
                    />
                  );
                }
                return <MainLoading />;
              }}
            />
            <Route path={Routes.Probability} children={<Probability />} />
            <Route
              path={Routes.Transactions}
              render={() => {
                if (getAuth().currentUser) {
                  window.scroll(0, 0);
                  return <Transazioni />;
                }
                return <MainLoading />;
              }}
            />
            <Route
              path={Routes.Documents}
              render={() => {
                if (getAuth().currentUser) {
                  window.scroll(0, 0);
                  return <Documents/>;
                }
                return <MainLoading />;
              }}
            />
            <Route
              path={Routes.Credits}
              render={() => {
                if (getAuth().currentUser) {
                  window.scroll(0, 0);
                  return <MovimentiStripe />;
                }
                return <MainLoading />;
              }}
            />
            <Route
              path={Routes.Preferences}
              render={() => {
                if (getAuth().currentUser && this.state.currentMerchant) {
                  window.scroll(0, 0);
                  return (
                    <ExternalAccount merchant={this.state.currentMerchant} />
                  );
                }
                return <MainLoading />;
              }}
            />
            <Route
              path={Routes.Account}
              render={() => {
                if (getAuth().currentUser && this.state.currentMerchant) {
                  console.log(this.props.user);
                  window.scroll(0, 0);
                  return (
                    <MenuDatiAziendali merchant={this.state.currentMerchant} />
                  );
                }
                return <MainLoading />;
              }}
            />
            <Route
              path={Routes.Developers}
              render={() => {
                if (getAuth().currentUser) {
                  window.scroll(0, 0);
                  return <Developers />;
                }
                return <MainLoading />;
              }}
            />
            <Route
              exact
              path={Routes.Stores}
              render={() => {
                if (getAuth().currentUser) {
                  window.scroll(0, 0);
                  return (
                    <Stores
                      onStoreClick={(storeData) => {
                        this.props.history.push(Routes.Store + storeData._id);
                      }}
                      onAddStore={() => {
                        this.props.history.push(Routes.AddStore);
                      }}
                    />
                  );
                }
                return <MainLoading />;
              }}
            />
            <Route
              path={Routes.Store}
              render={() => {
                if (getAuth().currentUser) {
                  window.scroll(0, 0);
                  return (
                    <ProfileStore
                      onSubmit={async (action, data) => {
                        if (action === "on-store-added") {
                          this.props.history.push(
                            Routes.Store + data.newStoreId
                          );
                          window.location.reload();
                        } else if (action === "on-back") {
                          this.props.history.push(Routes.Stores);
                        }
                      }}
                    />
                  );
                }
                return <MainLoading />;
              }}
            />
          </Switch>
        </MenuDrawer>

        <MayPDialog
          forMerchant={true}
          open={this.state.showUpdate}
          actions={["ok"]}
          title="Aggiorna maypay"
          message="Chiudi e riapri l'app per aggiornare maypay!"
          onClose={(action) => {
            if (action === "ok") {
              this.setState({ showUpdate: false }, () => {
                if ("serviceWorker" in navigator) {
                  navigator.serviceWorker
                    .getRegistration()
                    .then((reg) => {
                      if (reg.waiting) {
                        reg.waiting.postMessage({ type: "SKIP_WAITING" });
                        window.location.reload();
                      }
                    })
                    .catch((err) =>
                      console.log("Could not get registration: ", err)
                    );
                }
              });
            }
          }}
        >
          <div
            style={{
              backgroundColor: "#00A2E0",
              color: "#ffffff",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            Una nuova versione di maypay è disponibile
          </div>
        </MayPDialog>
        <MayPDialog
          forMerchant={true}
          open={this.state.openDialog}
          title={this.state.dialogTitle}
          message={this.state.dialogMessage}
          actions={this.state.dialogActions}
          onClose={this.state.onDialogClose}
          maxWidth={"xs"}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // app: state.app,
    user: state.user,
    // store: state.store,
  };
};

const dipatchPropsToState = (dispatch) => {
  return {
    updateUserProfile: (profile) => {
      dispatch(updateProfile(profile));
    },
    // updateStore: (store, photoPath, photoUrl) => {
    //   dispatch(updateStore(store, photoPath, photoUrl));
    // },
    clearUserProfile: () => {
      dispatch(clearData());
    },
    // clearStoreData: () => {
    //   dispatch(clearStoreData());
    // },
    // setCurrentTransaction: (currentTransaction) => {
    //   dispatch(setCurrentTransaction(currentTransaction));
    // },
  };
};

const _MainPage = withWidth()(MainPage);

export default connect(
  mapStateToProps,
  dipatchPropsToState
)(withRouter(_MainPage));
