import React from "react";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "../assets/@MAYPAY_UIKIT_BUYER/mp_search_bar.svg";
import { withStyles } from "@material-ui/core/styles";

const _stylePaper = {
  root: {
    textTransform: "none",
    padding: "2px 2px",
    minWidth: "240px",
    width: "inherit",
    color: "##B2B2B2",
    borderRadius: "30px",
    textDecoration: "none",
    boxShadow: "0 5px 15px #8F95A36B",
    fontFamily: '"MavenPro Regular", Arial, sans-serif',
    fontWeight: "normal",
    fontSize: "14px",
    letterSpacing: "0px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    height: "auto",
  },
};
const _styleInput = {
  disabled: {
    backgroundImage: "linear-gradient(to bottom,#f4f4f4, #e5e5e5)",
    color: "#606060",
  },
  root: {
    flex: 1,
  },
  input: {
    padding: 0,
    paddingLeft: "20px",
  },
};
const _styleIcon = {
  root: {
    padding: "10px",
  },
};

const CInput = withStyles(_styleInput)(InputBase);
const CIcon = withStyles(_styleIcon)(IconButton);
const CRoot = withStyles(_stylePaper)(Paper);

class MayPSearchInput extends React.Component {
  constructor(props) {
    super(props);
    const { classes, other } = props;
    this.classes = classes;
    this.props = other;
    this.inputRef = React.createRef();
    this.state = {
      text: "",
    };
  }

  render() {
    return (
      <CRoot>
        <CInput
          inputRef={this.inputRef}
          placeholder={this.props.placeholder}
          inputProps={{ "aria-label": this.props.placeholder }}
          value={this.state.text}
          onChange={(e) => {
            this.setState({
              text: e.target.value,
            });
            if (
              e.target.value === "" &&
              this.props.onCancel &&
              this.inputRef.current
            ) {
              this.props.onCancel();
            }
          }}
        />
        {this.state.text !== "" && (
          <CIcon
            aria-label="search"
            onClick={() => {
              console.log(this.inputRef.current);
              this.setState({
                text: "",
              });

              if (this.props.onCancel && this.inputRef.current) {
                this.props.onCancel();
              }
            }}
          >
            <CancelIcon />
          </CIcon>
        )}
        <CIcon
          aria-label="search"
          onClick={() => {
            if (this.props.onSearch && this.inputRef.current) {
              this.props.onSearch(this.state.text);
            }
          }}
        >
          <img src={SearchIcon} alt="search" />
        </CIcon>
      </CRoot>
    );
  }
}
export default MayPSearchInput; //(MayPButtonBase)
