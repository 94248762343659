const initpreferenceState = {
  showPresentation: true,
  keepView: false,
  currentView: undefined,
};

function preferences(state = initpreferenceState, action) {
  switch (action.type) {
    case "SET_PRESENTATION_VISIBILITY":
      return Object.assign({}, state, { showPresentation: action.show });
    case "SET_KEEP_VIEW":
      return Object.assign({}, state, { keepView: action.keep });
    case "SET_CURRENT_VIEW":
      return Object.assign({}, state, { currentView: action.currentView });
    default:
      return state;
  }
}

export default preferences;
