import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withStyles } from "@material-ui/core/styles";

const _style = {
  root: {
    textTransform: "none",
    backgroundImage: "linear-gradient(to bottom,#00a2e0, #007eae)",
    padding: "1.2em",
    minWidth: "240px",
    color: "#ffffff",
    borderRadius: "40px",
    textDecoration: "none",
    boxShadow: "0 15px 45px #68686847",
    fontFamily: '"Giorgio", Arial, sans-serif',
    fontWeight: "normal",
    fontSize: "16px",
    letterSpacing: "2.4px",
    lineHeight: "29px",
    "WebkitTextStroke": "0.7px #FFFFFF",
  },
  disabled: {
    backgroundImage: "linear-gradient(180deg, #E7E7E7 0%, #B0AFAF 100%)",
    boxShadow: "0 15px 45px #68686830",
    color: "#606060",
    "WebkitTextStroke": "0.7px #606060",
  },
};

class MayPButtonBase extends React.Component {
  constructor(props) {
    super(props);
    const { classes, other } = props;
    this.classes = classes;
    this.props = other;
  }

  render() {
    return <ButtonBase className={this.classes.root} {...this.props} />;
  }
}

const wrap = (props) => {
  let style = {
    root: {},
    disabled: {},
    focusVisible: {},
  };
  style.root = { ...(_style.root || {}), ...(props.root || {}) };
  style.disabled = { ...(_style.disabled || {}), ...(props.disabled || {}) };
  style.focusVisible = {
    ...(_style.focusVisible || {}),
    ...(props.focusVisible || {}),
  };

  //Object.assign(style, _style, props);
  return withStyles(style)(MayPButtonBase);
};

export default wrap({}); //(MayPButtonBase)

export { wrap };
