import React, { useEffect, useRef, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import styles from "./MayPTable.module.css";
import { v4 as uuidv4 } from "uuid";
import { Box, Checkbox, Tooltip, makeStyles } from "@material-ui/core";
import MayPCheck from "./MayPCheck";

const useStyles = makeStyles((theme) => ({
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export default function MayPDesktopTable(props) {
  const tableRef = useRef();
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState(props.selectedRows || props.data.map((d) => {return false}))
  useEffect(() => {
    setSelectedRows(props.selectedRows)
  }, [props.selectedRows]) 
 
 
  return (
    <>
      {
        /*props.loading  && */ // LOADING
        <Box>
          {/* <Skeleton
            style={{
              backgroundColor: "var(--secondary-color)",
              height: "7vw",
              width: "100%",
              marginTop: "-2.5vw",
            }}
          /> */}
          {Array(props.rowsPerPage || 10)
            .fill("")
            .map((a, index) => {
              return (
                // <Skeleton
                //   key={index}
                //   style={
                //     props.skeletonRowsVariant &&
                //     props.skeletonRowsVariant === "simple"
                //       ? {
                //           height: "7vw",
                //           width: "100%",
                //           marginTop: "-2.5vw",
                //         }
                //       : {
                //           height: "7vw",
                //           width: "100%",
                //           marginTop: "-2.5vw",
                //         }
                //   }
                // />
                <></>
              );
            })}
        </Box>
      }

      {
        /*!props.loading &&*/ // NOT LOADING
        <>
          {/* {props.data.length === 0 && (
            // EMPTY ICON HOVER TABLE
            <div className={"noDataContainer"}>
              <img
                src={"/icons/no_data.png"}
                className={"noDataIcon"}
                alt="nodata"
              />
            </div>
          )} */}
          <TableContainer
            component={Box}
            style={{
              padding: "0 24px",
              boxSizing: "border-box",
            }}
          >
            <Table>
              <TableHead>
                <TableRow ref={tableRef} key="header">
                  {props.tableHeaders.map((header) => {
                    return (
                      <TableCell
                        key={typeof header !== String ? uuidv4() : header}
                        variant="head"
                        align="center"
                        style={{
                          color: "var(--primary-color)",
                          fontFamily: "Giorgio",
                          WebkitTextStroke: "0.5px var(--primary-color)",
                          padding: "1vw",
                          fontSize: "16px",
                        }}
                      >
                        {header}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              {props.data.length === 0 && (
                // EMPTY TABLE BODY
                <TableBody
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {new Array(8).fill(0).map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        style={{
                          "&:nth-of-type(odd)": {
                            backgroundColor: "#f2f2f2",
                          },
                          "&:nth-of-type(odd):hover": {
                            backgroundColor: "#e6f8ff",
                          },
                          "&:nth-of-type(even):hover": {
                            backgroundColor: "#e6f8ff",
                          },
                        }}
                      >
                        {props.dataKeys.map((key) => {
                          return (
                            <TableCell
                              key={`${index}-${key}`}
                              style={{ padding: "2% 0" }}
                            ></TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

              {props.data.length > 0 && (
                // TABLE BODY WITH DATA
                <TableBody>
                  {props.data.map((row, index) => {
                    const { key, __v, ...content } = row;
                    return (
                      <TableRow
                        key={key}
                        onDrag={(e) => e.stopPropagation()}
                        onClick={props.onClick}
                        hover={true}
                        classes={{ hover: classes.hover }}
                      >
                        {props.dataKeys.map((key) => {
                          const style = {
                            fontSize: "16px",
                            // wordWrap: "normal",
                            padding: "0.5em 0.1em",
                            // textDecoration: content.deleted
                            //   ? "line-through"
                            //   : "",
                          };
                          return (
                            <TableCell
                              key={`${index}-${key}`}
                              id={`${index}-${key}`}
                              align="center"
                              style={
                                props.onRowClick
                                  ? {
                                    ...style,
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }
                                  : style
                              }
                            >
                              {key !== "avatar" && key !== "displayId" && key !== "selected" && (
                                content[key]
                              )}
                              {key === "avatar" && (
                                <div>
                                  {content.avatar && (
                                    <img
                                      // id={
                                      //   (content.disabled !== undefined &&
                                      //     content.disabled) ||
                                      //   (content.deleted !== undefined &&
                                      //     content.deleted) ||
                                      //   (content.enabled !== undefined &&
                                      //     !content.enabled)
                                      //     ? "disabledPic"
                                      //     : ""
                                      // }
                                      src={content[key]}
                                      className={styles.avatarIcon}
                                      alt="avatarIcon"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        const e = {
                                          target: {
                                            id: `${index}-${key}`,
                                          },
                                        };
                                        if (props.onClick) {
                                          props.onClick(e);
                                        }
                                      }}
                                    // onError={({ currentTarget }) => {
                                    //   currentTarget.onerror = null;
                                    //   currentTarget.src =
                                    //     "/icons/avatar_default_dark.png";
                                    // }}
                                    />
                                  )}
                                  {!content.avatar && content.avatarName && (
                                    <div className={styles.avatarName}>
                                      {content.avatarName}
                                    </div>
                                  )}
                                </div>
                              )}

                              {key === "displayId" && (
                                <Tooltip
                                  title={content[key]}
                                  arrow
                                  id={`${index}-${key}`}
                                >
                                  <p>{content[key].substring(0, 5) + "..."}</p>
                                </Tooltip>
                              )}
                              {
                                key === "selected" && (
                                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                  <MayPCheck 
                                  appearance="radio" 
                                  checked={selectedRows[index] === true} 
                                  onChange={(isChecked) => {
                                    setSelectedRows((prev) => {
                                      prev[index] = !prev[index];
                                      return [...prev];
                                    })

                                    if (props.onSelected) {
                                      props.onSelected(index, isChecked)
                                    }
                                  }}
                                  />
                                  </div>
                                )
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      }
    </>
  );
}
