import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Cropper from "react-easy-crop";
import { connect } from "react-redux";
import SubmitIc from "../../assets/icons/mp_check_big.svg";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

class Profile extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      imageSrc: undefined,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      minZoom: 1,
      maxZoom: 1,
      selecting: false,
    };

    this.onCropComplete = this.onCropComplete.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.createCroppedImage = this.createCroppedImage.bind(this);
    this.takePicture = this.takePicture.bind(this);

    this.canvas = React.createRef();
    this.croppedImgData = undefined;
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    this.createCroppedImage(croppedAreaPixels);
  };

  createCroppedImage(croppedAreaPixels) {
    console.log(this.img);
    const ctx = this.canvas.current.getContext("2d");
    const img = document.getElementById("crop-image-src");
    if (img) {
      console.log("cropping");
      ctx.clearRect(0, 0, 256, 256);
      ctx.drawImage(
        img,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        256,
        256
      );
      this.croppedImgData = this.canvas.current.toDataURL();
    } else {
      console.log("img not loaded");
    }
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  async onFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      this.setState({
        imageSrc: imageDataUrl,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1,
        minZoom: 0.5,
        maxZoom: 3,
      });
    }
  }

  async takePicture() {
    try {
      this.setState(
        {
          selecting: true,
        },
        async () => {
          setTimeout(() => {
            this.setState({
              selecting: false,
            });
          }, 500);

          const image = await Camera.getPhoto({
            quality: 100,
            source: CameraSource.Photos,
            resultType: CameraResultType.DataUrl,
            promptLabelPhoto: "Scegli foto",
            promptLabelPicture: "Scatta foto",
          });

          if (image.dataUrl) {
            this.setState({
              imageSrc: image.dataUrl,
              crop: { x: 0, y: 0 },
              zoom: 1,
              aspect: 1,
              minZoom: 0.5,
              maxZoom: 3,
            });
          }
        }
      );
    } catch (err) {
      console.log("Something went wrong with photo", err);
    }
  }

  render() {
    return (
      <div className="store-content">
        <div className="store-crop-container">
          <canvas
            ref={this.canvas}
            width="256"
            height="256"
            style={{
              position: "absolute",
              zIndex: "100",
              top: "100px",
              backgroundColor: "#ffffff",
              display: "none",
            }}
          ></canvas>
          <Cropper
            image={this.state.imageSrc}
            crop={this.state.crop}
            zoom={this.state.zoom}
            zoomSpeed={0.5}
            minZoom={this.state.minZoom}
            maxZoom={this.state.maxZoom}
            aspect={this.state.aspect}
            cropShape="round"
            showGrid={true}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
            restrictPosition={false}
            cropSize={{ width: 256, height: 256 }}
            mediaProps={{ id: "crop-image-src" }}
          />
          <div className="crop-select">
            <IconButton
              className="avatar-button"
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={this.takePicture}
              disabled={this.state.selecting}
            >
              <PhotoCamera />
            </IconButton>
          </div>
          <div className="crop-submit">

            <IconButton
              className="avatar-button submit"
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => {
                if (this.props.onSubmit) {
                  console.log("submit avatar pic");
                  this.props.onSubmit(
                    "submit-profile-pic",
                    this.croppedImgData
                  );
                }
              }}
            >
              <img alt="submit" src={SubmitIc} />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cache: state.user || { phone: "", profile: {} },
  };
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default connect(mapStateToProps, null)(Profile);
