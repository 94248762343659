import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import CustomIcon from "../utils/CustomIcon";
import LogoIc from "../../../assets/maypay_logo_white_merchant.svg"
import { withRouter } from "react-router-dom";
import { Routes } from "../../libs/utils";

function HideOnScroll(props, ref) {
  const trigger = useScrollTrigger();
  if (props.hideonscroll) {
    return (
      <Slide
        nodeRef={props.nodeRef}
        appear={false}
        direction="down"
        in={!trigger}
        timeout={props.timeout}
      >
        {props.children}
      </Slide>
    );
  } else {
    return props.children;
  }
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

// const AvatarIcon = React.memo(
//   function AvatarIcon(props) {
//     /* renderizza utilizzando le props */
//     return (
//       <span>
//         <IconButton
//           edge="start"
//           onClick={() => {
//             if (props.onAvatarClick) {
//               props.onAvatarClick();
//             }
//           }}
//         >
//           <NotificationBadge
//             badgecolor="#f57a55"
//             overlap="circular"
//             anchorOrigin={{
//               vertical: "top",
//               horizontal: "right",
//             }}
//             variant="dot"
//             edge="end"
//             notificationnum={props.notificationAvatar}
//           >
//             <Avatar
//               style={{
//                 boxShadow: "0px 5px 15px #0000004D",
//               }}
//               alt="Profile picture"
//               src={props.avatarURL || ""}
//             >
//               {props.avatarName}
//             </Avatar>
//           </NotificationBadge>
//         </IconButton>
//       </span>
//     );
//   },
//   function isTheSame(prevProps, nextProps) {
//     if (
//       prevProps.showBackButton !== nextProps.showBackButton ||
//       prevProps.notifications !== nextProps.notifications ||
//       prevProps.avatarName !== nextProps.avatarName ||
//       prevProps.avatarURL !== nextProps.avatarURL ||
//       prevProps.notificationAvatar !== nextProps.notificationAvatar
//     ) {
//       return false;
//     }
//     return true;
//   }
// );

class MyPAppBar extends React.Component {
  static defaultProps = {
    showAppBar: true,
  };
  constructor(props) {
    super(props);
    this.props = props;
    this.ref = React.createRef();

    this.state = {
      elevation: props.elevation || 0,
      color: "default",
      notificationAvatar: props.notificationAvatar,
      notifications: props.notifications,
    };

    this.AppBarStyled = withStyles({
      root: {
        minWidth: "var(--min-width)",
        width: "100vw",
        right: "auto",

        height: this.props.height || "100px",
        margin: "0",
        "& .MuiIcon-root": {
          fontSize: "32px",
        },
        zIndex: "2000",
        boxShadow: "0px 3px 15px #00000029",
        marginTop: props.ios ? "env(safe-area-inset-top)" : "",
      },
      colorDefault: {
        background: "linear-gradient(to right, #00A2E0, #426CB4)", //this.props.defaultColor || "transparent",
        color: "#000000",
      },
      colorSecondary: {
        backgroundColor: this.props.bgColor || "#f2f2f2",
        color: "#000000",
      },
    })(AppBar);

    this.showUserNotification = this.showUserNotification.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  onScroll(evt) {
    if (window.pageYOffset > this.ref.current.offsetHeight) {
      this.setState({ color: "secondary", elevation: 4 });
    } else {
      this.setState({ color: "default", elevation: 0 });
    }
  }
  componentDidMount() {
    if (this.props.hideonscroll) {
      window.addEventListener("scroll", this.onScroll);
    }
    /*let img = new Image();
    try{
      img.onload = ()=> {
          console.log("avatar loaded");
          this.setState({
            img: <img src ={img.src} alt="avatar"/>
          })
      }
      img.src= this.props.avatarURL;
      console.log("Avatar url", this.props.avatarURL);
    }catch(e){
      console.log("error loading avatar",e.toString())
    }*/
  }
  componentWillUnmount() {
    if (this.props.hideonscroll) {
      window.removeEventListener("scroll", this.onScroll);
    }
  }

  showUserNotification() {
    this.setState({ notification: !this.state.notification });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.showAppBar && (
          <HideOnScroll nodeRef={this.ref} {...this.props}>
            <this.AppBarStyled
              ref={this.ref}
              color={this.state.color}
              elevation={this.state.elevation}
            >
              <Toolbar
                style={{
                  paddingLeft: "8px",
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={() => {
                    if (this.props.onMenuClick) {
                      this.props.onMenuClick();
                    }
                  }}
                >
                  <CustomIcon icon={this.props.rightIcon} />

                  {/* <NotificationBadge
                    badgecolor="#f57a55"
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    variant="dot"
                    edge="end"
                    notificationnum={this.props.notifications}
                    styletop="4px"
                    styleleft="10px"
                  >
                  </NotificationBadge> */}
                </IconButton>
                {/* {!this.props.showNotificationButton && (
                  <div style={{ width: "56px", height: "56px" }}></div>
                )} */}
                <div
                  className="logo-container"
                  onClick={() => {
                    // window.open("http://maypay.com", "_blank");
                    this.props.history.push(Routes.Home);
                  }}
                >
                  <img src={LogoIc} alt="logo_maypay" />
                </div>

                {/* <Typography style={{ flexGrow: 1, textAlign: "center" }}>
                  <span
                    className="app-bar-title"
                    style={{ visibility: this.props.showAppBarTitle }}
                  >
                    {this.props.appBarTitle}
                  </span>
                </Typography> */}

                {/* <AvatarIcon
                  notifications={this.props.notifications}
                  notificationAvatar={this.props.notificationAvatar}
                  avatarName={this.props.avatarName}
                  avatarURL={this.props.avatarURL}
                  onBackClick={this.props.onBackClick}
                  onAvatarClick={this.props.onAvatarClick}
                /> */}
              </Toolbar>
            </this.AppBarStyled>
          </HideOnScroll>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(MyPAppBar);
