import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import MayPFormBuider from "../../common/components/utils/MayPFormBuilder";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import { MayPButtonTextWrap } from "../../common/components/utils/MayPButtonText";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { withRouter } from "react-router-dom";


const Buttontext = MayPButtonTextWrap({
  root: {
    fontSize: "14px",
    letterSpacing: "1.4px",
    lineHeight: "22px",
    fontWeight: "normal",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #606060",
    color: "#606060",
  },
  disabled: {
    color: "#cccccc",
  },
});

class Registrazione1 extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;

    this.createUser = this.createUser.bind(this);
    this.checkPassword = this.checkPassword.bind(this);

    this.state = {
      formValid: false,
      email: "",
      password: "",
      password2: "",
      waiting: false,
      error: 0,
      errorMessage: "",
    };

    this.loginItems = [
      {
        type: "email",
        label: "Email",
        name: "email",
        defaultValue: "",
        wrapperClass: "login-input2",
        validation: [
          {
            re: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
            message: "E-mail non valida",
          },
        ],
        nextFocus: "password",
      },
      {
        type: "password",
        label: "Password",
        name: "password",
        defaultValue: "",
        wrapperClass: "login-input2",
        validation: [
          {
            re: /^[A-Za-z0-9\-&$%!.]{6,}$/,
            message:
              "La password deve essere di almeno 6 caratteri. Sono ammessi lettere numeri e i caratteri speciali ($ % . - ! &)",
          },
          {
            callback: this.checkPassword,
            message: "Le password non combaciano",
          },
        ],
        nextFocus: "password2",
      },
      {
        type: "password",
        label: "Conferma password",
        name: "password2",
        defaultValue: "",
        wrapperClass: "login-input2",
        validation: [
          {
            re: /^[A-Za-z0-9\-&$%!.]{6,}$/,
            message: "Le password non combaciano",
          },
          {
            callback: this.checkPassword,
            message: "Le password non combaciano",
          },
        ],
      },
    ];
  }

  createUser() {
    let m = "";
    let isError = 0;
    this.setState({
      waiting: true,
    });

    createUserWithEmailAndPassword(
      getAuth(),
      this.state.email,
      this.state.password
    )
      .then((user) => {
        console.log(user);
        if (this.props.onSubmit) {
          this.props.onSubmit(this.props.page, "on-usercreated", user);
        }
      })
      .catch((error) => {
        isError = 1;
        if (error.code === "auth/email-already-in-use") {
          m = "L'indirizzo e-mail è già utilizzato da un altro account";
        } else if (error.code === "auth/invalid-email") {
          m = "L'indirizzo e-mail non è valido";
        } else if (error.code === "auth/weak-password") {
          m = "La password scelta è troppo debole";
        } else if (error.code === "auth/network-request-failed") {
          m = "Errore di rete. Assicurati di essere connesso ad internet.";
        } else {
          m = "Si è verificato un errore durante la registrazione";
        }
      })
      .finally(() => {
        this.setState({
          error: isError,
          errorMessage: m,
          waiting: false,
        });
      });
  }

  checkPassword(items) {
    if (
      !items.password.value ||
      !items.password2.value ||
      items.password.value === "" ||
      items.password2.value === "" ||
      items.password.value !== items.password2.value
    ) {
      console.log("Password error");
      return { isValid: false };
    }
    const checked = {
      password: { isValid: true },
      password2: { isValid: true },
    };
    return { isValid: true, items: checked };
  }

  render() {
    return (
      <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <h3 style={{ marginTop: "50px" }}>Account</h3>

        <MayPWithSpinner waiting={this.state.waiting}>
          {this.state.error === 0 && (
            <div className="message">
              Per iniziare, inserisci un indirizzo email e una password.
            </div>
          )}
          {this.state.error !== 0 && (
            <div className="message error">{this.state.errorMessage}</div>
          )}
        </MayPWithSpinner>
        <MayPFormBuider
          items={this.loginItems}
          id="loinForm"
          onValid={(v, data) => {
            this.setState({
              formValid: v,
              email: data.email,
              password: data.password,
              password2: data.password2,
            });
          }}
        />

        <div style={{ marginTop: "40px", marginBottom: "20px" }}>
          <MayPButtonBlue
            disabled={!this.state.formValid || this.state.waiting}
            onClick={this.createUser}
          >
            Continua
          </MayPButtonBlue>
        </div>
        <div
          className="page-title2"
          style={{ marginTop: "20px", marginBottom: "30px" }}
        >
          <Buttontext
            onClick={(e) => {
              if (this.props.onSubmit) {
                this.props.onSubmit(this.props.page, "on-home", undefined);
              }
            }}
            style={{ color: "#4E4E4E" }}
          >
            Torna indietro
          </Buttontext>
        </div>
        {/*
        <div className="bottom-notes">
          Cliccando continua accetti i nostri
          <span className="bottom-link">
          <ButtonLink>
            Termini e Codizioni
          </ButtonLink>
          </span> e
          acconsenti al trattamento dei dati secondo la nostra
          <span className="bottom-link">
          <ButtonLink>
            Privacy Policy
          </ButtonLink>
          </span>
        </div>
        */}
      </div>
    );
  }
}

export default withRouter(Registrazione1);
