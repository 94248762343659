import React from "react";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { currencyFormatter } from "../../../common/libs/utils";
import styles from "./Dettagli.module.css";
import MayPProbabilityBadge from "../../../common/components/utils/MayPProbabilityBadge";

class Dettagli extends React.Component {
  constructor(props) {
    super(props);

    
    this.toDecimal = this.toDecimal.bind(this);
    this.toFixed = this.toFixed.bind(this);
    
    this.status= "Non completata";
    this.statusClass= styles.losstext;

    console.log(props);


    if (this.props.transaction.state === 4) {
      this.status = "Non ha vinto";
      this.statusClass = styles.losstext;
    } else if (this.props.transaction.state === 5) {
      this.status = "Ha vinto";
      this.statusClass = styles.wintext;
    } else if (this.props.transaction.state === 8) {
      this.status = "Pre-autorizzazione";
    } else if (this.props.transaction.state === 9) {
      this.status = "Non hai vinto";
      this.refundedLabel = "In attesa di rimborso";
    } else if (this.props.transaction.state === 10) {
      this.status = "Non hai vinto";
      this.refundedLabel = "Rimborsata";
    } else if (this.props.transaction.state === 11) {
      this.status = "Non hai vinto";
      this.refundedLabel = "Rimborso fallito";
    }

    const date = new Date(this.props.transaction.endTime);
    this.when = format(date, "PP", { locale: it });
    this.hour = format(date, "p", { locale: it });
  }

  toDecimal(v) {
    return Math.round(v * 100) / 100;
  }

  toFixed(v) {
    return currencyFormatter.format(Number.parseFloat(v));
  }

  render() {
    return (
      <div ref={this.ref} className={styles.container}>
        <div className={`${styles.rowBlue} ${styles.header}`}>
          <div className={styles.title}>Dettagli</div>
          <MayPProbabilityBadge probability={this.props.transaction.currentProbability}/>
        </div>

        {/* <div className={styles.whiteBox}> */}
        <div className={styles.rowContainer}>
          <div className={styles.rowBlue}>
            <div className={styles.label0}>Importo pagato</div>
            <div className={styles.value0}>
              {this.toFixed(this.props.transaction.amount)} €
            </div>
          </div>
           {
              (this.props.transaction.state === 9 || 
              this.props.transaction.state === 10 ||
              this.props.transaction.state === 11) &&
               (
              <div className={styles.rowBlue}>
                <div className={styles.label0}>{this.refundedLabel}</div>
                <div className={styles.value0}>
                  {this.toFixed(this.props.transaction.refundedAmount)} €
                </div>
              </div>
              )
           }
          <div className={styles.boxBlue}> 
            <div className={styles.row}>
              <div className={styles.label2}>Fondo Montepremi + Imposte 45%</div>
              <div className={styles.value1}>
                {this.toFixed(
                  (this.props.transaction._fees.transactionCharge + this.props.transaction._fees.taxes) / 100
                )}{" "}
                €
              </div>
            </div>
            {/* <div className={styles.row}>
              <div className={styles.label2}>Fondo imposte Montepremi</div>
              <div className={styles.value1}>
                {this.toFixed(this.props.transaction._fees.taxes / 100)} €
              </div>
            </div> */}
            <div className={styles.row}>
              <div className={styles.label2}>Compenso Maypay*</div>
              <div className={styles.value1}>
                {this.toFixed(this.props.transaction._fees.maypayFee / 100)} €
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.label2}>Servizio di Pagamento*</div>
              <div className={styles.value1}>
                {this.toFixed(
                  this.props.transaction._fees.expectedStripeFee / 100
                )}{" "}
                €
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.label2}>Iva 22% sulle operazioni imponibili</div>
              <div className={styles.value1}>
                {this.toFixed(
                  this.props.transaction._fees.vat / 100
                )}{" "}
                €
              </div>
            </div>
            {this.props.transaction._fees.discount !== 0 && (
              <div className={styles.rowBlue}>
                <div className={styles.label2}>Sconti</div>
                <div className={styles.value1}>
                  -{this.toFixed(this.props.transaction._fees.discount / 100)} €
                </div>
              </div>
            )}
          </div>
          <div className={styles.row}>
              <div className={styles.label3}>*Operazione imponibile</div>
            </div>
          <div className={styles.row}>
            <div className={styles.label0}>Importo netto</div>
            <div className={styles.value0}>
              {this.toFixed(
                this.props.transaction.amount -
                  this.props.transaction.application_fee_amount / 100
              )}{" "}
              €
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

export default Dettagli;
