import React from "react";
import { wrap } from "./MayPButton";
import styles from "./MayPStoreBadge.module.css";
import IconButton from "@material-ui/core/IconButton";
import PointsIc from "../../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_points.svg";
import { StoreType } from "../../libs/utils";

const style = {
  root: {
    textTransform: "none",
    backgroundColor: "#ffffff",
    backgroundImage: "none",
    boxShadow: "#00000029 0 3px 6px",
    padding: "0",
    margin: "0",
    height: "109px",
    width: "100%",
    minWidth: "inherit",
    borderRadius: "20px",
    textDecoration: "none",
    marginTop: "16px",
    display: "flex",
    flexFlow: "row",
    lineHeight: "inherit",
    letterSpacing: "inherit",
    color: "#00000",
    WebkitTextStroke: "none",
  },
  disabled: {
    backgroundImage: "none",
    color: "#606060",
  },
};

const ButtonWrap = wrap(style);

const Details = (props) => {
  if (props.store.store_type === StoreType.ONLINE) {
    return <>{props.store.store_web_site}</>;
  }
  return (
    <>
      {props.store.store_address}
      {`${
        props.store.store_address_number
          ? ", " + props.store.store_address_number
          : ""
      }`}
      <br />
      {props.store.store_cap}, {props.store.store_city}
    </>
  );
};
class MayPStoreBadge extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      storePic: undefined,
      bottomMessage: "",
      missingContest: false,
    };
    this.details = "";

    this.store = this.props.store || this.props.data;
  }

  componentDidMount() {
    if (!this.store.registered_to_contest) {
      this.setState({
        bottomMessage: "In attesa di attivazione del concorso",
        missingContest: true,
      });
    } else if (!this.store.profilePhotoUrl || !this.store.storePhotoUrl) {
      this.setState({
        bottomMessage: "Immagini mancanti",
      });
    } else if (!this.store.enabled) {
      this.setState({
        bottomMessage: "Disabilitato",
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.wrap}>
          <ButtonWrap
            className={styles.container}
            onClick={() => {
              if (this.props.onItemClick) {
                this.props.onItemClick(this.store);
              }
            }}
          >
            <div className={styles.storePicContainer}>
              <div className={styles.storePicContainer}>
                {this.store.storePhotoUrl && (
                  <img
                    className={styles.storePic}
                    src={this.store.storePhotoUrl}
                    alt="store"
                  />
                )}
              </div>
            </div>
            <div className={styles.storeInfo}>
              <div className={styles.storeTitle}>{this.store.store_name}</div>
              <div className={styles.storeAddress}>
                <Details store={this.store} />
                {this.state.missingContest && (
                  <div>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                          "https://www.maypay.com/domande-frequenti/stato-attivazione-concorso/",
                          "_blank"
                        );
                      }}
                    >
                      <img
                        src={PointsIc}
                        style={{ width: "36px", height: "auto" }}
                        alt="completa account"
                      />
                    </IconButton>
                  </div>
                )}
              </div>
              <div className={styles.bottom}>
                <div
                  className={`${styles.storeSmallText} ${styles.alertMessage}`}
                >
                  {this.state.bottomMessage}
                </div>
                <div className={styles.storeSmallText}>
                  {this.store.store_category}
                </div>
              </div>
            </div>
          </ButtonWrap>
        </div>
      </React.Fragment>
    );
  }
}

export default MayPStoreBadge;
