export const firebaseConfig = {
  apiKey: "AIzaSyCxHxSHOI6dYAuHWG10eBkzy6m2FIyBDag",
  authDomain: "maypay-app.firebaseapp.com",
  projectId: "maypay-app",
  storageBucket: "maypay-app.appspot.com",
  messagingSenderId: "548101909962",
  appId: "1:548101909962:web:191275a3aa4c6ce22eb333"
};


export const app = {
  version: "0.1.4",
  build: 1,
  environment: "live",
};


export const redirectUrl = "https://business.maypay.com/redirect.html";

console.debug = () => {};
console.log = () => {};
console.warn = () => {};
console.error = () => {};
