import { MenuItem, Select, withStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from "react";
import { withRouter } from "react-router-dom";
import MayPButtonBlue from '../../common/components/utils/MayPButton';
import MayPDatePicker from "../../common/components/utils/MayPDatePicker";
import MayPPageTemplate from "../../common/components/utils/MayPPageTemplate";
import { MayPPage } from "../../common/components/utils/MayPPager";
import StoreLib from "../../libs/store";
import styles from "./Transazioni.module.css";
import TransactionsHistory from "./transactions/TransactionsHistory";


const StyledSelect = withStyles({
  root: {
    minWidth: "200px",
    background: "#ffffff",
    borderRadius: "30px !important",
    boxShadow: "0px 3px 6px #00000029",
  },
  select: {
    // paddingRight: "0 !important",
    paddingLeft: "14px",
    paddingRight: "14px",
    color: "#A6A6A6",
    borderRadius: "30px",
  }
})(Select)

class Transazioni extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      // currentTab: "transactions",
      transactions: [],
      showTransactionDetails: false,
      selectedTransaction: undefined,
      currentScroll: 0,
      startTime: null,
      endTime: null,
      invalidDateMessageStart: "",
      invalidDateMessageEnd: "",
      stores: [],
      selectedStore: null,
      searchInput: {
        storeId: null,
        startTime: null,
        endTime: null,
      }
    };

    this.handleScroll = this.handleScroll.bind(this);
    // this.onTabClick = this.onTabClick.bind(this);
    this.onBackHandler = this.onBackHandler.bind(this);
    this.preventLoading = false;

    this.ref = React.createRef();
    this.trHistoryRef = React.createRef();
  }

  // onTabClick(event, value) {
  //   this.setState({
  //     currentTab: value,
  //   });
  // }

  onBackHandler() {
    this.setState(
      {
        showTransactionDetails: false,
      },
      () => {
        if (this.trHistoryRef && this.trHistoryRef.current) {
          this.trHistoryRef.current.setScroll(this.state.currentScroll);
        }
      }
    );
    if (this.props.onShow) {
      this.props.onShow(this.props.page);
    }
  }

  handleScroll(evt) {
    try {
      const root = document.getElementById("root");
      const scrollValue = root.clientHeight + window.scrollY;
      const scrollLimit = root.scrollHeight;

      if (scrollValue >= scrollLimit && !this.preventLoading) {
        this.preventLoading = true;
        console.log("update");
        //this.loadStores();
      } else if (scrollValue < scrollLimit - 150) {
        this.preventLoading = false;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    const storeLib = StoreLib();
    const stores = await storeLib.getStores();

    if (stores.success) {
      const data = stores.data.map((store) => {
        return {
          storeId: store._id,
          storeName: store.store_name
        }
      })
      data.unshift({ storeId: "allStores", storeName: "Tutti i negozi" })
      console.log("CHANGED setting", data.length)
      this.setState({
        stores: data,
        selectedStore: { storeId: "allStores", storeName: "Tutti i negozi" }
      })
    } else {
      this.setState({
        stores: [{ storeId: "allStores", storeName: "Tutti i negozi" }]
      })
    }
  }

  componentWillUnmount() {
    //window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <MayPPageTemplate title={"Cronologia Transazioni"}>
        <action></action>
        <content>
          <div className={styles.mainContainer}>
            <div className={styles.headerContainer}>
              <div className={styles.inputContainer}>
                <div className={styles.inputLabel}>Negozio:</div>
                <StyledSelect
                  IconComponent={(props) => (<KeyboardArrowDownIcon {...props} style={{ color: "#A6A6A6", paddingRight: "8px" }} />)}
                  autoWidth={false}
                  placeholder="Negozi"
                  variant="standard"
                  disableUnderline={true}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  value={this.state.selectedStore ? this.state.selectedStore.storeId : null}
                  onChange={(event) => {
                    console.log("CHANGED")
                    this.setState({
                      selectedStore: this.state.stores.find((store) => store.storeId === event.target.value),
                    }, () => {
                      console.log(this.state.selectedStore)
                    })
                  }}
                >
                  {
                    this.state.stores.length > 0 &&
                    this.state.stores.map((store, index) => {
                      return (
                        <MenuItem key={`${store.storeId}-${index}`} value={store.storeId}>{store.storeName}</MenuItem>
                      )
                    })
                  }
                </StyledSelect>
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.inputLabel}>Periodo dal: </div>
                <MayPDatePicker
                  className={styles.datepicker}
                  fullWidth={true}
                  format="dd/MM/yyyy"
                  ampm={false}
                  cancelLabel="Annulla"
                  okLabel="Imposta"
                  value={this.state.startTime}
                  error={this.state.invalidDateMessageStart}
                  helperText={this.state.invalidDateMessageStart}
                  disableFuture={true}
                  KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                  InputProps={{
                    disableUnderline: true,
                    fullWidth: true,
                    placeholder: "00/00/00",
                    style: {
                      border: "0",
                      color: "#A6A6A6",
                      borderRadius: "30px",
                      boxShadow: "0px 3px 6px #00000029",
                      padding: "2px 16px",
                      margin: 0,
                    }
                  }}
                  onChange={(date) => {
                    this.setState({
                      startTime: date,
                      invalidDateMessageStart: ""
                    })
                  }}
                  onBlur={(event) => {
                    if (event.target.value === "") {
                      this.setState({
                        invalidDateMessageEnd: ""
                      })
                      return;
                    }
                    const s = event.target.value.split("/");
                    const date = new Date(`${s[1]}/${s[0]}/${s[2]}`)
                    console.log(date)
                    if (isNaN(date)) {
                      this.setState({
                        invalidDateMessageStart: "Data non valida"
                      })
                      return;
                    }

                    if (date && this.state.endTime && date.getTime() > this.state.endTime) {
                      this.setState({
                        invalidDateMessageStart: "Data inizio successiva a data fine"
                      })
                      return;
                    }

                    if (date && this.state.endTime && date.getTime() < this.state.endTime) {
                      this.setState({
                        invalidDateMessageEnd: ""
                      })
                    }
                  }}
                />
              </div>
              <div className={styles.inputContainer}>
                <div className={styles.inputLabel}>al: </div>
                <MayPDatePicker
                  className={styles.datepicker}
                  format="dd/MM/yyyy"
                  value={this.state.endTime}
                  cancelLabel="Annulla"
                  okLabel="Imposta"
                  ampm={false}
                  error={this.state.invalidDateMessageEnd}
                  helperText={this.state.invalidDateMessageEnd}
                  disableFuture={true}
                  KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                  InputProps={{
                    disableUnderline: true,
                    placeholder: "00/00/00",
                    style: {
                      border: "0",
                      color: "#A6A6A6",
                      borderRadius: "30px",
                      boxShadow: "0px 3px 6px #00000029",
                      padding: "0 16px",
                      margin: 0,
                    }
                  }}
                  onChange={(date) => {
                    this.setState({
                      endTime: date,
                      invalidDateMessageEnd: ""
                    })
                  }}
                  onBlur={(event) => {
                    if (event.target.value === "") {
                      this.setState({
                        invalidDateMessageEnd: ""
                      })
                      return;
                    }
                    const s = event.target.value.split("/");
                    const date = new Date(`${s[1]}/${s[0]}/${s[2]}`)
                    console.log(date, typeof date)
                    if (isNaN(date)) {
                      this.setState({
                        invalidDateMessageEnd: "Data non valida"
                      })
                      return;
                    }

                    if (date && this.state.startTime && date.getTime() < this.state.startTime) {
                      this.setState({
                        endTime: date,
                        invalidDateMessageEnd: "Data fine precedente a data inizio"
                      })
                      return;
                    }

                    if (date && this.state.startTime && date.getTime() > this.state.startTime) {
                      this.setState({
                        invalidDateMessageStart: ""
                      })
                    }
                  }}
                />
              </div>
              <div className={styles.tableActionContainer}>
                <MayPButtonBlue
                  disabled={this.state.invalidDateMessageEnd || this.state.invalidDateMessageStart}
                  onClick={() => {
                    this.setState({
                      searchInput: {
                        storeId: (this.state.selectedStore?.storeId === "allStores" || !this.state.selectedStore?.storeId) ? null : this.state.selectedStore.storeId,
                        startTime: this.state.startTime ? this.state.startTime.getTime() : null,
                        endTime: this.state.endTime ? this.state.endTime.getTime() : null,
                      }
                    })
                  }}
                >
                  Applica
                </MayPButtonBlue>
                {/* <MayPButtonBlue
                  disabled = {this.state.transactions.length === 0}
                  onClick={ async () => {
                    const {transactions, selectedStore} = this.state;
                    const csv = await exportCsvHelper(
                      transactions.map((t) => {
                        const {
                          dateString,
                          buyerName,
                          storeName,
                          displayCurrentProbability,
                          displayAmount,
                          displayState,
                        } = t
                        return {
                          Data: dateString,
                          Acquirente: buyerName,
                          Negozio: storeName,
                          Probabilità: displayCurrentProbability,
                          Importo: displayAmount,
                          Esito: displayState
                        }
                      })
                    )
                    const a = document.createElement("a");
                    const file = new Blob([csv], { type: "text/csv" });
        
                    a.href = URL.createObjectURL(file);
                    const startDate = new Date(transactions[0].endTime).toISOString();
                    const endDate = new Date(transactions[transactions.length - 1].endTime).toISOString();
                    const storeName = selectedStore !== "allStores" && selectedStore !== null?
                       this.state.stores.find((s) => s.storeId === selectedStore).storeName:
                       ""
                    
                    a.download = `Transazioni${storeName}_${startDate}_${endDate}.csv`;
                    a.click();
        
                    URL.revokeObjectURL(a.href);
                  }}
                  >
                  Esporta
                </MayPButtonBlue> */}
              </div>
            </div>
            <TransactionsHistory searchInput={this.state.searchInput} onTransactionsChange={(transactions) => {
              this.setState({ transactions })
            }} />
          </div>
        </content>
      </MayPPageTemplate>
    );
  }
}

export default withRouter(Transazioni);
