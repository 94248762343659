import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import MayPFormBuider from "../../common/components/utils/MayPFormBuilder";
import { MayPButtonTextWrap } from "../../common/components/utils/MayPButtonText";
import { connect } from "react-redux";
import { setBankCache } from "../../redux/actions/login";
import styles from "./ExternalAccount.module.css";
import countries from "./countries";
import LockIcon from "../../common/components/assets/@MAYPAY_UIKIT_BUYER/mp_payments.svg";
import { withRouter } from "react-router-dom";

const ButtonBack = MayPButtonTextWrap({
  root: {
    fontSize: "14px",
    letterSpacing: "1.4x",
    fontWeight: "normal",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #606060",
    color: "#606060",
  },
  disabled: {
    color: "#cccccc",
    "WebkitTextStroke": "0.7px #cccccc",
  },
});

class ExternalAccount extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formValid: false,
      waiting: false,
      error: 0,
      errorMessage: "",
      formData: props.cache,
    };

    this.onContinue = this.onContinue.bind(this);
    this.onBack = this.onBack.bind(this);

    this.formItems = [
      {
        type: "text",
        label: "Titolare del conto",
        name: "bank_owner",
        defaultValue: props.cache.bank_owner,
        wrapperClass: "form-data w100",
        nextFocus: "bank_country",
      },
      {
        type: "select",
        label: "Stato",
        name: "bank_country",
        defaultValue: props.cache.bank_country || "IT",
        wrapperClass: "form-data w50",
        nextFocus: "bank_currency",
        values: countries,
      },
      {
        type: "select",
        label: "Valuta",
        name: "bank_currency",
        defaultValue: props.cache.bank_currency || "EUR",
        wrapperClass: "form-data w50",
        nextFocus: "bank_iban",
        values: [
          { label: "EUR", value: "EUR" },
          { label: "USD", value: "USD" },
          { label: "GBP", value: "GBP" },
          { label: "SEK", value: "SEK" },
          { label: "NOK", value: "NOK" },
          { label: "DKK", value: "DKK" },
          { label: "CHF", value: "CHF" },
        ],
      },
      {
        type: "text",
        label: "IBAN",
        name: "bank_iban",
        defaultValue: props.cache.bank_iban
          ? props.cache.bank_iban.toUpperCase()
          : "",
        wrapperClass: "form-data w100",
        validation: [
          {
            re: /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/,
            message: "IBAN non valido",
          },
        ],
      },
    ];
  }

  checkEmailsEq(items) {
    console.log(items);
    if (
      ((items.owner_email.value && items.owner_email.value !== "") ||
        (items.owner_email2.value && items.owner_email2.value !== "")) &&
      items.owner_email.value !== items.owner_email2.value
    ) {
      return { isValid: false };
    }
    const checked = {
      owner_email: { isValid: true },
      owner_email2: { isValid: true },
    };
    return { isValid: true, items: checked };
  }

  onContinue() {
    console.log(this.state.formData)
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.page, "on-bankdata", {
        ...this.state.formData,
      });
    }
  }

  onBack() {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.page, "on-bankdata-back", {
        ...this.state.formData,
      });
    }
  }


  render() {
    return (
      <div>
        <h3 style={{ marginTop: "50px" }}>Collega un conto bancario</h3>

        <MayPFormBuider
          items={this.formItems}
          id="bankForm"
          disableAll={this.props.waiting}
          onValid={(v, data) => {
            this.setState({
              formValid: v,
              formData: data,
            });
            this.props.setCache(data);
          }}
          containerClass={styles.formDataContainer}
        />
        <div className={styles.secure}>
          <div className={styles.secureBlue}>
            <img
              src={LockIcon}
              style={{ width: "24px", height: "auto" }}
              alt="pagamenti sicuri"
            />{" "}
            <div className={styles.blueText}>Pagamenti sicuri</div>
          </div>
        </div>
        <div className={styles.info}>
          Tutti pagamenti sono processati da Stripe, leader globale dei
          pagamenti digitali.
        </div>
        <div style={{ marginTop: "40px", textAlign: "center", width: "100%" }}>
          <MayPWithSpinner waiting={this.props.waiting}>
            <MayPButtonBlue
              disabled={this.props.waiting || !this.state.formValid}
              onClick={this.onContinue}
            >
              Continua
            </MayPButtonBlue>
          </MayPWithSpinner>
        </div>
        <div className="login-page-back">
          <ButtonBack onClick={this.onBack} disabled={this.props.waiting}>
            Torna indietro
          </ButtonBack>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cache: state.login.bankCache || {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCache: (data) => dispatch(setBankCache(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExternalAccount));
