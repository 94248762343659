const initAppState = {
  loaded: false,
  db: undefined,
};

function app(state = initAppState, action) {
  switch (action.type) {
    case "SET_LOADED":
      return Object.assign({}, state, { loaded: action.loaded });
    default:
      return state;
  }
}

export default app;
