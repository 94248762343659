import format from "date-fns/format";
import React from "react";
import itLocale from "date-fns/locale/it";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "d MMM yyyy", { locale: this.locale });
  }
}

class MayPDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={LocalizedUtils} locale={itLocale}>
        {!this.props.enableTimePicker && <KeyboardDatePicker {...this.props} />}
        {this.props.enableTimePicker && <KeyboardDateTimePicker {...this.props} />}
      </MuiPickersUtilsProvider>
    );
  }
}

export default MayPDatePicker;
