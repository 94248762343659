import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const _style = {
  root: {
    height: "5px",
    width: "calc(100% - 40px)",
    display: "inline-block",
    backgroundColor: "#d6d6d6",
    borderRadius: 100,
    top: "-2px",
    marginRight: "10px",
  },
  bar: {
    borderRadius: 100,
    backgroundColor: "#00e091",
  },
};

class MayPLinearProgress extends React.Component {
  constructor(props) {
    super(props);
    const { classes, other } = props;
    this.classes = classes;
    this.props = other;
  }

  render() {
    return (
      <LinearProgress className={this.props.classes.root} {...this.props} />
    );
  }
}

export default withStyles(_style)(MayPLinearProgress);
