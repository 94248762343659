export const setAuth = (auth) => ({
  type: "SET_AUTH",
  auth,
});

export const clearData = () => ({
  type: "CLEAR_DATA",
});

export const updateProfile = (
  profile,
  photoPath = undefined,
  photoURL = undefined
) => ({
  type: "UPDATE_PROFILE",
  profile,
  photoPath,
  photoURL,
});

export const setCurrentTransaction = (currentTransaction) => ({
  type: "SET_CURRENT_TRANSACTION",
  currentTransaction,
});
