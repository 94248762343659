import { getApp } from "firebase/app";
import {
  getStorage,
  uploadString,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

import { v4 as uuidV4 } from "uuid";

export default function a() {
  /*const asyncReq = async (url, data)=>{
    return new Promise(function (resolve, reject) {
      request(url, function (error, res, body) {
        if (!error && res.statusCode == 200) {
          resolve({error,res, body});
        } else {
          reject({error, res, body});
        }
      });
    });
  }*/

  const sendMessageToFollowers = async (data, bgPic) => {
    let r = { data: null };
    try {
      const currentUser = getAuth().currentUser;
      let storageRef = getStorage();
      let bgPicURL = bgPic;
      if (bgPic && bgPic.startsWith("data")) {
        const id = uuidV4();
        let bgPicRef = ref(
          storageRef,
          "store/" + currentUser.uid + "/messages/" + id
        );
        await uploadString(bgPicRef, bgPic, "data_url");
        const uploadedRef = ref(storageRef, bgPicRef.fullPath);
        bgPicURL = await getDownloadURL(uploadedRef);
        console.log(1);
      } else {
      }
      data.imageBig = bgPicURL;
      console.log(data);
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "sendMessageToFollowers");
      r = await f(data);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };

  const reportUser = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "reportUser");
      r = await f(data);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };

  const registerStripeCustomAccount = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "registerStripeCustomAccount");
      r = await f(data);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data;
    }
  };

  const getStripeCustomAccount = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "getStripeCustomAccount");
      r = await f(data);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data;
    }
  };

  const setStripeExternalAccount = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "setStripeExternalAccount");
      r = await f(data);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data;
    }
  };

  const retrieveCurrentPayment = async (data) => {
    let r = { data: null, error: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "retrievePaymentReqBuyer");
      const res = await f(data);
      r = res.data;
    } catch (e) {
      r.error = e;
    } finally {
      return r;
    }
  };

  const refusePaymentReq = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "refusePaymentReq");
      r = await f(data);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };

  const acceptPaymentReq = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "acceptPaymentReq");
      r = await f(data);
      console.log(r);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };

  const getStripeMerchantPayouts = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "getStripeMerchantPayouts");
      r = await f(data);
      console.log(r);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };
  const getStripeMerchantBalance = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "getStripeMerchantBalance");
      r = await f(data);
      console.log(r);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };

  const getStripeExternalAccount = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "getStripeExternalAccount");
      r = await f(data);
      console.log(r);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };

  const upsertMerchantAccount = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "upsertMerchantAccount");
      r = await f(data);
      //console.log(r);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };

  const upsertStore = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "upsertStore");
      r = await f(data);
      //console.log(r);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };

  const closeCashRegister = async (data) => {
    let r = { data: null };
    try {
      const functions = getFunctions(getApp(), "europe-west1");
      const f = httpsCallable(functions, "closeCashRegister");
      r = await f(data);
      console.log(r);
    } catch (e) {
      console.log(e);
    } finally {
      return r.data || null;
    }
  };

  return {
    refusePaymentReq: refusePaymentReq,
    acceptPaymentReq: acceptPaymentReq,
    reportUser: reportUser,
    sendMessageToFollowers: sendMessageToFollowers,
    registerStripeCustomAccount: registerStripeCustomAccount,
    getStripeCustomAccount: getStripeCustomAccount,
    setStripeExternalAccount: setStripeExternalAccount,
    getStripeMerchantPayouts: getStripeMerchantPayouts,
    getStripeMerchantBalance: getStripeMerchantBalance,
    getStripeExternalAccount: getStripeExternalAccount,
    upsertMerchantAccount: upsertMerchantAccount,
    upsertStore: upsertStore,
    closeCashRegister: closeCashRegister,
    retrieveCurrentPayment: retrieveCurrentPayment,
  };
}
