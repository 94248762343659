const initUserState = {
  uid: undefined,
  email: undefined,
  photoURL: undefined,
  photoPath: undefined,
  registered: false,
  profile: undefined,
};

function user(state = initUserState, action) {
  let user = { ...state };
  switch (action.type) {
    case "SET_AUTH":
      if (action.auth) {
        user.uid = action.auth.uid;
        user.email = action.auth.email;
        user.verified = action.auth.emailVerified;
        user.registered = action.auth.registration_completed ? true : false;
      } else {
        user.uid = undefined;
        user.email = undefined;
        user.verified = undefined;
        user.registered = undefined;
      }
      return Object.assign({}, state, user);
    case "CLEAR_DATA":
      user = initUserState;
      return Object.assign({}, state, user);
    case "UPDATE_PROFILE":
      if (action.profile) {
        user.profile = action.profile;
      }
      if (action.photoURL) {
        user.photoURL = action.photoURL;
      }
      if (action.photoPath) {
        user.photoPath = action.photoPath;
      }
      return Object.assign({}, state, user);
    case "SET_CURRENT_TRANSACTION":
      //if(action.currentTransaction){
      user.currentTransaction = action.currentTransaction;
      //}
      return Object.assign({}, state, user);
    default:
      return state;
  }
}

export default user;
