import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";
import { getAuth, updateProfile, updateEmail } from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  where,
  query,
  getDocs,
  addDoc,
  getDoc,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { APIKEY_STATUS, FirestoreCollection } from "../common/libs/utils";
const crypto = require("crypto");

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const updateUserProfileFirebase = async (data) => {
    console.log("Saving user profile...", data);
    const currentUser = getAuth().currentUser;
    const db = getFirestore();

    if (currentUser && currentUser.uid) {
      let profileRef = doc(collection(db, "merchants"), currentUser.uid);
      let storageRef = getStorage();

      //Update profile of firebase user
      let photoURL = currentUser.photoURL;

      try {
        if (data.profilePic && data.profilePic.startsWith("data")) {
          let userPicRef = ref(
            storageRef,
            "users/profile/profile-" + currentUser.uid + ".png"
          );
          await uploadString(userPicRef, data.profilePic, "data_url");
          photoURL = userPicRef.fullPath;
        }
        await updateProfile(currentUser, {
          displayName: data.profile.name,
          photoURL: photoURL,
        });

        await updateEmail(currentUser, data.profile.email);

        // Update successful, set other profile data in DB .
        await updateDoc(profileRef, {
          name: data.profile.name || "",
          surname: data.profile.surname || "",
          gender: data.profile.gender || "",
          birthday: data.profile.birthday || "",
          email: data.profile.email || "",
        });
        return { success: true, error: null, data: null };
      } catch (error) {
        console.log("error updating user:", error);
        return { success: false, error: error, data: null };
      }
    } else {
      console.error("current user not defined");
      //error
      return { success: false, error: "currentUser is undefined", data: null };
    }
  };

  const getProfilePhotoURL = async () => {
    const currentUser = getAuth().currentUser;
    let userPicRef = ref(getStorage(), currentUser.photoURL);
    try {
      const url = await getDownloadURL(userPicRef);
      return url;
    } catch (error) {
      console.log("error getting user profile picture url", error);
    }

    return undefined;
  };

  const getApiKeys = async (storeId) => {
    try {
      const db = getFirestore();
      let queryRef = collection(
        doc(collection(db, "merchants"), getAuth().currentUser.uid),
        "apiKeys"
      );

      if (storeId) {
        queryRef = query(queryRef, where("storeId", "==", storeId));
      }

      const results = await getDocs(queryRef);

      if (results.size === 0) {
        return {
          success: true,
          data: [],
          error: null,
        };
      }

      const apiKeys = [];

      results.forEach((doc) => {
        const data = doc.data();
        apiKeys.push({
          ...data,
          apiKeyId: doc.id,
        });
      });

      return {
        success: true,
        error: null,
        data: apiKeys,
      };
    } catch (error) {
      return {
        success: false,
        error: error,
        data: null,
      };
    }
  };

  const createApiKey = async (storeId, environment) => {
    try {
      const merchantId = getAuth().currentUser.uid;
      const publicKey =
        "maypay_pk_" + crypto.randomBytes(16).toString("base64");
      const privateKey =
        "maypay_sk_" + crypto.randomBytes(48).toString("base64");

      const apiKeyData = {
        publicKey: publicKey,
        privateKey: privateKey,
        merchantId: merchantId,
        state: APIKEY_STATUS.ACTIVE,
        createdAt: Date.now(),
        environment,
      };

      if (storeId) {
        apiKeyData["storeId"] = storeId;
      }

      const res = await addDoc(
        collection(
          doc(
            collection(getFirestore(), FirestoreCollection.Merchants),
            merchantId
          ),
          FirestoreCollection.APIKeys
        ),
        apiKeyData
      );

      console.log(apiKeyData);
      return {
        success: true,
        error: null,
        data: {
          apiKeyId: res.id,
          publicKey: publicKey,
          privateKey: privateKey,
          merchantId: merchantId,
          storeId,
          state: APIKEY_STATUS.ACTIVE,
          createdAt: Date.now(),
          environment,
        },
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error: error,
        data: null,
      };
    }
  };

  const revokeApiKey = async (apiKeyId) => {
    try {
      await updateDoc(
        doc(
          collection(
            doc(
              collection(getFirestore(), FirestoreCollection.Merchants),
              getAuth().currentUser.uid
            ),
            FirestoreCollection.APIKeys
          ),
          apiKeyId
        ),
        {
          state: APIKEY_STATUS.REVOKED,
        }
      );
      return {
        success: true,
        error: null,
        data: null,
      };
    } catch (error) {
      return {
        success: false,
        error: error,
        data: null,
      };
    }
  };


  const getSettings = async () => {
    try {
      const merchantSettings = await getDoc(doc(collection(doc(collection(getFirestore(), FirestoreCollection.Merchants), getAuth().currentUser.uid), 'settings'), 'discount'))
      const settings = await getDoc(doc(collection(getFirestore(), FirestoreCollection.Settings), 'discount'))

      const settingsData = settings.data();
      const vat = settingsData.vat;
      const merchantSettingsData = merchantSettings.data();
      return {
        success: true,
        data: merchantSettings.exists() ? { vat, ...merchantSettingsData } : settingsData,
        error: null
      }
    }
    catch (error) {
      return {
        success: false,
        data: null,
        error: error
      }
    }
  }

  const getProbability = async () => {
    try {
      const merchant = await getDoc(doc(collection(getFirestore(), FirestoreCollection.Merchants), getAuth().currentUser.uid))
      const merchantData = merchant.data()
      var probability = merchantData.default_probability
      if (probability === null || probability === undefined) {
        const settings = await getDoc(doc(collection(getFirestore(), FirestoreCollection.Settings), 'project'))
        const settingsData = settings.data()
        probability = settingsData.globalDefaultProbability
      }

      return {
        success: true,
        data: { probability: probability },
        error: null
      }

    }
    catch (error) {
      return {
        success: false,
        data: null,
        error: error
      }
    }
  }

  const getReports = async (docTypes, startTime, endTime, cursor, l) => {
    console.log(docTypes, startTime, endTime)
    try {

      const queryParams = []

      if (docTypes.length > 0) {
        queryParams.push(where("type", "in", docTypes))
      }
      if (startTime) {
        queryParams.push(where("period", ">=", startTime))
      }
      if (endTime) {
        queryParams.push(where("period", "<=", endTime))
      }
      queryParams.push(orderBy("period", "desc"))
      queryParams.push(limit(l))

      if (cursor) {
        queryParams.push(startAfter(cursor));
      }

      const collectionRef = collection(
        doc(collection(getFirestore(), "merchants"), getAuth().currentUser.uid),
        "reports"
      );

      const queryRef = await getDocs(query(collectionRef, ...queryParams))

      const docsData = []

      if (queryRef.size > 0) {
        for (const d of queryRef.docs) {
          docsData.push({ id: d.id, ...d.data() })
        }
      }

      return {
        success: true,
        error: null,
        data: { documents: docsData, cursor: queryRef.docs[queryRef.docs.length - 1] }
      }

    }
    catch (e) {
      console.log(e)
      return {
        success: false,
        error: e,
        data: null
      }
    }
  }

  const getReportUrl = async (path) => {

    try {
      const storageRef = getStorage();
      const report = ref(storageRef, path);

      const url = await getDownloadURL(report)
      return {
        success: true,
        error: null,
        data: { url }
      }
    } catch (e) {
      return {
        success: false,
        error: e,
        data: null
      }
    }

  }

  return {
    updateUserProfileFirebase,
    getProfilePhotoURL,
    getApiKeys,
    createApiKey,
    revokeApiKey,
    getProbability,
    getSettings,
    getReports,
    getReportUrl
  };
}
