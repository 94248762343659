import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";

const styles = {
  root: {
    // - The TextField-root
    "&& .MuiInput-root:hover::before": { borderBottomColor: "#00a2e0" },
  },
};

const StylesTextField = withStyles(styles)(TextField);

class MayPInput extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    if (this.props.inputRef) {
      this.ref = this.props.inputRef;
    } else {
      this.ref = React.createRef();
    }
    this.onKeyEnter = this.onKeyEnter.bind(this);
  }

  onKeyEnter(event) {
    if (event.key === "Enter") {
      if (event.target) {
        this.ref.current.blur();
      }
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  }

  render() {
    return (
      <StylesTextField
        inputRef={this.ref}
        {...this.props}
        onKeyDown={this.onKeyEnter}
      />
    );
  }
}

export default MayPInput;
