import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue, { wrap } from "../../common/components/utils/MayPButton";
import MayPFormBuider from "../../common/components/utils/MayPFormBuilder";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import { MayPButtonTextWrap } from "../../common/components/utils/MayPButtonText";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const ButtonPassword = MayPButtonTextWrap({
  root: {
    fontSize: "16px",
    letterSpacing: "1.6px",
    fontWeight: "normal",
    lineHeight: "22px",
    color: "#00E091",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #00e091",
  },
});

const _style = {
  root: {
    backgroundImage: "linear-gradient(to bottom,#00E091, #00E091)",
    color: "#606060",
    "WebkitTextStroke": "0.7px #606060",
  },
};

const ButtonGreen = wrap(_style, MayPButtonBlue);

const ERROR_LOGIN = 1;

class Accedi extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formValid: false,
      email: "",
      password: "",
      waiting: false,
      error: 0,
      errorMessage: "",
    };

    this.loginItems = [
      {
        type: "email",
        label: "E-mail",
        name: "email",
        defaultValue: "",
        wrapperClass: "login-input2",
        validation: [
          {
            re: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
            message: "E-mail non valida",
          },
        ],
        nextFocus: "password",
      },
      {
        type: "password",
        label: "Password",
        name: "password",
        defaultValue: "",
        wrapperClass: "login-input2",
        validation: [
          { callback: this.checkPassword, message: "Password non valida" },
        ],
      },
    ];

    this.onRegistrati = this.onRegistrati.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.onAccedi = this.onAccedi.bind(this);
    this.login = this.login.bind(this);
  }

  onRegistrati(evt) {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.page, evt, null);
    }
  }

  onAccedi() {
    this.setState(
      {
        waiting: true,
      },
      this.login
    );
  }

  login() {
    console.log("login", getAuth());
    signInWithEmailAndPassword(getAuth(), this.state.email, this.state.password)
      .then((user) => {
        if (this.props.onSubmit) {
          this.props.onSubmit(this.props.page, "on-login", user);
        }
      })
      .catch((error) => {
        // Handle Errors here.
        console.log("Login", error);
        let message = "";
        if (error.code && error.code === "auth/user-not-found") {
          message = "Attenzione i dati inseriti risultano errati.";
        } else if (error.code === "auth/network-request-failed") {
          message =
            "Errore di rete. Assicurati di essere connesso ad internet.";
        } else {
          message = "Non è stato possibile effettuare l'accesso.";
        }

        this.setState({
          waiting: false,
          error: ERROR_LOGIN,
          errorMessage: message,
        });
        // ...
      });
  }

  checkPassword() {
    return { isValid: true };
  }

  render() {
    return (
      <div>
        <h3>Benvenuto</h3>
        <MayPWithSpinner waiting={this.state.waiting}>
          {this.state.error === 0 && (
            <div className="message">Accedi con le tue credenziali.</div>
          )}
          {this.state.error !== 0 && (
            <div className="message error">{this.state.errorMessage}</div>
          )}
        </MayPWithSpinner>
        <MayPFormBuider
          items={this.loginItems}
          id="loinForm"
          onValid={(v, data) => {
            this.setState({
              formValid: v,
              email: data.email,
              password: data.password,
            });
          }}
        />
        <div style={{ marginTop: "40px", textAlign: "center", width: "100%" }}>
          <ButtonPassword
            onClick={() => {
              this.onRegistrati("goto-resetpassword");
            }}
            disabled={this.state.waiting}
          >
            Password dimenticata?
          </ButtonPassword>
        </div>
        <div style={{ marginTop: "16px", marginBottom: "20px", flexGrow: 1 }}>
          <MayPButtonBlue
            disabled={!this.state.formValid || this.state.waiting}
            onClick={() => this.onAccedi()}
          >
            Accedi
          </MayPButtonBlue>
        </div>
        <div
          className="message"
          style={{ marginTop: "40px", marginBottom: "0" }}
        >
          Non sei ancora registrato?
        </div>
        <div style={{ marginTop: "16px", textAlign: "center", width: "100%" }}>
          <ButtonGreen
            disabled={this.state.waiting}
            onClick={() => this.onRegistrati("goto-registration")}
          >
            Registrati
          </ButtonGreen>
        </div>
      </div>
    );
  }
}

export default Accedi;
