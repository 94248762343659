import { IconButton, MenuItem, Select, withStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CancelIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/maypay_icon_x_red.svg";
import DownloadIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_documents_download_gray_2.svg";
import DownloadIcGreen from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_documents_download_green.svg";
import MayPButtonBlue, { wrap } from '../../common/components/utils/MayPButton';
import MayPCheck from "../../common/components/utils/MayPCheck";
import MayPDatePicker from "../../common/components/utils/MayPDatePicker";
import MayPDialog from "../../common/components/utils/MayPDialog";
import MayPPageTemplate from "../../common/components/utils/MayPPageTemplate";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPRadio from "../../common/components/utils/MayPRadio";
import MayPSearchFilter from "../../common/components/utils/MayPSearchFilter";
import MayPTable from "../../common/components/utils/MayPTable";
import { DocumentType, FileType, zipFromUrls } from "../../common/libs/utils";
import UserLib from "../../libs/user";
import styles from "./Documents.module.css";

const StyledSelect = withStyles({
    root: {
        minWidth: "200px",
        background: "#ffffff",
        borderRadius: "30px !important",
        boxShadow: "0px 3px 6px #00000029",
    },
    select: {
        // paddingRight: "0 !important",
        paddingLeft: "14px",
        paddingRight: "14px",
        color: "#A6A6A6",
        borderRadius: "30px",
    }
})(Select)

const style = {
    root: {
        textTransform: "none",
        backgroundColor: "transparent",
        backgroundImage: "none",
        boxShadow: "transparent 0 0px 0px",
        padding: "0px 10px",
        minWidth: "inherit",
        borderRadius: "20px",
        textDecoration: "none",
        display: "flex",
        flexFlow: "row",
        lineHeight: "inherit",
        letterSpacing: "inherit",
        color: "var(--secondary-color)",
        WebkitTextStroke: "0.5px var(--secondary-color)",
        margin: "0 auto",
    },
    disabled: {
        backgroundImage: "none",
        color: "#606060",
    },
};

const ButtonWrap = wrap(style);

class Documents extends MayPPage {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            documents: [],
            viewedDocuments: [],
            type: 0,
            selectedTypes: [],
            types: [
                { id: 0, name: 'Tutti i documenti', type: 'document_type', value: null },
                // { id: 1, name: 'Fatture', type: 'document_type', value: DocumentType.INVOICE },
                { id: 1, name: 'Resoconti del mese', type: 'document_type', value: DocumentType.DAILY_REPORT },
                { id: 2, name: 'Resoconti accrediti', type: 'document_type', value: DocumentType.REPORT },
                { id: 3, name: 'Estratti conto', type: 'document_type', value: DocumentType.ACCOUNT_BALANCE }
            ],
            startTime: null,
            endTime: null,
            invalidDateMessageStart: null,
            invalidDateMessageEnd: null,
            waitingDocuments: false,
            multipleSelect: false,
            filters: [],
            selectAll: false,
            selectedRows: [],
            showDialog: false,
            dialogTitle: "",
            dialogMessage: "",
            dialogActions: [],
            dialogOnClose: {},
        }
        this.limit = 10;
        this.cursor = null;

        this.labels = {
            ResocontoGiornaliero: "Resoconto del mese",
            ResocontoAccrediti: "Resoconto accrediti",
            EstrattoConto: "Estratto conto",
        }
    }

    getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('it-IT', {
            month: 'long',
        });
    }

    handleDownload = async (fileName, storagePath, contentType) => {
        const userLib = UserLib()
        const res = await userLib.getReportUrl(storagePath);

        if (!res.success) {
            this.setState({
                showDialog: true, 
                dialogTitle: "Errore",
                dialogMessage: `Impossibile scaricare il file ${fileName}`,
                dialogOnClose: () => {this.setState({showDialog: false})}
            })
            return;
        }

        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
                    // Creare un oggetto Blob con il contenuto del file

                    let type = 'text';
                    switch (contentType) {
                        case FileType.PDF:
                            type = 'application/pdf';
                            break;
                        case FileType.CSV:
                            type = 'text/csv';
                            break;
                        default:
                            break;
                    }

                    var blob = new Blob([xhr.response], { type: type });

                    // Creare un oggetto URL per il blob generato
                    var url = window.URL.createObjectURL(blob);

                    // Creare un elemento <a> per il download
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;

                    // Aggiungere l'elemento <a> al documento
                    document.body.appendChild(a);

                    // Simulare il click sull'elemento <a> per avviare il download
                    a.click();

                    // Rimuovere l'elemento <a> dal documento
                    document.body.removeChild(a);

                    // Rilasciare l'URL dell'oggetto blob
                    window.URL.revokeObjectURL(url);
                } else {
                    console.error('Errore durante il download del file:', xhr.status, xhr.statusText);
                    this.setState({
                        showDialog: true, 
                        dialogTitle: "Errore",
                        dialogMessage: `Impossibile scaricare il file ${fileName}`,
                        dialogOnClose: () => {this.setState({showDialog: false})}
                    })
                }
            }
        };

        xhr.onreadystatechange = xhr.onreadystatechange.bind(this);

        xhr.onerror = function () {
            console.error('Errore durante la richiesta del file.');
        };

        // Impostare responseType su 'arraybuffer' per gestire file binari
        xhr.responseType = 'arraybuffer';

        // Effettuare la richiesta al server per il file
        xhr.open('GET', res.data.url);
        xhr.send();
    };

    padMonth(number) {
        return `${number}`.padStart(2, "0")
    }

    async fetchReports() {
        try {
            const userLib = UserLib()
            const docType = this.state.types.filter(t => this.state.selectedTypes.includes(t.id)).map(t => t.value)
            const start = this.state.startTime ? `${this.state.startTime.getFullYear()}/${this.padMonth(this.state.startTime.getMonth() + 1)}` : null
            const end = this.state.endTime ? `${this.state.endTime.getFullYear()}/${this.padMonth(this.state.endTime.getMonth() + 1)}` : null
            const documents = await userLib.getReports(docType, start, end, this.cursor, this.limit)
            if (documents.success) {
                if (documents.data.documents.length > 0) {
                    this.setState({
                        selectedRows: documents.data.documents.map((d) => {
                            return false
                        })
                    })
                    const docsData = documents.data.documents.map((d) => {

                        let period = null
                        const splittedId = d.id.split("_")
                        if (splittedId.length === 3) {
                            period = `${this.getMonthName(splittedId[2])} ${splittedId[1]}`
                        }
                        else {
                            period = `${splittedId[2]}`
                        }

                        // d.selected = false

                        return {
                            ...d,
                            createdAt: new Date(d.createdAt).toLocaleDateString("it-IT"),
                            expiredAt: new Date(d.expiredAt).toLocaleDateString("it-IT"),
                            period: period,
                            viewType: this.labels[d.type] ?? "",
                            download: <>
                                <ButtonWrap key={d.id} onClick={() => {
                                    this.handleDownload(d.id, d.storageRef, d.contentType)
                                }}>
                                    <div>Scarica {d.contentType.toUpperCase()}</div>
                                    <img className={styles.downloadDocImg} src={DownloadIcGreen} alt="download document"></img>
                                </ButtonWrap>
                            </>,
                        }
                    });
                    this.cursor = documents.data.cursor;
                    this.setState({
                        documents: docsData,
                        viewedDocuments: docsData,
                    });
                    return docsData;
                }
                else {
                    return null;
                }
            }
        }
        catch (e) {
            this.setState({
                openDialog: true,
                dialogTitle: "Errore",
                dialogMessage: `Errore durante il caricamento dei documenti: ${e.message}`
            })
        }
    }

    removeSearchFilter(filterId) {
        console.log(filterId)
        this.setState({
            filters: this.state.filters.filter((f) => { return f.id !== filterId }),
            selectedTypes: this.state.selectedTypes.filter(t => t !== filterId)
        }, () => {
            if (this.state.filters.filter(f => f.type === 'document_type').length === 0) {
                this.setState({
                    type: 0
                })
            }
        })
        if (filterId === 99) {
            this.setState({
                startTime: null,
                endTime: null
            })
        }

    }

    addDocumentTypeFilter(id) {
        const newFilter = this.state.types.find((t) => { return t.id === id })
        let filters = this.state.filters
        if (!this.state.filters.find(f => f.id === id)) {
            if (newFilter.name !== 'Tutti i documenti') {
                filters.push(newFilter)
            }
            else {
                filters = []
            }
        }
        this.setState({
            filters: filters
        })
    }

    changeDateFilter(startTime, endTime) {

        let filterName = ""
        if (!startTime && endTime) {
            filterName = `fino a ${this.getMonthName(endTime.getMonth() + 1)} ${endTime.getFullYear()}`
        }
        else if (startTime && !endTime) {
            filterName = `da ${this.getMonthName(startTime.getMonth() + 1)} ${startTime.getFullYear()}`
        }
        else if (startTime && endTime) {
            filterName = `${this.getMonthName(startTime.getMonth() + 1)} ${startTime.getFullYear()} - ${this.getMonthName(endTime.getMonth() + 1)} ${endTime.getFullYear()}`
        }
        else {
            let filters = this.state.filters.filter((f) => { return f.type !== 'date_range' })
            this.setState({
                filters: filters
            })
            return;
        }


        const newFilter = { id: 99, name: filterName, type: 'date_range' }
        let filters = this.state.filters.filter((f) => { return f.type !== 'date_range' })
        filters.push(newFilter)
        this.setState({
            filters: filters
        })
    }

    formatDate(date) {
        const opzioni = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattatore = new Intl.DateTimeFormat('it-IT', opzioni);
        return formattatore.format(date);
    }

    componentDidMount() {
        this.fetchReports();
        this.cursor = null;
    }

    render() {
        return (
            <MayPPageTemplate title={"Documenti Contabili"}>
                <action></action>
                <content>
                    <div className={styles.mainContainer}>
                        <div className={styles.pageDescription}>Qui puoi visualizzare tutti i documenti ricevuti negli ultimi 24 mesi.<br />
                            Puoi effettuare una ricerca avanzata per tipologia di documento <br />
                            e per data di emissione</div>
                        <div className={styles.headerContainer}>
                            <div className={styles.inputContainer}>
                                <div className={styles.inputLabel}>Documento:</div>
                                <StyledSelect
                                    IconComponent={(props) => (<KeyboardArrowDownIcon {...props} style={{ color: "#A6A6A6", paddingRight: "8px" }} />)}
                                    autoWidth={false}
                                    placeholder="Tutti i documenti"
                                    variant="standard"
                                    disableUnderline={true}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    value={this.state.type}
                                    onChange={(event) => {
                                        let selectedTypes = this.state.selectedTypes

                                        if (!selectedTypes.includes(event.target.value)) {
                                            selectedTypes.push(event.target.value)
                                        }

                                        if (event.target.value === 0) {
                                            selectedTypes = []
                                        }

                                        this.setState({
                                            type: event.target.value,
                                            selectedTypes: selectedTypes
                                        })

                                        this.addDocumentTypeFilter(event.target.value)
                                    }}
                                >
                                    {
                                        this.state.types.map((type, index) => {
                                            return (
                                                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                            )
                                        })
                                    }
                                </StyledSelect>
                            </div>
                            <div className={styles.inputContainer}>
                                <div className={styles.inputLabel}>Dal: </div>
                                <MayPDatePicker
                                    className={styles.datepicker}
                                    fullWidth={true}
                                    format="MM/yyyy"
                                    ampm={false}
                                    cancelLabel="Annulla"
                                    okLabel="Imposta"
                                    value={this.state.startTime}
                                    error={this.state.invalidDateMessageStart}
                                    helperText={this.state.invalidDateMessageStart}
                                    disableFuture={true}
                                    KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                                    InputProps={{
                                        disableUnderline: true,
                                        fullWidth: true,
                                        placeholder: "00/0000",
                                        style: {
                                            border: "0",
                                            color: "#A6A6A6",
                                            borderRadius: "30px",
                                            boxShadow: "0px 3px 6px #00000029",
                                            padding: "2px 16px",
                                            margin: 0,
                                        }
                                    }}
                                    onChange={(date) => {
                                        this.setState({
                                            startTime: date,
                                            invalidDateMessageStart: "",
                                        })
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({
                                                invalidDateMessageEnd: ""
                                            })
                                            this.changeDateFilter(null, this.state.endTime)
                                            return;
                                        }
                                        const s = event.target.value.split("/")
                                        const date = new Date(`${s[0]}/01/${s[1]}`)

                                        if (isNaN(date)) {
                                            this.setState({
                                                invalidDateMessageStart: "Data non valida"
                                            })
                                            return;
                                        }

                                        if (date && this.state.endTime && date.getTime() > this.state.endTime) {
                                            this.setState({
                                                invalidDateMessageStart: "Data inizio successiva a data fine"
                                            })
                                            return;
                                        }

                                        if (date && this.state.endTime && date.getTime() < this.state.endTime) {
                                            this.setState({
                                                invalidDateMessageEnd: ""
                                            })
                                        }

                                        if (date instanceof Date) {
                                            this.changeDateFilter(date, this.state.endTime);
                                        }
                                    }}
                                />
                            </div>
                            <div className={styles.inputContainer}>
                                <div className={styles.inputLabel}>al: </div>
                                <MayPDatePicker
                                    className={styles.datepicker}
                                    format="MM/yyyy"
                                    value={this.state.endTime}
                                    cancelLabel="Annulla"
                                    okLabel="Imposta"
                                    ampm={false}
                                    error={this.state.invalidDateMessageEnd}
                                    helperText={this.state.invalidDateMessageEnd}
                                    disableFuture={true}
                                    KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                                    InputProps={{
                                        disableUnderline: true,
                                        placeholder: "00/0000",
                                        style: {
                                            border: "0",
                                            color: "#A6A6A6",
                                            borderRadius: "30px",
                                            boxShadow: "0px 3px 6px #00000029",
                                            padding: "0 16px",
                                            margin: 0,
                                        }
                                    }}
                                    onChange={(date) => {
                                        this.setState({
                                            endTime: date,
                                            invalidDateMessageEnd: ""
                                        })
                                    }}
                                    onBlur={(event) => {
                                        if (event.target.value === "") {
                                            this.setState({
                                                invalidDateMessageEnd: ""
                                            })
                                            this.changeDateFilter(this.state.startTime, null)
                                            return;
                                        }
                                        const s = event.target.value.split("/")
                                        const date = new Date(`${s[0]}/01/${s[1]}`)

                                        if (isNaN(date)) {
                                            this.setState({
                                                invalidDateMessageEnd: "Data non valida"
                                            })
                                            return;
                                        }

                                        if (date && this.state.startTime && date.getTime() < this.state.startTime) {
                                            this.setState({
                                                endTime: date,
                                                invalidDateMessageEnd: "Data fine precedente a data inizio"
                                            })
                                            return;
                                        }

                                        if (date && this.state.startTime && date.getTime() > this.state.startTime) {
                                            this.setState({
                                                invalidDateMessageStart: ""
                                            })
                                        }


                                        if (date instanceof Date) {
                                            this.changeDateFilter(this.state.startTime, date);
                                        }
                                    }}
                                />
                            </div>
                            <div className={styles.tableActionContainer}>
                                <MayPButtonBlue
                                    disabled={this.state.invalidDateMessageEnd || this.state.invalidDateMessageStart || this.state.type === null}
                                    onClick={() => {
                                        this.cursor = null;
                                        this.setState({
                                            viewedDocuments: []
                                        }, () => {
                                            this.fetchReports()
                                        })
                                    }}
                                >
                                    Cerca
                                </MayPButtonBlue>
                            </div>
                        </div>
                        <div className={styles.filterContainer}>
                            {/* <div className={styles.filterHeader}>
                                <div className={styles.filterTitle}>Filtri</div>
                                <MayPCheck
                                    appearance="checkbox"
                                    label="Selezione multipla"
                                    onChange={(isChecked) => {
                                        this.setState({
                                            multipleSelect: isChecked,
                                        })
                                    }}
                                    checked={this.state.multipleSelect}
                                ></MayPCheck>
                            </div> */}

                            <div className={styles.filterRow}>
                                <div className={styles.filters}>
                                    {this.state.filters.map((filter) => {
                                        return (
                                            <>
                                                <MayPSearchFilter
                                                    key={filter.id}
                                                    label={filter.name}
                                                    onClose={() => {
                                                        this.removeSearchFilter(filter.id)
                                                    }}></MayPSearchFilter>
                                            </>
                                        )
                                    })
                                    }
                                </div>
                                {this.state.multipleSelect &&
                                    <div className={styles.selectionOptions}>
                                        <MayPRadio
                                            label="Seleziona tutti"
                                            labelPlacement="start"
                                            checked={this.state.selectAll}
                                            classes={{
                                                label: styles.radioLabel,
                                            }}
                                            radioProps={{
                                                style: { color: '#606060' }
                                            }}
                                            onChange={() => {
                                                this.setState({
                                                    selectedRows: this.state.selectedRows.map((r) => { return true }),
                                                    selectAll: true
                                                })
                                            }}
                                        />
                                        <div>
                                            Scarica selezionati
                                            <IconButton disabled={!this.state.selectedRows.includes(true)} onClick={async () => {
                                                const toDownload = []
                                                this.state.selectedRows.forEach((s, i) => {
                                                    if (s) {
                                                        toDownload.push(this.state.documents[i])
                                                    }
                                                })
                                                try
                                                {
                                                    await zipFromUrls(toDownload.map(d => d.storageRef), toDownload.map(d => `${d.id}.${d.contentType}`), toDownload.map(d => d.contentType))
                                                }
                                                catch(e){
                                                    this.setState({
                                                        showDialog: true, 
                                                        dialogTitle: "Errore",
                                                        dialogMessage: `${e.message}`,
                                                        dialogOnClose: () => {this.setState({showDialog: false})}
                                                    })
                                                }
                                            }}>
                                                <img className={styles.downloadIcon} alt="download selected" src={DownloadIc} />
                                            </IconButton>
                                        </div>
                                        <div className={styles.cancelSelection}>
                                            Annulla selezione
                                            <IconButton
                                                onClick={() => {
                                                    this.setState({
                                                        selectedRows: this.state.documents.map((d) => { return false }),
                                                        selectAll: false
                                                    })
                                                }}>
                                                <img className={styles.cancelSelectionIcon} alt="cancel selection" src={CancelIc} />
                                            </IconButton>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={styles.tableMainContainer}>
                            <div className={styles.tableWhiteBox}>
                                <div className={styles.tableTransactionsContainer}>
                                    {!this.state.documents.length &&
                                        !this.state.waitingDocuments && (
                                            <div className={styles.tableInfo}>
                                                Attualmente non sono presenti documenti
                                            </div>
                                        )}
                                    <MayPTable
                                        pagination={true}
                                        labelRowsPerPage="Documenti per pagina"
                                        loading={this.state.waitingDocuments}
                                        tableHeaders={this.state.multipleSelect ?
                                            [
                                                "Emissione",
                                                "Resoconto",
                                                "Periodo",
                                                "Consultabile fino al",
                                                "Seleziona",
                                                "Download",
                                            ] :
                                            [
                                                "Emissione",
                                                "Resoconto",
                                                "Periodo",
                                                "Consultabile fino al",
                                                "Download",
                                            ]
                                        }
                                        dataKeys={this.state.multipleSelect ?
                                            [
                                                "createdAt",
                                                "viewType",
                                                "period",
                                                "expiredAt",
                                                "selected",
                                                "download",
                                            ] :
                                            [
                                                "createdAt",
                                                "viewType",
                                                "period",
                                                "expiredAt",
                                                "download",
                                            ]
                                        }
                                        tableData={{
                                            rows: this.state.viewedDocuments || [],
                                            totalRows: -1,
                                        }}
                                        rowsPerPage={this.limit}
                                        updateData={(p, l) => {
                                            if (l !== this.limit) {
                                                this.limit = l;
                                                this.cursor = null;
                                            }
                                            return this.fetchReports();
                                        }}
                                        onRowClick={(item, row, column) => {
                                            this.setState({
                                                openDialog: true,
                                                dialogMessage: {},
                                                dialogActions: [],
                                                dialogClose: async (action) => {
                                                    this.setState({
                                                        openDialog: false,
                                                    });
                                                }
                                            })
                                        }}
                                        selectedRows={this.state.selectedRows}
                                        onPageChange={(viewedData) => {
                                            this.setState({
                                                documents: viewedData,
                                                selectedRows: viewedData.map(() => false),
                                                selectAll: false
                                            })
                                        }}
                                        onSelected={(rowIndex, checked) => {
                                            this.setState((prev) => {
                                                console.log("Selected", rowIndex, checked)
                                                prev.selectedRows = prev.selectedRows.map((sr, i) => {
                                                    if (i === rowIndex) {
                                                        return checked
                                                    }
                                                    return sr
                                                })

                                                if (prev.selectAll && !checked) {
                                                    prev.selectAll = false
                                                }
                                                return {
                                                    ...prev
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <MayPDialog
                        forMerchant={true}
                        open={this.state.showDialog}
                        title={this.state.dialogTitle}
                        message={this.state.dialogMessage}
                        actions={["Ok"]}
                        onClose={this.state.dialogOnClose}
                    />
                </content>
            </MayPPageTemplate>
        );
    }
}

export default Documents;
