import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import MayPWithSpinner from "../../common/components/utils/MayPWithSpinner";
import { MayPButtonTextWrap } from "../../common/components/utils/MayPButtonText";
import { connect } from "react-redux";

import { getAuth, sendEmailVerification } from "firebase/auth";

const ButtonPassword = MayPButtonTextWrap({
  root: {
    fontSize: "16px",
    letterSpacing: "1.6px",
    fontWeight: "normal",
    lineHeight: "22px",
    color: "#00E091",
    fontFamily: '"Giorgio", Arial, sans-serif',
    "WebkitTextStroke": "0.7px #00E091",
  },
});

class VerificaEmail extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;

    this.onBack = this.onBack.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
    this.sendEmailVerification = this.sendEmailVerification.bind(this);

    this.state = {
      formValid: false,
      waiting: false,
      error: 0,
      errorMessage: "",
      currentUser: getAuth().currentUser,
    };
  }

  onBack() {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.page, "goto-register", {});
    }
  }
  verifyEmail() {
    this.setState({
      waiting: true,
    });
    this.state.currentUser
      .reload()
      .then(() => {
        const currentUser = getAuth().currentUser
        console.log("refreshed", currentUser);
        this.setState(
          {
            waiting: false,
            currentUser: currentUser,
          },
          () => {
            if (this.state.currentUser.emailVerified) {
              if (this.props.onSubmit) {
                this.props.onSubmit(
                  this.props.page,
                  "on-emailverified",
                  this.state.currentUser
                );
              }
            } else {
              this.setState({
                error: 1,
                errorMessage:
                  "Devi verificare il tuo indirizzo e-mail prima di continuare",
              });
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
        let message = "";
        if (error.code === "auth/network-request-failed") {
          message =
            "Errore di rete. Assicurati di essere connesso ad internet.";
        } else {
          message = "Si è verificato un errore, riprova";
        }

        this.setState({
          waiting: false,
          error: 1,
          errorMessage: message,
        });
      });
  }

  sendEmailVerification() {
    let m = "";
    let isError = 0;
    this.setState(
      {
        waiting: true,
        currentUser: getAuth().currentUser,
      },
      () => {
        if (this.state.currentUser && !this.state.currentUser.emailVerified) {
          sendEmailVerification(this.state.currentUser)
            .then(() => {
              console.log("verification email sent");
            })
            .catch((error) => {
              console.log(error);
              if (error.code !== "auth/too-many-requests") {
                isError = 1;
                m = "Non è stato possibile inviare la mail di verifica";
              } else {
                isError = 0;
                m = "";
              }
            })
            .finally(() => {
              this.setState({
                error: isError,
                errorMessage: m,
                waiting: false,
              });
            });
        } else if (
          this.state.currentUser &&
          this.state.currentUser.emailVerified
        ) {
          if (this.props.onSubmit) {
            this.props.onSubmit(
              this.props.page,
              "on-emailverified",
              this.state.currentUser
            );
          }
        }
      }
    );
  }

  componentDidMount() {
    super.componentDidMount();
    console.log("mounted");  
    if (this.state.currentUser && !this.state.currentUser.emailverified) {
      this.sendEmailVerification();
    } else {
      this.setState({
        currentUser: getAuth().currentUser
      })
    }
  }

  componentDidUpdate(prevprops) {
    console.log("update");
    if (!prevprops.appLoaded && this.props.appLoaded) {
      this.sendEmailVerification();
    }
  }

  render() {
    return (
      <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
        <h3 style={{ marginTop: "50px" }}>Verifica e-mail</h3>

        <MayPWithSpinner waiting={this.state.waiting}>
          {this.state.error === 0 && (
            <div className="message">
              Abbiamo inviato una e-mail all'indirizzo:
              <br />
              <b>{this.props.userEmail}</b>.<br />
              Apri la e-mail e clicca su verifica per attivare l'account.
            </div>
          )}
          {this.state.error !== 0 && (
            <div className="message error">{this.state.errorMessage}</div>
          )}
        </MayPWithSpinner>
        <div style={{ marginTop: "40px", textAlign: "center", width: "100%" }}>
          <MayPButtonBlue
            disabled={this.state.waiting}
            onClick={this.verifyEmail}
          >
            Continua
          </MayPButtonBlue>
        </div>
        <div
          style={{
            // marginBottom: "40px",
            flexGrow: "1",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div
            className="message"
            style={{ marginTop: "40px", marginBottom: "8px" }}
          >
            E-mail non corretta?
          </div>
          <div style={{ marginTop: "0px", textAlign: "center", width: "100%" }}>
            <ButtonPassword disabled={this.state.waiting} onClick={this.onBack}>
              Ripeti la registrazione
            </ButtonPassword>
          </div>
          <div
            className="message"
            style={{ marginTop: "40px", marginBottom: "8px" }}
          >
            Non hai ricevuto l'e-mail?
          </div>
          <div style={{ marginTop: "0px", textAlign: "center", width: "100%" }}>
            <ButtonPassword
              disabled={this.state.waiting}
              onClick={this.sendEmailVerification}
            >
              Reinvia e-mail
            </ButtonPassword>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    db: state.app.db,
    userEmail: state.user.email,
    appLoaded: state.app.loaded,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificaEmail);
