import { useCallback, useEffect, useState } from "react";
import MayPSearchInput from "./MayPSearchInput";
import MayPTable from "./MayPTable";
import MayPWithSpinner from "./MayPWithSpinner";
import StoreLib from "../../../libs/store";
import styles from "./MayPSearchStore.module.css";
import { StoreType } from "../../libs/utils";
import { MPErrors, parseError } from "../../../libs/errors";
import MayPStoreBadge from "./MayPStoreBadge";

let cursor = "";
let limit = 10;
let onlyEnabledStores = false;
let filterValue = null;
let stores = [];

export default function MayPSearchStore(props) {
  const [loadingStores, setLoadingStores] = useState(false);

  useEffect(() => {
    limit = props.fixedLimit || 10;
    loadStores();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    return () => {
      cursor = "";
      limit = 10;
      onlyEnabledStores = false;
      filterValue = null;
      stores = [];
    }
  }, []);

  const onRowClick = useCallback(
    (item) => {
      if (item && item._id && props.onStoreClick) {
        props.onStoreClick(item);
      }
    },
    [props]
  );

  const loadStores = useCallback(async () => {
    if (loadingStores) {
      return;
    }

    setLoadingStores(true);
    let filter, startAfter, data, startAt, endAt;

    if (stores.length > 0 && cursor) {
      startAfter = cursor;
    }

    if (filterValue) {
      if (filterValue.startsWith("id:")) {
        filter = "_id";
        filterValue = filterValue.replace("id:", "").trim();
      } else {
        filter = "_q";
        filterValue = filterValue.toLowerCase();
      }
    }

    const storeLib = new StoreLib();
    console.log({
      limit,
      filter,
      filterValue,
      startAfter,
      startAt,
      endAt,
      onlyEnabledStores,
    });
    try {
      const res = await storeLib.getStoresPaginated(
        limit,
        filter,
        filterValue,
        startAfter,
        startAt,
        endAt,
        onlyEnabledStores
      );

      if (res.success === false) {
        throw res.error;
      }

      data = res.data;
      cursor = data[data.length - 1]._id;

      if (props.mapping) {
        data = res.data.map(props.mapping);
        for (let i = 0; i < data.length; i++) {
          data[i]["_id"] = res.data[i]["_id"];
          data[i]["key"] = res.data[i]["_id"];
        }
      } else {
        data = res.data.map((b) => {
          return {
            ...b,
            key: b._id,
            display_store_probability: `1/${b.store_probability} (${
              Math.round((100 / b.store_probability) * 100) / 100
            }%)`,
            store_address:
              b.store_type === StoreType.PHYSICAL
                ? `${b.store_address} ${b.store_address_number}`
                : b.store_web_site,
            avatar: b.profilePhotoUrl,
            avatarName: b.store_name[0].toUpperCase(),
            displayId: b._id,
            status: b.deleted
              ? "Cancellato"
              : b.enabled
              ? "Abilitato"
              : "Disabilitato",
            displayStoreType:
              b.store_type === StoreType.PHYSICAL
                ? "Punto Vendita"
                : "eCommerce",
          };
        });
      }
      if (stores.length === 0) {
        // first data load
        // setStores(data);
        console.log("first");
        stores = data;
      }
    } catch (error) {
      const { message, code } = parseError(error);
      console.log({ message, code });
      if (code !== MPErrors.FirebaseDataNotFound.code) {
        // setStores([]);
        stores = [];
      }
    } finally {
      setLoadingStores(false);
    }
    console.log(data);
    return data;
  }, [props.mapping, loadingStores]);

  const onSearch = useCallback(
    (searchText) => {
      cursor = null;
      stores = [];
      filterValue = searchText || null;
      loadStores();
    },
    [loadStores]
  );

  return (
    <div>
      <div
        className={
          props.embedded ? styles.embeddedBarContainer : styles.barContainer
        }
      >
        <MayPSearchInput
          placeholder={"Cerca..."}
          onSearch={onSearch}
          onCancel={onSearch}
        />
      </div>

      <MayPWithSpinner waiting={loadingStores}>
        <MayPTable
          tableHeaders={
            props.headers || [
              "",
              "ID",
              "Nome",
              "Categoria",
              "Indirizzo",
              "Città",
              "Cap",
              "Probabilità",
              "stato",
              "Tipologia",
            ]
          }
          dataKeys={
            props.keys || [
              "avatar",
              "displayId",
              "store_name",
              "store_category",
              "store_address",
              "store_city",
              "store_cap",
              "display_store_probability",
              "status",
              "displayStoreType",
            ]
          }
          tableData={{ rows: stores || [], totalRows: -1 }}
          labelRowsPerPage="Negozi per pagina"
          rowsPerPage={limit}
          updateData={(p, l) => {
            console.log(l, limit);
            if (l !== limit) {
              cursor = "";
              limit = l;
            }
            return loadStores();
          }}
          onRowClick={onRowClick}
          pagination={true}
          fixedLimit={props.fixedLimit}
          MiniComponent={MayPStoreBadge}
          miniComponentProps= {{
            onItemClick: onRowClick
          }}
          miniComponentKeys={[
            "storePhotoUrl",
            "_id",
            "store_name",
            "store_category",
            "store_address",
            "store_city",
            "store_cap",
            "store_web_site",
            "registered_to_contest",
            "store_type",
            "profilePhotoUrl",
          ]}
        />
      </MayPWithSpinner>
    </div>
  );
}
