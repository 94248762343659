import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withStyles } from "@material-ui/core/styles";

const _style = {
  root: {
    textTransform: "none",
    backgroundColor: "transparent",
    color: "inherit",
    fontSize: "inherit",
    textDecoration: "none",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontFamily: "inherit",
  },
};

class MayPButtonBase extends React.Component {
  constructor(props) {
    super(props);
    const { classes, other } = props;
    this.classes = classes;
    this.props = other;
  }

  render() {
    return <ButtonBase className={this.props.classes.root} {...this.props} />;
  }
}

const MayPButtonTextWrap = (props) => {
  let style = {
    root: {},
    disabled: {},
    focusVisible: {},
  };
  style.root = { ...(_style.root || {}), ...(props.root || {}) };
  style.disabled = { ...(_style.disabled || {}), ...(props.disabled || {}) };
  style.focusVisible = {
    ...(_style.focusVisible || {}),
    ...(props.focusVisible || {}),
  };

  //Object.assign(style, _style, props);
  return withStyles(style)(MayPButtonBase);
};

export default MayPButtonTextWrap({}); //(MayPButtonBase)

export { MayPButtonTextWrap };
