import { useChildrens } from "../../libs/utils";
import styles from "./MayPPageTemplate.module.css";

export default function MayPPageTemplate({
  title,
  description,
  children,
  ...props
}) {
  const childs = useChildrens({
    types: ["action", "content"],
    defaultName: "content",
    childs: children,
  });
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.pageTitle}>{title}</div>
      </div>
      {(childs.action || description) && (
        <>
          <div className={styles.secondHeaderContainer}>
            <div className={styles.pageDescription}>{description}</div>
            <div className={styles.pageAction}>{childs.action}</div>
          </div>
        </>
      )}
      <div className={styles.content}>{childs.content}</div>
    </div>
  );
}

/*
 <MayPPageTemplate title="test" description="descrizione di prova ...">
  <action>
    <> 
      ....
    </>
  </action>
  <content>
    <> 
      ....
    </>
  </content>
 </MayPPageTemplate
 */
