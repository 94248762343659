import { useCallback, useEffect, useState } from "react";
import MayPTable from "../common/components/utils/MayPTable";
import styles from "./developers.module.css";
import UserLib from "../libs/user";
import MayPWithSpinner from "../common/components/utils/MayPWithSpinner";
import { APIKEY_ENVIRONMENT, APIKEY_STATUS } from "../common/libs/utils";
import { MayPButtonTextWrap } from "../common/components/utils/MayPButtonText";
import MayPDialog from "../common/components/utils/MayPDialog";
import MayPButtonBlue from "../common/components/utils/MayPButton";
import MayPPageTemplate from "../common/components/utils/MayPPageTemplate";

import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const TableButton = MayPButtonTextWrap({
  root: {
    fontSize: "14px",
    letterSpacing: "1.6x",
    fontWeight: "normal",
    fontFamily: '"Giorgio", Arial, sans-serif',
    WebkitTextStroke: "0.7px #00a2e0",
    color: "#00a2e0",
    textDecoration: "underline"
  },
  disabled: {
    color: "#cccccc",
  },
});

let environment = APIKEY_ENVIRONMENT.PRODUCTION;
let selectedStore = "-";

export default function Developers(props) {
  const [apiKeys, setApiKeys] = useState([]);
  const [displayApiKeys, setDisplayApiKeys] = useState([]);
  const [loadingApiKeys, setLoadingApiKeys] = useState(true);

  const [dialogSettings, setDialogSettings] = useState({
    open: false,
    title: null,
    message: null,
    actions: [],
    onClose: null,
  });

  const loadApiKeys = useCallback(async () => {
    setLoadingApiKeys(true);
    const userLib = new UserLib();
    const apiKeysResult = await userLib.getApiKeys();
    setLoadingApiKeys(false);

    if (!apiKeysResult.success) {
      console.log(apiKeysResult.error);
      setApiKeys([]);
      return;
    }

    setApiKeys(apiKeysResult.data);
  }, []);

  useEffect(() => {
    loadApiKeys();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      environment = APIKEY_ENVIRONMENT.PRODUCTION;
      selectedStore = "-";
    }
  }, []);

  const ShowKeys = (props) => {
    const { keys } = props;
    const [copied, setCopied] = useState({
      privateKey: false,
      publicKey: false,
    });

    return (
      <div className={styles.showKeysContainer}>
        <TextField
          label="Public Key"
          fullWidth={true}
          defaultValue={keys.publicKey}
          helperText={copied.publicKey ? "Copiato!" : ""}
          style={{
            width: "100%",
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    window.navigator.clipboard.writeText(keys.publicKey);
                    setCopied((prev) => {
                      return {
                        ...prev,
                        publicKey: true,
                      };
                    });
                  }}
                >
                  <FileCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Private Key"
          defaultValue={keys.privateKey}
          fullWidth={true}
          helperText={copied.privateKey ? "Copiato!" : ""}
          style={{
            width: "100%",
          }}
          minRows={2}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    window.navigator.clipboard.writeText(keys.privateKey);
                    setCopied((prev) => {
                      return {
                        ...prev,
                        privateKey: true,
                      };
                    });
                  }}
                >
                  <FileCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (apiKeys) {
      const data = apiKeys
        .map((ak, i) => {
          return {
            ...ak,
            key: i,
            disabled: ak.state === APIKEY_STATUS.REVOKED,
            displayCreated: new Date(ak.createdAt).toLocaleString("en-GB"),
            environment:
              ak.environment === APIKEY_ENVIRONMENT.PRODUCTION
                ? "Produzione"
                : "Sandbox",
            apiKey: (
              <TableButton
                onClick={() => {
                  setDialogSettings({
                    open: true,
                    title: "API Key",
                    message: <ShowKeys keys={ak} />,
                    actions: ["OK"],
                    onClose: null,
                  });
                }}
              >
                Visualizza Chiavi
              </TableButton>
            ),
            revoke:
              ak.state === APIKEY_STATUS.ACTIVE ? (
                <TableButton
                  onClick={() => {
                    setDialogSettings({
                      open: true,
                      title: "Revoca API Key",
                      message:
                        "Dopo la revoca, la chiave non avrà accesso all'API Maypay. Sei sicuro di voler revocare l'API Key?",
                      actions: ["Annulla", "Revoca"],
                      onClose: async (e) => {
                        console.log("dialog", e);
                        setDialogSettings({
                          ...dialogSettings,
                          open: false,
                        });

                        if (e === "Revoca") {
                          const userLib = new UserLib();
                          const result = await userLib.revokeApiKey(
                            ak.apiKeyId
                          );

                          if (!result.success) {
                            console.log(result.error);
                            setDialogSettings({
                              open: true,
                              title: "Si è verificato un errore",
                              message:
                                "Non è stato possibile revocare una nuova API key",
                              actions: ["OK"],
                              onClose: null,
                            });
                            return;
                          }

                          apiKeys[
                            apiKeys.findIndex(
                              (apiKey) => apiKey.apiKeyId === ak.apiKeyId
                            )
                          ].state = APIKEY_STATUS.REVOKED;

                          setApiKeys((prev) => {
                            prev[
                              prev.findIndex(
                                (apiKey) => apiKey.apiKeyId === ak.apiKeyId
                              )
                            ].state = APIKEY_STATUS.REVOKED;
                            return [...prev];
                          });

                          setDialogSettings({
                            open: true,
                            title: "API Key Revocata",
                            message: "",
                            actions: ["OK"],
                            onClose: null,
                          });
                        }
                      },
                    });
                  }}
                >
                  Revoca
                </TableButton>
              ) : (
                ""
              ),
          };
        })
        .sort((a, b) => {
          if (a.state === b.state) {
            if (a.createdAt >= b.createdAt) {
              return -1;
            }
            return 1;
          }

          return a.state === APIKEY_STATUS.ACTIVE ? -1 : 1;
        });

      setDisplayApiKeys(data);
    } else {
      setDisplayApiKeys([]);
    }
  }, [apiKeys, dialogSettings]);

  const AddMessage = (props) => {
    const [env, setEnv] = useState(APIKEY_ENVIRONMENT.PRODUCTION);
    // const [checked, setChecked] = useState(true);
    // const [store, setStore] = useState(null);

    useEffect(() => {
      environment = APIKEY_ENVIRONMENT.PRODUCTION;
    }, []);

    return (
      <div className={styles.addContainer}>
        <div>Seleziona la tipologia di chiave.</div>
        <TextField
          select
          fullWidth={true}
          value={env}
          onChange={(e) => {
            setEnv(e.target.value);
            environment = e.target.value;
            console.log(environment);
          }}
          label={"Tipo"}
          variant={"outlined"}
          SelectProps={{
            native: true,
          }}
        >
          <option value={APIKEY_ENVIRONMENT.PRODUCTION}>{"Produzione"}</option>
          <option value={APIKEY_ENVIRONMENT.SANDBOX}>{"Sandbox"}</option>
        </TextField>
        {/* 
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={() => {
                setChecked((prev) => !prev);
                setStore(null);
                selectedStore = "-";
              }}
              color="primary"
            />
          }
          label={"Associata all'esercente"}
        />

        {!checked && (
          <>
            <div>
              Seleziona il negozio a cui vuoi associare la chiave dalla tabella
              sottostante.
            </div>
            <div>Negozio selezionato: {store ? store.store_name : "-"}</div>

            <MayPSearchStore
              embedded={true}
              fixedLimit={4}
              onStoreClick={(store) => {
                setChecked(false);
                setStore({
                  storeId: store._id,
                  store_name: store.store_name,
                });
                selectedStore = store._id;
              }}
              headers={["Nome", "Indirizzo", "Città"]}
              keys={["store_name", "store_address", "store_city"]}
              mapping={(store) => {
                return {
                  store_name: store.store_name,
                  store_address:
                    store.store_address + " " + store.store_address_number,
                  store_city: store.store_city,
                };
              }}
            />
          </>
        )}

        {checked && (
          <div className={styles.merchantMessage}>
            Questa chiave potrà essere usata per qualsiasi negozio.
          </div>
        )} */}
      </div>
    );
  };

  const onAddClick = useCallback(() => {
    setDialogSettings({
      open: true,
      title: "Aggiungi API Key",
      message: <AddMessage />,
      actions: ["Annulla", "Conferma"],
      onClose: async (e) => {
        setDialogSettings({
          ...dialogSettings,
          open: false,
        });

        if (e === "Conferma") {
          const userLib = new UserLib();
          const result = await userLib.createApiKey(
            selectedStore === "-" ? null : selectedStore,
            environment
          );

          if (!result.success) {
            console.log(result.error);
            setDialogSettings({
              open: true,
              title: "Si è verificato un errore",
              message: "Non è stato possibile aggiungere una nuova API key",
              actions: ["OK"],
              onClose: null,
            });
            return;
          }

          setDialogSettings({
            open: true,
            title: "Nuova API Key aggiunta",
            message:
              "Comunicala agli sviluppatori tramite un canale di comunicazione affidabile e sicuro",
            actions: ["OK"],
          });

          // apiKeys.push(result.data);
          // console.log("key aggiunta", apiKeys)
          console.log(result.data)
          setApiKeys((prev) => {
            prev.push(result.data);
            return [...prev];
          });
        }
      },
    });
  }, [dialogSettings]);

  const defaultClose = useCallback(() => {
    setDialogSettings({
      ...dialogSettings,
      open: false,
    });
  }, [dialogSettings]);

  return (
    <MayPPageTemplate
      title="Sviluppatori"
      description="Qui è possibile creare la chiave API necessaria agli sviluppatori per l'integrazione di Maypay nei sistemi dell'esercente."
    >
      <action>
        <div className={styles.buttonContainer}>
          <MayPButtonBlue onClick={onAddClick}>Aggiungi API Key</MayPButtonBlue>
        </div>
      </action>
      <content>
        <div className={styles.mainContainer}>
          <div className={styles.sectionContainer}>
            <div className={styles.tableContainer}>
              <MayPWithSpinner waiting={loadingApiKeys}>
                <MayPTable
                  tableHeaders={[
                    "Riferimento",
                    "Data Creazione",
                    // "ID Negozio",
                    "Stato",
                    "Ambiente",
                    "API Key",
                    "Revoca",
                  ]}
                  dataKeys={[
                    "apiKeyId",
                    "displayCreated",
                    // "storeId",
                    "state",
                    "environment",
                    "apiKey",
                    "revoke",
                  ]}
                  tableData={{
                    rows: displayApiKeys || [],
                    totalRows: -1,
                  }}
                  updateData={(p, l) => {}}
                  onRowClick={() => {}}
                  pagination={false}
                />
              </MayPWithSpinner>
            </div>
          </div>
          <MayPDialog
            forMerchant={true}
            open={dialogSettings.open}
            title={dialogSettings.title}
            message={dialogSettings.message}
            actions={dialogSettings.actions}
            onClose={dialogSettings.onClose || defaultClose}
          />
        </div>
      </content>
    </MayPPageTemplate>
  );
}
