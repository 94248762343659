import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CustomIcon from "../../common/components/utils/CustomIcon";
import MayPButtonText from "../../common/components/utils/MayPButtonText";
import MayPDialog from "../../common/components/utils/MayPDialog";
import ProfileIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_profile_Tavola disegno 1.svg";
import HomeIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_home.svg";
import StoreIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_shopping.svg";
import PaymentIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_payment.svg";
import TransactionIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_transaction_new.svg";
import CreditIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_credit.svg";
import PrivacyIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_privacy_policy.svg";
import TermsIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_privacy.svg";
import AssistanceIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_assistance.svg";
import FaqIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_faq.svg";
import ExitIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_exit.svg";
import ProbabilityIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/maypay_probability.svg";
import DevIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_developers.svg";
import DocumentsIc from "../../assets/icons/@MAYPAY_UIKIT_MERCHANT/mp_documents.svg"

import { Browser } from "@capacitor/browser";
import { Typography, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { app } from "../../appenv";

const styleText = {
  selected: {
    WebkitTextStroke: "0.3px #00a2e0",
    color: "#00a2e0",
  },
  notSelected: {
    WebkitTextStroke: "0.3px",
    color: "#343434",
  },
};

class MayPListText extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.classes = props.classes;
  }

  render() {
    return (
      <ListItemText
        disableTypography={true}
        className={
          this.props.selected
            ? this.props.classes.selected
            : this.props.classes.notSelected
        }
        primary={
          <Typography
            variant="body2"
            style={{
              fontFamily: "Giorgio",
              letterSpacing: "1.1px",
              fontSize: "14px",
            }}
          >
            {this.props.primary}
          </Typography>
        }
      />
    );
  }
}

const MayPListTextItem = withStyles(styleText)(MayPListText);

class Menu extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      showDialog: false,
      currentLocation: this.props.location.pathname,
      items: [
        {
          label: "Home",
          action: "on-page",
          icon: HomeIc,
          type: "item",
        },
        {
          label: "Gestisci Negozi",
          action: "on-page",
          icon: StoreIc,
          type: "item",
        },
        {
          label: "Account",
          action: "on-page",
          icon: ProfileIc,
          type: "item",
        },
        {
          label: "Probabilità",
          action: "on-page",
          icon: ProbabilityIc,
          type: "item",
        },
        {
          label: "Preferenze di accredito",
          action: "on-page",
          icon: PaymentIc,
          type: "item",
        },
        {
          label: "Cronologia Transazioni",
          action: "on-page",
          icon: TransactionIc,
          type: "item",
        },
        {
          label: "Documenti Contabili",
          action: "on-page",
          icon: DocumentsIc,
          type: "item",
        },
        {
          label: "Accrediti",
          action: "on-page",
          icon: CreditIc,
          type: "item",
        },
        {
          type: "divider",
        },
        {
          label: "Sviluppatori",
          action: "on-page",
          icon: DevIc,
          type: "item",
        },
        {
          type: "divider",
        },
        {
          label: "Termini e Condizioni",
          action: "terms",
          icon: TermsIc,
          type: "item",
        },
        {
          label: "Privacy Policy",
          action: "privacy",
          icon: PrivacyIc,
          type: "item",
        },
        {
          label: "Domande Frequenti",
          action: "faq",
          icon: FaqIc,
          type: "item",
        },
        {
          label: "Assistenza",
          action: "help",
          icon: AssistanceIc,
          type: "item",
        },
        {
          label: "Esci",
          action: "logout",
          icon: ExitIc,
          type: "item",
        },
        {
          type: "divider",
        },
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        currentLocation: this.props.location.pathname,
      });
    }
  }

  render() {
    return (
      <div className="menuContainer">
        <List>
          {this.state.items.map((item, index) => {
            if (item.type === "item") {
              return (
                <ListItem
                  button
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                  }}
                  key={"item-" + index}
                  onClick={async (evt) => {
                    console.log(item);
                    if (item.action === "logout") {
                      this.setState({
                        showDialog: true,
                      });
                    } else {
                      if (item.action === "terms") {
                        await Browser.open({
                          url: "https://www.maypay.com/termini-condizioni-esercenti/",
                        });
                      } else if (item.action === "privacy") {
                        await Browser.open({
                          url: "https://www.maypay.com/privacy-policy/",
                        });
                      } else if (item.action === "help") {
                        await Browser.open({
                          url: "https://www.maypay.com/assistenza-esercenti/",
                        });
                      } else if (item.action === "faq") {
                        await Browser.open({
                          url: "https://www.maypay.com/domande-frequenti/",
                        });
                      }
                      if (this.props.onItemClick) {
                        this.props.onItemClick(
                          this.props.page,
                          item.action,
                          item.label
                        );
                      }
                    }
                  }}
                >
                  <ListItemIcon
                    style={{
                      minWidth: "60px",
                    }}
                  >
                    <CustomIcon
                      icon={item.icon}
                      style={{
                        width: "40px",
                        height: "32px",
                      }}
                    />
                  </ListItemIcon>
                  <MayPListTextItem
                    selected={this.state.currentLocation.includes(item.label)}
                    primary={item.label}
                  />
                </ListItem>
              );
            } else if (item.type === "divider") {
              return <Divider key={"divider-" + index} />;
            } 
            return <></>
          })}
        </List>

        <div className="app-version">
          <MayPButtonText
            style={{
              marginTop: "20px",
            }}
          >
            {`v${app.version}_b${app.build}-${app.environment}`}
            {/* Versione {window.notificationManager.getAppVersion() || "-"} */}
          </MayPButtonText>
        </div>
        <MayPDialog
          forMerchant={true}
          open={this.state.showDialog}
          title="Confermi di voler uscire?"
          actions={["Annulla", "Esci"]}
          onClose={(action) => {
            if (action === "Esci") {
              if (this.props.onItemClick) {
                this.props.onItemClick(this.props.page, "logout", {});
              }
            } else {
              this.setState({
                showDialog: false,
              });
            }
          }}
        />
      </div>
    );
  }
}

export default withRouter(Menu);
