import {
  getFirestore,
  collection,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  getDocs,
  setDoc,
  startAfter,
  startAt,
  endAt,
  limit,
  documentId,
} from "firebase/firestore";
import { getAuth, updateProfile } from "firebase/auth";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadString,
} from "firebase/storage";

import utilsLib, { FirestoreCollection, StoreType } from "../common/libs/utils";
import cfLibs from "./cloudfunction";
import { GQuery } from "./GHash";
import { MPError, MPErrors } from "./errors";

const geofire = require("geofire-common");

export default function a() {
  const utils = utilsLib();
  const cflibs = cfLibs();

  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();

  const geocode = (address) => {
    return new Promise((resolve, reject) => {
      //Try to get latLon from store address
      let geohash = undefined;
      try {
        let geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: address }, function (results, status) {
          console.log("---------", results);
          console.log(status);
          if (status === "OK") {
            geohash = geofire.geohashForLocation([
              results[0].geometry.location.lat(),
              results[0].geometry.location.lng(),
            ]);
            console.log("Geohash", geohash);
            //console.log(_p.geopoint.toJSON());
            const ghash = GQuery.hash([
              results[0].geometry.location.lat(),
              results[0].geometry.location.lng(),
            ]);
            resolve({
              geohash: geohash,
              geopoint: {
                _lat: results[0].geometry.location.lat(),
                _long: results[0].geometry.location.lng(),
              },
              ...ghash,
            });
          } else {
            alert(
              "Geocode was not successful for the following reason: " + status
            );
            reject(status);
          }
        });
      } catch (error) {
        reject(error.message);
      }
    });
  };

  const saveStoreFirebase = async (data, profilePic, storePic) => {
    console.log("Saving store profile...", data);
    const currentUser = auth.currentUser;

    if (currentUser && currentUser.uid) {
      //Update profile of firebase user
      let profilePicURL = currentUser.photoURL || data.profilePic;
      let storePicURL = data.storePic;

      try {
        if (profilePic && profilePic.startsWith("data")) {
          let storeProfilePicRef = ref(
            storage,
            `store/${currentUser.uid}/profile_${data.storeId}.png`
          );
          await uploadString(storeProfilePicRef, profilePic, "data_url");
          profilePicURL = storeProfilePicRef.fullPath;
        }
        if (storePic && storePic.startsWith("data")) {
          let storePicRef = ref(
            storage,
            `store/${currentUser.uid}/store_${data.storeId}.png`
          );
          await uploadString(storePicRef, storePic, "data_url");
          storePicURL = storePicRef.fullPath;
        }
        await updateProfile(currentUser, {
          photoURL: profilePicURL,
        });

        // Update successful, set other profile data in DB .
        const result = Object.assign({}, data, {
          userId: currentUser.uid,
          profilePic: profilePicURL || "",
          storePic: storePicURL || "",
        });

        //Create index for string query
        let _q = new Set();
        _q = utils.extractQueryWords(data.store_name.toLowerCase(), _q);
        let _a = Array.from(_q);
        _a = _a.concat(["*"]);
        _a = _a.concat([data.store_category.toLowerCase()]);

        if (data.store_type === StoreType.PHYSICAL) {
          _a = _a.concat([data.store_cap.toLowerCase()]);
          _a = _a.concat([data.store_city.toLowerCase()]);
  
          const address =
            data.store_name +
            " " +
            data.store_address +
            " " +
            data.store_address_number +
            "," +
            data.store_cap +
            " " +
            data.store_city;
          const _p = await geocode(address);
          console.log(_p);

          result["_p"] = _p;
          result["_q"] = _a;
        } else {
          result["_q"] = _a;
        }


        //await storeRef.set(result);
        const res = await cflibs.upsertStore(result);
        console.log(res);
        if (!res.success) {
          throw res.error;
        }
        //Create store stat subcollection if it doesn't exist
        const statRef = doc(
          collection(doc(collection(db, "store"), data.storeId), "stats"),
          "starred"
        );
        const statDoc = await getDoc(statRef);

        if (!statDoc.exists()) {
          await setDoc(statRef, {
            users: [],
          });
        }

        return { success: true, error: null, data: result };
      } catch (error) {
        console.log("error updating store:", error);
        return { success: false, error: error, data: null };
      }
    } else {
      console.error("current user not defined");
      //error
      return { success: false, error: "currentUser is undefined", data: null };
    }
  };

  const addStoreFirebase = async (data, profilePic, storePic) => {
    console.log("Saving store profile...", data);
    const currentUser = auth.currentUser;

    if (currentUser && currentUser.uid) {
      //Update profile of firebase user
      let profilePicURL = data.profilePic;
      let storePicURL = data.storePic;
      const result = Object.assign({}, data, {
        userId: currentUser.uid,
        profilePic: profilePicURL || "",
        storePic: storePicURL || "",
      });

      try {
        //Create index for string query
        let _q = new Set();
        _q = utils.extractQueryWords(data.store_name.toLowerCase(), _q);
        let _a = Array.from(_q);
        _a = _a.concat(["*"]);
        _a = _a.concat([data.store_category.toLowerCase()]);

        if (data.store_type === StoreType.PHYSICAL) {
          _a = _a.concat([data.store_cap.toLowerCase()]);
          _a = _a.concat([data.store_city.toLowerCase()]);
  
  
          const address =
            data.store_name +
            " " +
            data.store_address +
            " " +
            data.store_address_number +
            "," +
            data.store_cap +
            " " +
            data.store_city;
          const _p = await geocode(address);
          console.log(_p);
          result["_p"] = _p;
          result["_q"] = _a;
        } else {
          result["_q"] = _a;
        }
        // Update successful, set other profile data in DB .

        const res = await cflibs.upsertStore(result);
        console.log(res);
        if (!res.success) {
          throw res.error;
        }
        //Create store stat subcollection if it doesn't exist
        const statRef = doc(
          collection(doc(collection(db, "store"), res.data.storeId), "stats"),
          "starred"
        );
        const statDoc = await getDoc(statRef);

        if (!statDoc.exists()) {
          await setDoc(statRef, {
            users: [],
          });
        }

        if (profilePic && profilePic.startsWith("data")) {
          let storeProfilePicRef = ref(
            storage,
            `store/${currentUser.uid}/profile_${res.data.storeId}.png`
          );
          await uploadString(storeProfilePicRef, profilePic, "data_url");
          profilePicURL = storeProfilePicRef.fullPath;
        }
        if (storePic && storePic.startsWith("data")) {
          let storePicRef = ref(
            storage,
            `store/${currentUser.uid}/store_${res.data.storeId}.png`
          );
          await uploadString(storePicRef, storePic, "data_url");
          storePicURL = storePicRef.fullPath;
        }
        // await updateProfile(currentUser, {
        //   photoURL: profilePicURL,
        // });

        await cflibs.upsertStore({
          storeId: res.data.storeId,
          profilePic: profilePicURL,
          storePic: storePicURL,
        });
        console.log(res);

        return { success: true, error: null, data: res.data.storeId };
      } catch (error) {
        console.log("error updating store:", error);
        return { success: false, error: error, data: null };
      }
    } else {
      console.error("current user not defined");
      //error
      return { success: false, error: "currentUser is undefined", data: null };
    }
  };

  const getStoreProfilePhotoURL = async (storeId) => {
    try {
      const currentUser = auth.currentUser;
      const location = storeId
        ? `store/${currentUser.uid}/profile_${storeId}.png`
        : currentUser.photoURL;
      let profilePicRef = ref(storage, location);
      const url = await getDownloadURL(profilePicRef);
      return url;
    } catch (error) {
      console.log("error getting store profile picture url", error);
    }

    return undefined;
  };

  const readMovements = async (period) => {
    let res = [];
    try {
      const currentUser = auth.currentUser;

      if (currentUser && currentUser.uid) {
        if (period._w !== undefined) {
          for (let m in period._w) {
            let docRef = collection(
              doc(collection(db, "store"), currentUser.uid),
              "operations"
            );
            let queryParameters = [];

            if (period._y !== undefined) {
              queryParameters.push(where("_y", "==", period._y));
            }
            queryParameters = [
              ...queryParameters,
              where("_m", "==", m.substr(1)),
              where("_d", "in", period._w[m]),
            ];
            if (period._w[m].length > 0) {
              const result = await getDocs(query(docRef, queryParameters));
              for (let i = 0; i < result.docs.length; i++) {
                res.push(result.docs[i].data());
              }
            }
          }
        } else if (
          period._d !== undefined &&
          period._m !== undefined &&
          period._y !== undefined
        ) {
          const key = `_y${period._y}_m${period._m}_d${period._d}`;
          let docRef = doc(
            collection(
              doc(collection(db, "store"), currentUser.uid),
              "operations"
            ),
            key
          );
          const result = await getDoc(docRef);
          if (result.exists()) {
            res.push(result.data());
          }
        } else if (period._m !== undefined && period._y !== undefined) {
          const key = `_y${period._y}_m${period._m}`;
          let docRef = doc(
            collection(
              doc(collection(db, "store"), currentUser.uid),
              "operations"
            ),
            key
          );
          const result = await getDoc(docRef);
          if (result.exists()) {
            res.push(result.data());
          }
        } else if (period._y !== undefined) {
          const key = `_y${period._y}`;
          let docRef = doc(
            collection(
              doc(collection(db, "store"), currentUser.uid),
              "operations"
            ),
            key
          );
          const result = await getDoc(docRef);
          if (result.exists()) {
            res.push(result.data());
          }
        }
      }
    } catch (error) {
      console.log("error reading movements", error);
    }

    return res;
  };

  const getBuyerProfilePhotoURL = async (_url) => {
    try {
      let profilePicRef = ref(storage, _url);
      const url = await getDownloadURL(profilePicRef);
      return url;
    } catch (error) {
      console.log("error getting store profile picture url", error);
    }

    return undefined;
  };

  const getStorePhotoURL = async (storeId) => {
    try {
      const currentUser = auth.currentUser;
      const location = storeId
        ? `store/${currentUser.uid}/store_${storeId}.png`
        : `store/${currentUser.uid}/store.png`;

      let storePicRef = ref(storage, location);

      const url = await getDownloadURL(storePicRef);
      return url;
    } catch (error) {
      console.log("error getting store picture url", error);
    }

    return "";
  };

  const getStarredCount = async () => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser && currentUser.uid) {
        let docRef = doc(
          collection(doc(collection(db, "store"), currentUser.uid), "stats"), // CANNOT EXIST AFTER MULTI STORE SUPPORT
          "starred"
        );
        let result = await getDoc(docRef);
        //console.log(result);
        if (result.exists()) {
          return result.data().users.length || 0;
        }
      }
    } catch (error) {
      console.log("error getting pending transactions", error);
      return -1;
    }

    return 0;
  };

  const getPendingTransactions = async () => {
    let transactions = [];
    try {
      const currentUser = auth.currentUser;
      if (currentUser && currentUser.uid) {
        const docQuery = query(
          collection(db, "transactions"),
          where("storeId", "==", currentUser.uid),
          where("state", "==", 0),
          orderBy("startTime")
        );
        let result = await getDocs(docQuery);

        console.log(result);
        for (let i = 0; i < result.docs.length; i++) {
          const item = result.docs[i];
          let t = {
            transactionId: item.id,
            data: item.data(),
          };
          let buyerPicURL = undefined;
          try {
            buyerPicURL = await getBuyerProfilePhotoURL(t.data.buyerPic);
          } catch (error) {
            console.log(error);
          }
          t.data.buyerPicURL = buyerPicURL;

          transactions.push(t);
        }
      }
    } catch (error) {
      console.log("error getting pending transactions", error);
    }

    return { transactions: transactions };
  };

  const getCashRegisterData = async (startTime, endTime) => {
    if (startTime === undefined && endTime === undefined) {
      return { cashRegister: [] };
    }
    let cashRegisters = [];
    const currentUser = auth.currentUser;
    if (currentUser && currentUser.uid) {
      const queryParameters = [];
      const docRef = collection(
        doc(
          collection(
            doc(collection(db, "store"), currentUser.uid),
            "cashregister"
          ),
          "currentCashRegister"
        ),
        "history"
      );
      if (startTime === undefined) {
        queryParameters.push(where("endTime", ">", endTime));
      } else {
        queryParameters.push(where("endTime", "<", startTime));
        queryParameters.push(where("endTime", ">", endTime));
      }
      queryParameters.push(orderBy("endTime"));
      let result = await getDocs(query(docRef, ...queryParameters));

      console.log(result);
      for (let i = 0; i < result.docs.length; i++) {
        const item = result.docs[i];
        cashRegisters.push({ data: item.data() });
      }
    }

    return { cashRegister: cashRegisters };
  };

  const isCashRegisterClosed = async () => {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser && currentUser.uid) {
        let docRef = doc(
          collection(
            doc(collection(db, "store"), currentUser.uid),
            "cashregister"
          ),
          "currentCashRegister"
        );
        let result = await getDoc(docRef);
        //console.log(result);
        if (result.exists()) {
          return result.data().state === "closed";
        } else {
          return true;
        }
      }
    } catch (error) {
      console.log("error getting cash register state", error);
      return -1;
    }

    return 0;
  };

  const getStores = async () => {
    try {
      const res = await getDocs(
        query(
          collection(db, FirestoreCollection.Stores),
          where("userId", "==", getAuth().currentUser.uid)
        )
      );

      if (res.size === 0) {
        return {
          success: false,
          error: "No data",
          data: null,
        };
      }

      const stores = [];
      res.forEach((doc) => {
        const data = doc.data();
        stores.push({
          _id: doc.id,
          ...data,
        });
      });
      return {
        success: true,
        error: null,
        data: stores,
      };
    } catch (error) {
      return {
        success: false,
        error,
        data: null,
      };
    }
  };

  const getStoresPaginated = async (
    _limit,
    filter,
    filterValue,
    _startAfter,
    _startAt,
    _endAt,
    onlyEnabledStores
  ) => {
    try {
      const ref = collection(getFirestore(), FirestoreCollection.Stores);
      const queryParams = [where("userId", "==", getAuth().currentUser.uid)];

      console.log("Searching stores of", getAuth().currentUser.uid);

      if (onlyEnabledStores) {
        queryParams.push(where("enabled", "==", onlyEnabledStores));
      }

      if (filter) {
        if (filter === "_id") {
          queryParams.push(where(documentId(), "==", filterValue));
        } else {
          queryParams.push(where("_q", "array-contains", filterValue));
        }
      } else {
        queryParams.push(orderBy(documentId()));
        if (_startAfter !== undefined) {
          queryParams.push(startAfter(_startAfter));
        }
        if (_startAt !== undefined) {
          queryParams.push(startAt(_startAt));
        }
        if (_endAt !== undefined) {
          queryParams.push(endAt(_endAt));
        }
        if (_limit) {
          queryParams.push(limit(_limit));
        }
      }
      

      const results = await getDocs(query(ref, ...queryParams));

      if (results.docs.length === 0) {
        throw MPError.build(MPErrors.FirebaseDataNotFound);
      }

      const stores = [];

      for (let i = 0; i < results.docs.length; i++) {
        const doc = results.docs[i];
        const profilePhotoUrl = await getStoreProfilePhotoURL(doc.id);
        const storePhotoUrl = await getStorePhotoURL(doc.id);
        const res = {
          _id: doc.id,
          ...doc.data(),
          profilePhotoUrl,
          storePhotoUrl,
        };
        // const res = { _id: doc.id, ...doc.data() };
        stores.push(res);
      }

      console.log(stores)
      return {
        success: true,
        error: null,
        data: stores,
      };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        error: MPError.build(MPErrors.FirebaseStoreError, error),
        data: null,
      };
    }
  };

  const getStoreById = async (storeId) => {
    try {
      const result = await getDoc(
        doc(collection(getFirestore(), FirestoreCollection.Stores), storeId)
      );

      if (!result.exists()) {
        throw MPError.build(MPErrors.FirebaseDataNotFound);
      }
      const profilePhotoUrl = await getStoreProfilePhotoURL(result.id);
      const storePhotoUrl = await getStorePhotoURL(result.id);
      const data = {
        ...result.data(),
        storeId: result.id,
        profilePhotoUrl,
        storePhotoUrl,
      };

      return {
        success: true,
        error: null,
        data,
      };
    } catch (error) {
      return {
        success: false,
        error: MPError.build(MPErrors.FirebaseStoreError, error),
        data: null,
      };
    }
  };

  return {
    saveStoreFirebase: saveStoreFirebase,
    getStoreProfilePhotoURL: getStoreProfilePhotoURL,
    getStorePhotoURL: getStorePhotoURL,
    getBuyerProfilePhotoURL: getBuyerProfilePhotoURL,
    getPendingTransactions: getPendingTransactions,
    getStarredCount: getStarredCount,
    readMovements: readMovements,
    isCashRegisterClosed: isCashRegisterClosed,
    getCashRegisterData: getCashRegisterData,
    getStores,
    getStoresPaginated,
    getStoreById,
    addStoreFirebase,
  };
}
