export const setPresentationVisibility = (show) => ({
  type: "SET_PRESENTATION_VISIBILITY",
  show,
});

export const setCurrentView = (view) => ({
  type: "SET_CURRENT_VIEW",
  view,
});

export const setKeepView = (keep) => ({
  type: "SET_KEEP_VIEW",
  keep,
});
