import styles from "./stores.module.css";
import MayPButtonBlue from "../common/components/utils/MayPButton";
import MayPSearchStore from "../common/components/utils/MayPSearchStore";
import MayPPageTemplate from "../common/components/utils/MayPPageTemplate";


export default function Stores(props) {
  return (
    <MayPPageTemplate
      title="Negozi"
      description="Qui trovi tutti i tuoi negozi. Clicca su un negozio per visualizzare tutti i dettagli."
    >
      <action>
        <div className={styles.buttonContainer}>

        <MayPButtonBlue
          onClick={() => {
            if (props.onAddStore) {
              props.onAddStore();
            }
          }}
        >
          Aggiungi Negozio
        </MayPButtonBlue>
        </div>

      </action>
      <content>
        <div className={styles.mainContainer}>
          {/* <div className="pageTitle">Negozi</div> */}
          <div className={styles.contentContainer}>
            <MayPSearchStore onStoreClick={props.onStoreClick} />
            <div className={styles.buttonContainer}></div>
          </div>
        </div>
      </content>
    </MayPPageTemplate>
  );
}
