import React from "react";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import Avatar from "@material-ui/core/Avatar";
import { Tab, Tabs } from "@material-ui/core";
import detailStyles from "../../common/components/utils/MayPStoreDetails.module.css";
import TransactionsHistory from "../menudrawer/transactions/TransactionsHistory";
import { StoreType } from "../../common/libs/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
class ProfileStorePreview extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      selectedTab: 0,
      opening: this.props.store.store_opening_time || "-",
      description: this.props.store.store_description || "-",
      other: this.props.store.store_other || "-",
      address:
        "https://www.google.com/maps/embed/v1/place?key=AIzaSyA2v0q42asivNWDvc1lR-jeT_cLPieEjHE&q=" +
        this.props.store.store_name +
        "+" +
        this.props.store.store_address +
        "+" +
        this.props.store.store_address_number +
        "+" +
        this.props.store.store_city,
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <>
        <div className="store-profile-header">
          <div className="store-pic-container">
            {this.props.store.storePhotoUrl && (
              <img src={this.props.store.storePhotoUrl} alt=" " />
            )}
          </div>
          <div className="avatar-container">
            <Avatar
              style={{
                width: "128px",
                height: "128px",
                boxShadow: "0px 5px  40px #00000029",
                backgroundColor: "#00A2E0",
                fontSize: "48px",
              }}
              alt=""
              src={this.props.store.profilePhotoUrl}
            >
              {this.props.store.store_name
                ? this.props.store.store_name[0].toUpperCase()
                : ""}
            </Avatar>
          </div>
        </div>

        {this.props.store.store_type === StoreType.PHYSICAL && (
          <div className={detailStyles.previewHeader}>
            <div className={detailStyles.profilePreviewTitle}>
              {this.props.store.store_name}
            </div>
            <div className={detailStyles.profilePreviewAddress}>
              {this.props.store.store_address},
              {this.props.store.store_address_number}
              <br />
              {this.props.store.store_cap} {this.props.store.store_city},{" "}
              {this.props.store.store_state}
              <div className={detailStyles.category}>
                {this.props.store.store_category}
              </div>
            </div>
            {/* <Divider
              style={{
                width: "80%",
                margin: "40px auto",
              }}
            /> */}
          </div>
        )}

        {this.props.store.store_type === StoreType.ONLINE && (
          <div className={detailStyles.previewHeader}>
            <div className={detailStyles.profilePreviewTitle}>
              {this.props.store.store_name}
            </div>
            <div className={detailStyles.profilePreviewAddress}>
              {this.props.store.store_web_site}
            </div>
            <div className={detailStyles.profilePreviewAddress}>
              {this.props.store.store_email
                ? ` ${this.props.store.store_email}`
                : ""}
              <div className={detailStyles.category}>
                {this.props.store.store_category}
              </div>
            </div>
            {/* <Divider
              style={{
                width: "80%",
                margin: "40px auto",
              }}
            /> */}
          </div>
        )}

        {
          <>
            <Tabs
              variant="fullWidth"
              value={this.state.selectedTab}
              onChange={(e, value) => {
                this.setState({
                  selectedTab: value,
                });
              }}
            >
              <Tab
                label="Profilo"
                style={{ fontSize: "24px", fontFamily: "MavenPro Regular" }}
              />
              <Tab
                label="Cronologia Transazioni"
                style={{ fontSize: "24px", fontFamily: "MavenPro Regular" }}
              />
            </Tabs>
          </>
        }

        {this.props.store.store_type === StoreType.PHYSICAL && (
          <TabPanel value={this.state.selectedTab} index={0}>
            <div className={detailStyles.previewContainer}>
              <div style={{ marginBottom: "20px" }}></div>

              <div style={{ marginBottom: "40px" }}></div>
              <div className={detailStyles.profilePreviewDescription}>
                {/* {this.props.store.store_description.split("\n").map((item, index) => {
              return <p key={"descr_" + index}>{item}</p>;
            })} */}
              </div>

              <div className={detailStyles.profilePreviewTitle2}>
                Informazioni utili
              </div>
              <div className={detailStyles.profilePreviewTitle3}>
                Orari di apertura
              </div>
              <div className={detailStyles.profilePreviewText}>
                {this.state.opening.split("\n").map((item, index) => {
                  return <p key={"open_" + index}>{item}</p>;
                })}
              </div>
              <div className={detailStyles.profilePreviewTitle3}>Altro</div>
              <div className={detailStyles.profilePreviewText}>
                {this.state.other.split("\n").map((item, index) => {
                  return <p key={"other_" + index}>{item}</p>;
                })}
              </div>
              <div className={detailStyles.profilePreviewTitle2}>Mappa</div>
              <div className={detailStyles.profilePreviewMap}>
                <iframe
                  title="map"
                  width="100%"
                  height="250"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src={this.state.address}
                  allowFullScreen={false}
                ></iframe>
              </div>
              <div className={detailStyles.profilePreviewTitle2}>Contatti</div>
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>
                  Telefono
                </span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_phone || "-"}
                </span>
              </div>
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>Sito</span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_web_site || "-"}
                </span>
              </div>
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>Email</span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_email || "-"}
                </span>
              </div>
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>
                  Facebook
                </span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_fb_account || "-"}
                </span>
              </div>
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>
                  Instagram
                </span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_instagram_account || "-"}
                </span>
              </div>
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>
                  Linkedin
                </span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_linkedin_account || "-"}
                </span>
              </div>
            </div>

            <div
              style={{
                marginTop: "40px",
                textAlign: "center",
                width: "100%",
                marginBottom: "80px",
              }}
            >
              <MayPButtonBlue
                disabled={this.props.waiting}
                onClick={() => {
                  if (this.props.onEditing) {
                    this.props.onEditing();
                  }
                }}
              >
                Modifica Negozio
              </MayPButtonBlue>
              <div style={{ marginTop: "30px" }}></div>
            </div>
          </TabPanel>
        )}

        {this.props.store.store_type === StoreType.ONLINE && (
          <TabPanel value={this.state.selectedTab} index={0}>
            <div className={detailStyles.previewContainer}>
              <div style={{ marginBottom: "20px" }}></div>

              <div style={{ marginBottom: "40px" }}></div>
              {/* <div className={detailStyles.profilePreviewDescription}>
                {this.props.store.store_description
                  .split("\n")
                  .map((item, index) => {
                    return <p key={"descr_" + index}>{item}</p>;
                  })}
              </div> */}
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>Email</span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_email || "-"}
                </span>
              </div>
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>
                  Facebook
                </span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_fb_account || "-"}
                </span>
              </div>
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>
                  Instagram
                </span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_instagram_account || "-"}
                </span>
              </div>
              <div className={detailStyles.profilePreviewRow}>
                <span className={detailStyles.profilePreviewTitle4}>
                  Linkedin
                </span>
                <span className={detailStyles.profilePreviewText2}>
                  {this.props.store.store_linkedin_account || "-"}
                </span>
              </div>
            </div>

            <div
              style={{
                marginTop: "40px",
                textAlign: "center",
                width: "100%",
                marginBottom: "80px",
              }}
            >
              <MayPButtonBlue
                disabled={this.props.waiting}
                onClick={() => {
                  if (this.props.onEditing) {
                    this.props.onEditing();
                  }
                }}
              >
                Modifica Negozio
              </MayPButtonBlue>
              <div style={{ marginTop: "30px" }}></div>
            </div>
          </TabPanel>
        )}

        <TabPanel value={this.state.selectedTab} index={1}>
          <div className={"tabContent center"}>
            <TransactionsHistory storeId={this.props.store.storeId} />
          </div>
        </TabPanel>
      </>
    );
  }
}

export default ProfileStorePreview;
