import React from "react";

import MayPWithSpinner from "../../../common/components/utils/MayPWithSpinner";
import CFLib from "../../../libs/cloudfunction";
import {
  format,
  formatDistanceToNow,
  differenceInDays,
  differenceInMinutes,
  isYesterday,
  isToday,
} from "date-fns";
import { it } from "date-fns/locale";
import { currencyFormatter } from "../../../common/libs/utils";

import { connect } from "react-redux";
//import * as firebase from "firebase/app";
//import "firebase/firestore";

import styles from "./MovimentiStripe.module.css";
import MayPPageTemplate from "../../../common/components/utils/MayPPageTemplate";

class MovimentiStripe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      payouts: [],
      limit: 10,
      starting_after: null,
      has_more: true,
      waitingBalance: true,
      balance: 0,
      message: "",
    };

    this.toDecimal = this.toDecimal.bind(this);
    this.toFixed = this.toFixed.bind(this);
    this.loadMovements = this.loadMovements.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.cfLib = new CFLib();
    this.preventLoading = false;
  }

  toDecimal(v) {
    return Math.round(v * 100) / 100;
  }

  toFixed(v) {
    return currencyFormatter.format(Number.parseFloat(v));
  }

  async loadMovements() {
    console.log("loading...");
    if (this.state.has_more) {
      this.setState({
        loading: true,
      });
      try {
        const reqBody = {
          limit: this.state.limit,
        };
        if (this.state.starting_after) {
          reqBody.starting_after = this.state.starting_after;
        }
        const res = await this.cfLib.getStripeMerchantPayouts(reqBody);
        if (res.success) {
          const payouts = this.state.payouts;

          payouts.push(...res.data.data);
          this.setState({
            payouts: payouts,
            has_more: res.data.has_more,
            starting_after: payouts.length
              ? payouts[payouts.length - 1].id
              : null,
            loading: false,
            message:
              payouts.length === 0
                ? "Attualmente non sono presenti accrediti"
                : "",
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          message: "Errore di connessione. Riprova più tardi.",
        });
      }
    }
  }

  handleScroll(event) {
    const scrollValue = window.innerHeight + window.pageYOffset;
    const scrollLimit = document.body.offsetHeight;

    if (scrollValue >= scrollLimit * 0.98 && !this.preventLoading) {
      this.preventLoading = true;
      this.loadMovements();
    } else if (scrollValue < scrollLimit - 150) {
      this.preventLoading = false;
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.loadMovements();

    this.setState({
      waitingBalance: true,
    });
    this.cfLib.getStripeMerchantBalance().then((res) => {
      this.setState({
        balance: res ? res.data.pending[0].amount / 100 : "",
        waitingBalance: false,
      });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    console.log("removing scroll handler");
  }

  render() {
    return (
      <MayPPageTemplate title="Accrediti" description={"In questa pagina puoi visualizzare la lista degli accrediti effettuati all’IBAN di riferimento."}>
        <content>
          <div ref={this.ref} className={styles.container}>
            <div className={styles.creditBox}>
              <div className={styles.creditLabel}>Prossimo accredito</div>
              <MayPWithSpinner waiting={this.state.waitingBalance}>
                <div className={styles.creditValue}>
                  {currencyFormatter.format(this.state.balance)} €
                </div>
              </MayPWithSpinner>
            </div>
            <div className={styles.whiteBox}>
              <div className={styles.rowContainer}>
                {!this.state.payouts.length && !this.state.loading && (
                  <div className={styles.info}>{this.state.message}</div>
                )}
                {this.state.payouts.map((el, i) => {
                  const trDate = new Date(el.created * 1000);
                  const now = new Date();
                  let when;
                  if (isToday(trDate)) {
                    if (differenceInMinutes(now, trDate) > 60) {
                      when = format(trDate, "'oggi alle' p", { locale: it });
                    } else {
                      when = formatDistanceToNow(trDate, {
                        addSuffix: true,
                        locale: it,
                      });
                    }
                  } else if (isYesterday(trDate)) {
                    when = format(trDate, "'ieri alle' p", { locale: it });
                  } else if (differenceInDays(trDate, now) <= 4) {
                    when = format(trDate, "PP 'alle' p", { locale: it });
                  } else if (differenceInDays(trDate, now) > 4) {
                    when = format(trDate, "PP 'alle' p", { locale: it });
                  }

                  let status = undefined;
                  switch (el.status) {
                    case "paid":
                      status = "pagato";
                      break;
                    case "pending":
                      status = "in attesa";
                      break;
                    case "in_transit":
                      status = "in transito";
                      break;
                    case "canceled":
                      status = "annullato";
                      break;
                    case "failed":
                      status = "fallito";
                      break;
                    default:
                      status = "sconosciuto";
                      break;
                  }
                  return (
                    <div key={`payout-${i}`} className={styles.row}>
                      <div className={styles.rowTop}>
                        <div className={styles.date}>{when}</div>
                      </div>
                      <div className={styles.rowMiddle}>
                        <div>
                          <div className={styles.paymentDescription}>
                            {el.description}
                          </div>
                        </div>
                        <div className={styles.amount}>
                          {this.toFixed(this.toDecimal(el.amount / 100))} €
                        </div>
                      </div>
                      <div className={styles.rowMiddle}>
                        <div className={styles.paymentId}>
                          <b>ID pagamento: </b>
                          {el.id}
                        </div>
                        <div className={styles.paymentStatus}>
                          <b>Stato: </b>
                          {status}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {this.state.loading && (
                  <div style={{ marginTop: "70px", marginBottom: "200px" }}>
                    <MayPWithSpinner waiting={this.state.loading}>
                      <span />
                    </MayPWithSpinner>
                  </div>
                )}
              </div>
            </div>
          </div>
        </content>
      </MayPPageTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user || { profile: {} },
    store: state.store,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  MovimentiStripe
);
