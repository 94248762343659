import React from "react";
import { MayPPage } from "../../common/components/utils/MayPPager";
import MayPButtonBlue from "../../common/components/utils/MayPButton";
import MayPDialog from "../../common/components/utils/MayPDialog";
import MayPFormBuider from "../../common/components/utils/MayPFormBuilder";
import styles from "./MenuDatiAziendali.module.css";

import { Browser } from "@capacitor/browser";
import { withRouter } from "react-router-dom";
import MayPPageTemplate from "../../common/components/utils/MayPPageTemplate";

class MenuDatiAziendali extends MayPPage {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      formValid: false,
      waiting: false,
      error: 0,
      errorMessage: "",
      formData: props.cache,
      showDialog: false,
    };
    this.formItems = [
      {
        type: "text",
        label: "Ragione Sociale",
        name: "company_name",
        defaultValue: props.merchant.company_name,
        wrapperClass: "form-data w100",
        nextFocus: "company_vat",
        disabled: true,
      },
      {
        type: "text",
        label: "Partita IVA",
        name: "company_vat",
        defaultValue: props.merchant.company_vat,
        wrapperClass: "form-data w100",
        nextFocus: "company_address",
        disabled: true,
      },
      {
        type: "text",
        label: "Indirizzo sede legale",
        name: "company_address",
        defaultValue: props.merchant.company_address,
        wrapperClass: "form-data w100",
        nextFocus: "company_address_number",
        disabled: true,
      },
      {
        type: "text",
        label: "Civico",
        name: "company_address_number",
        defaultValue: props.merchant.company_address_number,
        wrapperClass: "form-data w50",
        nextFocus: "company_postal_code",
        disabled: true,
      },
      {
        type: "number",
        label: "CAP",
        name: "company_postal_code",
        defaultValue: props.merchant.company_postal_code,
        wrapperClass: "form-data w50",
        nextFocus: "company_city",
        disabled: true,
      },
      {
        type: "text",
        label: "Città",
        name: "company_city",
        defaultValue: props.merchant.company_city,
        wrapperClass: "form-data w50",
        nextFocus: "company_state",
        disabled: true,
      },
      {
        type: "text",
        label: "Stato",
        name: "company_state",
        defaultValue: props.merchant.company_state,
        wrapperClass: "form-data w50",
        nextFocus: "company_owner",
        disabled: true,
      },
      {
        type: "email",
        label: "Rappresentante",
        name: "company_owner",
        defaultValue:
          props.merchant.owner_name + " " + props.merchant.owner_surname,
        wrapperClass: "form-data w100",
        isOptional: true,
        validation: [],
        disabled: true,
      },
    ];

    this.onContinue = this.onContinue.bind(this);
  }

  async onContinue() {
    window.open("https://www.maypay.com/assistenza-esercenti/", "_blank");
  }

  render() {
    return (
      <MayPPageTemplate
        title="Account"
        description="Per modificare o eliminare i dati del tuo account, contatta l'assistenza clienti."
      >
        <action>
          <div
            // style={{
            //   textAlign: "left",
            //   width: "100%",
            //   marginBottom: "20px",
            //   display: "flex",
            //   flexDirection: "column",
            //   alignItems: "flex-end",
            //   rowGap: "24px",
            // }}
            className={styles.actionContainer}
          >
            <MayPButtonBlue
              disabled={this.state.waiting}
              onClick={this.onContinue}
            >
              Contattaci
            </MayPButtonBlue>
            {/* <ButtonBack
              onClick={() => {
                this.setState({
                  showDialog: true,
                });
              }}
              style={{
                color: "#F65626",
                WebkitTextStroke: "0.7px #F65626",
              }}
            >
              Elimina account
            </ButtonBack> */}
          </div>
        </action>
        <content>
          <div className={styles.mainContainer}>
            <div className={styles.box}>
              <MayPFormBuider
                items={this.formItems}
                id="companyForm"
                onValid={(v, data) => {
                  this.setState({
                    formValid: v,
                    formData: data,
                  });
                }}
                containerClass={styles.formContainer}
              />

              <div
                style={{
                  marginTop: "20px",
                  textAlign: "left",
                  width: "100%",
                  padding: "0 24px",
                }}
              >
                {/* <ButtonBack
              disabled={this.state.waiting || !this.state.formValid}
              onClick={() => {
                if (this.props.onSubmit) {
                  this.props.onSubmit(this.props.page, "goto-home");
                }
              }}
            >
              Torna alla home
            </ButtonBack> */}
                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  {/*
              <span style={{color:"#F65626", "WebkitTextStroke": "0.7px #F65626"}}>Elimina account</span>
              */}
                </div>
              </div>
              <MayPDialog
                forMerchant={true}
                open={this.state.showDialog}
                title="Elimina account"
                message="Per eliminare definitivamente il tuo account Maypay, contatta l’assistenza clienti."
                actions={["Annulla", "Contattaci"]}
                onClose={async (action) => {
                  if (action === "Contattaci") {
                    await Browser.open({
                      url: "https://www.maypay.com/assistenza-esercenti/",
                    });
                    this.setState({
                      showDialog: false,
                    });
                  } else {
                    this.setState({
                      showDialog: false,
                    });
                  }
                }}
              />
            </div>
          </div>
        </content>
      </MayPPageTemplate>
    );
  }
}

export default withRouter(MenuDatiAziendali);
