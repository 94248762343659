const categories = [
  { label: "", value: "" },
  { label: "Alimentari", value: "Alimentari" },
  { label: "Animali", value: "Animali" },
  { label: "Arredamento", value: "Arredamento" },
  { label: "Bar", value: "Bar" },
  { label: "Bistrot", value: "Bistrot" },
  { label: "Casalinghi", value: "Casalinghi" },
  { label: "Elettronica", value: "Elettronica" },
  { label: "Enoteca", value: "Enoteca" },
  { label: "Estetica", value: "Estetica" },
  { label: "Farmacia", value: "Farmacia" },
  { label: "Ferramenta", value: "Ferramenta" },
  { label: "Giochi", value: "Giochi" },
  { label: "Hotel", value: "Hotel" },
  { label: "Libri", value: "Libri" },
  { label: "Moda", value: "Moda" },
  { label: "Museo", value: "Museo" },
  { label: "Ristorante", value: "Ristorante" },
  { label: "Salute", value: "Salute" },
  { label: "Spettacolo", value: "Spettacolo" },
  { label: "Sport", value: "Sport" },
  { label: "Supermercato", value: "Supermercato" },
  { label: "Parrucchiere", value: "Parrucchiere" },
  { label: "Pasticceria", value: "Pasticceria" },
  { label: "Piante", value: "Piante" },
  { label: "Profumeria", value: "Profumeria" },
  { label: "Pub", value: "Pub" },
];

const sorted = categories.sort((a, b) => {
  return a.label.localeCompare(b.label);
});

sorted.push({ label: "Altro", value: "Altro" });
export default sorted;
